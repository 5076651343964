<template>
  <!-- <div v-on:contextmenu.prevent="doNothing"> -->
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container> 
    <div v-else>
      <b-container v-if="this.inSession">
        <!-- <p>{{tasting_note_id}}</p> -->
        <b-form v-if="!tasting.fromCellar" aria-autocomplete="off" >
          <div v-if="tasting.isOrganised">
            <p class="item-title" style="text-align:left">{{this.tasting_note.name}}</p>
            <b-row>
              <b-col>
                <p class="ww-text"><b>date:</b> {{this.tasting.nice_date}}</p>
              </b-col>
              <b-col class="text-right">
                <b-form-checkbox 
                  id="shareToggle"
                  v-model="tasting_note.share"
                >
                <label class="w-label" for="shareToggle">share with organiser</label>
                </b-form-checkbox>
              </b-col>
            </b-row>  
          </div>
          <div v-else>
            <b-form-group>
              <label class="w-label" for="input-date">date</label>
                <b-form-datepicker
                  class="w-datepicker mb-2" 
                  id="input-date" 
                  v-model="tasting_note.date"
                  @change="onSave"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                >
                </b-form-datepicker>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{dateFeedback}}
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <label class="w-label w-required" for="input-name">name</label>
              <b-form-input 
                id="input-name"
                class="w-input"
                v-model="tasting_note.name"
                @change="onSave"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.nameValid}"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{nameFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <hr>
          <b-row>
            <b-col>
              <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom:0">
                <b-form-radio-group :key="update_toggle"
                  id="radiobuttons"
                  class = "w-radiobuttons"
                  v-model="selected"
                  @change="changeChoice"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <div v-if="selected == 'short'">
            <b-form-group>
              <label class="w-label" for="input-tastingnote">tasting note</label>
              <b-form-textarea
                id="input-conclusions"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.conclusions"
                @change="onSoftSave"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.tasting_note_isValid}"
                aria-describedby="input-live-help input-live-feedback"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-live-feedback">
              enter at least 2 letters
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div v-else-if="selected == 'long'">
            <b-row style="margin-left:0px;margin-right:5px">
              <b-col cols="1" class="text-left" style="padding:0">
                <b-img v-if="leftVisible"
                  class="w-cursor-click"
                  width=30px
                  height=30px
                  :src="require('@/assets/img/left.png')"
                  @click="onLeft"
                >
                </b-img>
              </b-col>                
              <b-col cols="10" class="text-center" style="padding:0">
                <p v-if="this.pageCount == 0" 
                  class="current-page w-cursor-default"
                >
                  <b>appearance</b> -
                </p>
                <p v-else 
                  class="w-page w-cursor-click"
                  @click="onAppearance"
                >
                  appearance -
                </p>

                <p v-if="this.pageCount == 1" 
                  class="current-page w-cursor-default"
                >
                  <b>nose</b> -
                </p>
                <p v-else 
                  class="w-page w-cursor-click"
                  @click="onNose"
                >
                  nose -
                </p>

                <p v-if="this.pageCount == 2" 
                  class="current-page w-cursor-click"
                >
                  <b>palate</b> -
                </p>
                <p v-else 
                  class="w-page w-cursor-click"
                  @click="onPalate"
                >
                  palate -
                </p>

                <p v-if="this.pageCount == 3" 
                  class="current-page w-cursor-click"
                >
                  <b>conclusions</b>
                </p>
                <p v-else 
                  class="w-page w-cursor-click"
                  @click="onConclusions"
                >
                  conclusions
                </p>

                <p class="w-smalltext" style="text-align:center;">page {{this.pageCount+1}}/{{this.maxPage+1}}</p>
              </b-col>
              <b-col cols="1" class="text-right" style="padding:0">
                <b-img v-if="rightVisible"
                  class="w-cursor-click"
                  width=30px
                  height=30px
                  :src="require('@/assets/img/right.png')"
                  @click="onRight"
                >
                </b-img>
              </b-col>
              <br>                
            </b-row>
            <b-row style="margin-left:0px;margin-right:5px"> 
              <b-form-group v-if="pageCount == 0" class="w-page"> 
                <b-form-textarea
                  id="input-appearance"
                  class="w-textarea"
                  rows="3"
                  max-rows="10"
                  v-model="tasting_note.appearance"
                  @change="onSoftSave"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="describe the appearance of the wine"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group v-if="pageCount == 1" class="w-page">
                <b-form-textarea
                  id="input-nose"
                  class="w-textarea"
                  rows="3"
                  max-rows="10"
                  v-model="tasting_note.nose"
                  @change="onSoftSave"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="describe what you smell in the glass"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group v-if="pageCount == 2" class="w-page">
                <b-form-textarea
                  id="input-palate"
                  class="w-textarea"
                  rows="3"
                  max-rows="10"
                  v-model="tasting_note.palate"
                  @change="onSoftSave"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="describe what you taste"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group v-if="pageCount == 3" class="w-page">
                <b-form-textarea
                  id="input-conclusions"
                  class="w-textarea"
                  rows="3"
                  max-rows="10"
                  v-model="tasting_note.conclusions"
                  @change="onSoftSave"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="describe the conclusions"
                ></b-form-textarea>
              </b-form-group>
            </b-row>
          </div>
          <div v-else-if="selected == 'wset2'" style="position: relative">
            <b-form-textarea readonly 
              :key="update_wset"
              id="input-wset2"
              class="w-textarea"
              rows="5"
              max-rows="12"
              v-model="tasting_note.wset_text"
              @change="onSave"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="wset2 description of the wine"
            >
            </b-form-textarea>
            <div
              v-on:click.stop="showModal_WSET2_SAT"
              style="position:absolute; top:5px; right: 25px"
            >
              <font-awesome-icon 
                id="edit"
                class="w-cursor-click" 
                style="font-size:17px" 
                icon="pencil-alt"
                color="rgb(109,122,82)" 
              />
              <b-tooltip target="edit" triggers="hover">
                WSET Level 2 tasting grid
              </b-tooltip>
            </div>
            <div
              v-on:click.stop="showModal_AdditionalRemarks"
              style="position:absolute; bottom:5px; right: 25px"
            >
              <font-awesome-icon 
                id="plus"
                class="w-cursor-click" 
                style="font-size:17px" 
                icon="plus"
                color="rgb(109,122,82)" 
              />
              <b-tooltip target="plus" triggers="hover">
                additional remarks
              </b-tooltip>
            </div>
          </div>
          <div v-else-if="selected == 'wset3'" style="position: relative">
            <b-form-textarea readonly 
              :key="update_wset"
              id="input-wset3"
              class="w-textarea"
              rows="5"
              max-rows="12"
              v-model="tasting_note.wset_text"
              @change="onSave"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="wset3 description of the wine"
            >
            </b-form-textarea>
            <div
              v-on:click.stop="showModal_WSET3_SAT"
              style="position:absolute; top:5px; right: 25px"
            >
              <font-awesome-icon 
                id="edit"
                class="w-cursor-click" 
                style="font-size:17px" 
                icon="pencil-alt"
                color="rgb(109,122,82)" 
              />
              <b-tooltip target="edit" triggers="hover">
                WSET Level 3 tasting grid
              </b-tooltip>
            </div>
            <div
              v-on:click.stop="showModal_AdditionalRemarks"
              style="position:absolute; bottom:5px; right: 25px"
            >
              <font-awesome-icon 
                id="plus"
                class="w-cursor-click" 
                style="font-size:17px" 
                icon="plus"
                color="rgb(109,122,82)" 
              />
              <b-tooltip target="plus" triggers="hover">
                additional remarks
              </b-tooltip>
            </div>
          </div>
  
          <hr>
          <b-form-group>
            <b-row>
              <b-col>
                <label class="w-label" for="input-score">score</label> 
              </b-col>
              <b-col>
                <p class="item-title" style="text-align:right"><b>{{tasting_note.score}}/100</b></p>
              </b-col>
            </b-row>
            <range-slider
              class="w-slider"
              min="0"
              max="100"
              v-model="tasting_note.score"
              @change="onSoftSave"
            >
            </range-slider>
            <p class="item-title" style="text-align:right"><b>{{letter_score[Math.min(Math.round(tasting_note.score*15/100-0.50),14)]}}</b></p>
          </b-form-group>
          <br>
          <b-row>
            <b-col class="text-right" style="padding-right:15px">
              <b-button v-on:click="onSave()" class="w-button-green-full">save</b-button>
            </b-col>
          </b-row>   
        </b-form>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
   
    <b-modal id="WSET2_SAT" size="lg" @ok="saveWSET2_SAT" @cancel="hideModal_WSET2_SAT" scrollable>
      <template #modal-header>
        <h5 class="wset2-title">WSET Level 2 Systematic Approach to Tasting Wine®</h5>
      </template>

      <div
        v-for="(category,index) in wset2.SAT"
        v-bind:item="category"
        v-bind:index="index"
        v-bind:key="index"
        style="padding-bottom:0"
      >
          <p
            class="wset2-category"
          >
            {{ category.property }}
          </p>
          <div v-if="category.goesDeeper == true">
            <div 
              v-for="(value1,index1) in category.values"
              v-bind:item="value1"
              v-bind:index="index1"
              v-bind:key="index1"
            > 
              <div v-if="value1.goesDeeper == true">
                <p
                  class="wset2-property"
                  style="margin-left:10px;margin-bottom:0; width:30%;clear:both"
                >
                  {{ value1.property }}
                </p>
                <div
                  v-for="(value2,index2) in value1.values"
                  v-bind:item="value2"
                  v-bind:index="index2"
                  v-bind:key="index2"
                >
                  <div style="float:left; margin-left:15%;width:15%">
                    <p
                      class="wset2-property"
                      style="margin-left:10px;margin-bottom:0; width:15%;float:left"
                    >
                      {{ value2.property }}
                    </p>                    
                  </div> 
                  <div style="float:left; width:70%">
                    <div
                      v-for="(value3,index3) in value2.values"
                      v-bind:item="value3"
                      v-bind:index="index3"
                      v-bind:key="index3"
                      style="display:inline-block"
                    >
                      <p 
                          class="wset2-value w-cursor-click" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                          v-on:click="onSelect_WSET2([index,index1,index2,index3])"
                        >
                        <span v-if="isSelected([index,index1,index2,index3])" style="font-family:'proximanova-bold'"><b><u>{{ value3 }}</u></b></span>
                        <span v-else>{{ value3 }}</span>
                        <span v-if="index3 < value2.values.length-1"> - </span>
                      </p>
                    </div>
                  </div>
                  <br style="clear:both"/>
                </div>
              </div>
              <div v-else>
                <div style="float: left; width:30%">
                  <p
                    class="wset2-property"
                    style="margin-left:10px;float:left;margin-bottom:0;"
                  >
                    {{ value1.property }}
                  </p>
                </div>
                <div style="float:left; width:70%">
                  <div
                    v-for="(value2,index2) in value1.values"
                    v-bind:item="value2"
                    v-bind:index="index2"
                    v-bind:key="index2"
                    style="display:inline-block"
                  >
                    <p 
                        class="wset2-value w-cursor-click" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                        v-on:click="onSelect_WSET2([index,index1,index2])"
                      >
                      <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else-if="(index==1 && index1==2) || (index==2 && index1==6) " style="font-family:'proximanova-bold'; color:rgb(39, 97, 147)">{{value2}}</span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                  </div>
                </div>
                <br style="clear:both" />
              </div>
            </div>
            <br style="clear:both" />  
          </div>
          <div v-else> 
            <p>Level 0: NOT APPLICATABLE IN WSET2.</p>
          </div>
      </div>
      <hr>
      <div style="float:left; margin-left:10px;width:28%">
        <b-img
          width="90px"
          :src="require('@/assets/img/WSET2_Landscape_Descriptor.png')"
        >
        </b-img>
      </div>
      <div style="float:left; margin-left:10px; width:50%">
        <p class="w-smalltext" style="margin-bottom:0"><b>WSET Level 2 {{wset2.version}} Systematic Approach to Tasting Wine.</b></p>
        <p class="w-smalltext" style="margin-bottom:0">© Copyright Wine & Spirit Education Trust 2023. All Rights Reserved.</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="wset2-button-left" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="wset2-button-right" @click="ok()">
              add
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="WSET2_WineLexicon" size="lg" @ok="saveWSET2_WineLexicon" @cancel="hideModal_WSET2_WineLexicon" scrollable>
      <!-- <p>selected: {{selected_indices}}</p> -->
      <template #modal-header >
        <div style="display:block">
          <p class="wset2-title">WSET Level 2 Wine-Lexicon</p>
          <p class="wset2-subtitle">Supporting the WSET Level 2 Systematic Approach to Tasting Wine®</p>
        </div>
        <!-- <div style="position: absolute;z-index:10; right:15px">
          <b-img v-if="imageSrc != 0"
            width=50px
            height=50px
            :src=imageSrc
          ></b-img>
        </div> -->
        <!-- <div style="position: absolute; right:35px;">
          <p class="wset2-title"style="margin:0;color:white">blank</p>
          <b-form-checkbox v-model="showPicture" class="wset2-switch" name="showPicture" switch>
            <label class="wset2-label" for="showPicture">show picture</label>
          </b-form-checkbox>
        </div> -->
      </template>

      <!-- <div 
        v-if="imageSrc!=0 && showPicture"
        class="floating-div"
        :style="{ left: mouseX + 'px', top: (mouseY+10) + 'px' }"
      >
        <b-img v-if="imageSrc != 0"
          width=170px
          height=170px
          :src=imageSrc
        ></b-img>
      </div> -->

      <p class="wset2-category">Describing {{ wine_lexicon }}</p>

      <div
        v-for="(category,index) in wset2.wine_lexicon"
        v-bind:item="category"
        v-bind:index="index"
        v-bind:key="index"
        style="padding-bottom:0"
      >
          <p
            v-if="index == 0"
            class="wset2-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of the grape and alcoholic fermentation
          </p>
          <p
            v-else-if="index == 1"
            class="wset2-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of post-fermentation winemaking
          </p>
          <p
            v-else-if="index == 2"
            class="wset2-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of of maturation
          </p>
          <p
            v-else
            class="wset2-subcategory"
          >
            ERROR
          </p>
          <div v-if="category.goesDeeper == true">
            <div 
              v-for="(value1,index1) in category.values"
              v-bind:item="value1"
              v-bind:index="index1"
              v-bind:key="index1"
            > 
              <div v-if="value1.goesDeeper == true">
                <p
                  class="wset2-property"
                  style="margin-left:10px;margin-bottom:0; width:30%;clear:both"
                >
                  {{ value1.property }}
                </p>
                <div
                  v-for="(value2,index2) in value1.values"
                  v-bind:item="value2"
                  v-bind:index="index2"
                  v-bind:key="index2"
                >
                  <p
                    class="wset2-property"
                    style="margin-left:calc(15% + 10px);margin-bottom:0; width:15%;float:left"
                  >
                    {{ value2.property }}
                  </p>
                  <div style="padding-left:15%">
                    <div
                      v-for="(value3,index3) in value2.values"
                      v-bind:item="value3"
                      v-bind:index="index3"
                      v-bind:key="index3"
                    >
                      <p v-if="wine_lexicon == ''"
                          class="wset2-value w-cursor-click" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                          v-on:click="onSelect_WSET2([index,index1,index2,index3])"
                        >
                        <span v-if="isSelected([index,index1,index2,index3])" style="font-family:'proximanova-bold'"><b><u>{{ value3 }}</u></b></span>
                        <span v-else>{{ value3 }}</span>
                        <span v-if="index3 < value2.values.length-1"> - </span>
                      </p>
                      <p v-else>
                        CANNOT BE IN WSET2
                      </p>
                    </div>
                  </div>
                  <br style="clear:both"/>
                </div>
              </div>
              <div v-else>
                <div style="float: left; width:30%">
                  <p
                    class="wset2-property"
                    style="margin-left:10px;margin-bottom:0;"
                  >
                    {{ value1.property }}
                  </p>
                </div>
                <div style="float:left; width:70%">
                  <div
                    v-for="(value2,index2) in value1.values"
                    v-bind:item="value2"
                    v-bind:index="index2"
                    v-bind:key="index2"
                    style="display:inline-block"
                  >
                    <p v-if="wine_lexicon == ''"
                        class="wset2-value w-cursor-click" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                        v-on:click="onSelect_WSET2([index,index1,index2])"
                      >
                      <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                    <p v-if="wine_lexicon == 'aroma'"
                        class="wset2-value w-cursor-click" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                        v-on:click="onSelect_WSET2([1,2,index,index1,index2])"
                        @mouseover="mouseOver(value2)"
                        @mouseleave="mouseLeave"
                        @mousemove="mouseMove"
                      >
                      <span v-if="isSelected([1,2,index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>

                    <p v-if="wine_lexicon == 'flavour'"
                        class="wset2-value w-cursor-click" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                        v-on:click="onSelect_WSET2([2,6,index,index1,index2])"
                        @mouseover="mouseOver(value2)"
                        @mouseleave="mouseLeave"
                        @mousemove="mouseMove"
                      >
                      <span v-if="isSelected([2,6,index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                  </div>
                </div>
              </div>
            <br style="clear:both" />  
          </div>
         </div>
         <div v-else> 
            <p>Level 0: NOT APPLICATABLE IN WSET2.</p>
          </div>
      </div>
      <hr>
      <div style="float:left; margin-left:10px;width:28%">
        <b-img
          width="90px"
          :src="require('@/assets/img/WSET2_Landscape_Descriptor.png')"
        >

        </b-img>
      </div>
      <div style="float:left; margin-left:10px; width:50%">
        <p class="w-smalltext" style="margin-bottom:0"><b>WSET Level 2 {{wset2.version}} Systematic Approach to Tasting Wine.</b></p>
        <p class="w-smalltext" style="margin-bottom:0">© Copyright Wine & Spirit Education Trust 2023. All Rights Reserved.</p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="wset2-button-left" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="wset2-button-right" @click="ok()">
              back
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="WSET3_SAT" size="lg" @ok="saveWSET3_SAT" @cancel="hideModal_WSET3_SAT" scrollable>
      <template #modal-header>
        <h5 class="wset3-title">WSET Level 3 Systematic Approach to Tasting Wine®</h5>
      </template>

      <div
        v-for="(category,index) in wset3.SAT"
        v-bind:item="category"
        v-bind:index="index"
        v-bind:key="index"
        style="padding-bottom:0"
      >
          <p
            class="wset3-category"
          >
            {{ category.property }}
          </p>
          <div v-if="category.goesDeeper == true">
            <div 
              v-for="(value1,index1) in category.values"
              v-bind:item="value1"
              v-bind:index="index1"
              v-bind:key="index1"
            > 
              <div v-if="value1.goesDeeper == true">
                <p
                  class="wset3-property"
                  style="margin-left:10px;margin-bottom:0; width:30%;clear:both"
                >
                  {{ value1.property }}
                </p>
                <div
                  v-for="(value2,index2) in value1.values"
                  v-bind:item="value2"
                  v-bind:index="index2"
                  v-bind:key="index2"
                >
                  <div style="float:left; margin-left:15%;width:15%">
                    <p
                      class="wset3-property"
                      style="margin-left:10px;margin-bottom:0; width:15%;float:left"
                    >
                      {{ value2.property }}
                    </p>                    
                  </div> 
                  <div style="float:left; width:70%">
                    <div
                      v-for="(value3,index3) in value2.values"
                      v-bind:item="value3"
                      v-bind:index="index3"
                      v-bind:key="index3"
                      style="display:inline-block"
                    >
                      <p 
                          class="wset3-value w-cursor-click" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                          v-on:click="onSelect_WSET3([index,index1,index2,index3])"
                        >
                        <span v-if="isSelected([index,index1,index2,index3])" style="font-family:'proximanova-bold'"><b><u>{{ value3 }}</u></b></span>
                        <span v-else>{{ value3 }}</span>
                        <span v-if="index3 < value2.values.length-1"> - </span>
                      </p>
                    </div>
                  </div>
                  <br style="clear:both"/>
                </div>
              </div>
              <div v-else>
                <div style="float: left; width:30%">
                  <p
                    class="wset3-property"
                    style="margin-left:10px;float:left;margin-bottom:0;"
                  >
                    {{ value1.property }}
                  </p>
                </div>
                <div style="float:left; width:70%">
                  <div
                    v-for="(value2,index2) in value1.values"
                    v-bind:item="value2"
                    v-bind:index="index2"
                    v-bind:key="index2"
                    style="display:inline-block"
                  >
                    <p 
                        class="wset3-value w-cursor-click" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                        v-on:click="onSelect_WSET3([index,index1,index2])"
                      >
                      <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else-if="(index==1 && index1==2) || (index==2 && index1==7) " style="font-family:'proximanova-bold'; color:rgb(1, 140, 83)">{{value2}}</span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                  </div>
                </div>
                <br style="clear:both" />
              </div>
            </div>
            <br style="clear:both" />  
          </div>
          <div v-else> 
            <p>Level 0: NOT APPLICATABLE IN WSET3.</p>
          </div>
      </div>
      <hr>
      <div style="float:left; margin-left:10px;width:28%">
        <b-img
          width="90px"
          :src="require('@/assets/img/WSET3_Landscape_Descriptor.png')"
        >

        </b-img>
      </div>
      <div style="float:left; margin-left:10px; width:50%">
        <p class="w-smalltext" style="margin-bottom:0"><b>WSET Level 3 {{wset3.version}} Systematic Approach to Tasting Wine.</b></p>
        <p class="w-smalltext" style="margin-bottom:0">© Copyright Wine & Spirit Education Trust 2022. All Rights Reserved.</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="wset3-button-left" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="wset3-button-right" @click="ok()">
              add
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="WSET3_WineLexicon" size="lg" @ok="saveWSET3_WineLexicon" @cancel="hideModal_WSET3_WineLexicon" scrollable>
      <!-- <p>selected: {{selected_indices}}</p> -->
      <template #modal-header >
        <div style="display:block">
          <p class="wset3-title">WSET Level 3 Wine-Lexicon</p>
          <p class="wset3-subtitle">Supporting the WSET Level 3 Systematic Approach to Tasting Wine®</p>
        </div>
        <!-- <div style="position: absolute;z-index:10; right:15px">
          <b-img v-if="imageSrc != 0"
            width=50px
            height=50px
            :src=imageSrc
          ></b-img>
        </div> -->
        <!-- <div style="position: absolute; right:35px;">
          <p class="wset3-title"style="margin:0;color:white">blank</p>
          <b-form-checkbox v-model="showPicture" class="wset3-switch" name="showPicture" switch>
            <label class="wset3-label" for="showPicture">show picture</label>
          </b-form-checkbox>
        </div> -->
      </template>

      <!-- <div 
        v-if="imageSrc!=0 && showPicture"
        class="floating-div"
        :style="{ left: mouseX + 'px', top: (mouseY+10) + 'px' }"
      >
        <b-img v-if="imageSrc != 0"
            width=250px
            height=250px
            :src=imageSrc
          ></b-img>
      </div> -->

      <div
        v-if="showFlavourProfile" 
        class="floating-div"
        :style="{ width: '330px' }"
      >
        <b-container>
          <p class="wset3-title" >{{flavourName}}</p>
          <b-row> 
            <b-col class="text-center">
              <b-img v-if="flavourImage != 0"
                width=200px
                height=200px
                :src=flavourImage
              ></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom:5px">
                <b-form-radio-group 
                  id="flavour_di"
                  class = "wset3-radiobuttons"
                  style = "width:100%"
                  v-model="flavour_di"
                  @change="changeDI"
                  :options="options_di"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom:5px">  
                <b-form-radio-group 
                  id="flavour_sc"
                  class = "wset3-radiobuttons"
                  style = "width:100%"
                  v-model="flavour_sc"
                  @change="changeSC"
                  :options="options_sc"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom:5px">  
                <b-form-radio-group 
                  id="flavour_gw"
                  class = "wset3-radiobuttons"
                  style = "width:100%"
                  v-model="flavour_gw"
                  @change="changeGW"
                  :options="options_gw"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom:5px">  
                <b-form-radio-group 
                  id="flavour_fc"
                  class = "wset3-radiobuttons"
                  style = "width:100%"
                  v-model="flavour_fc"
                  @change="changeFC"
                  :options="options_fc"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom:5px">  
                <b-form-radio-group 
                  id="flavour_gw"
                  class = "wset3-radiobuttons"
                  style = "width:100%"
                  v-model="flavour_ur"
                  @change="changeUR"
                  :options="options_ur"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>    
          <b-row>  
            <b-col class="text-left" style="padding:0, margin:15px">
              <b-button class="wset3-button-left" @click="cancelOptions">
                cancel
              </b-button>
            </b-col>
            <b-col class="text-right" style="padding:0, margin:15px"">
              <b-button class="wset3-button-right" @click="saveOptions()">
                save
              </b-button>
            </b-col>
          </b-row>

        </b-container>
      </div>

      <p class="wset3-category">Describing {{ wine_lexicon }}</p>

      <div
        v-for="(category,index) in wset3.wine_lexicon"
        v-bind:item="category"
        v-bind:index="index"
        v-bind:key="index"
        style="padding-bottom:0"
      >
          <p
            v-if="index == 0"
            class="wset3-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of the grape and alcoholic fermentation
          </p>
          <p
            v-else-if="index == 1"
            class="wset3-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of post-fermentation winemaking
          </p>
          <p
            v-else-if="index == 2"
            class="wset3-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of of maturation
          </p>
          <p
            v-else
            class="wset3-subcategory"
          >
            ERROR
          </p>
          <div v-if="category.goesDeeper == true">
            <div 
              v-for="(value1,index1) in category.values"
              v-bind:item="value1"
              v-bind:index="index1"
              v-bind:key="index1"
            > 
              <div v-if="value1.goesDeeper == true">
                <p
                  class="wset3-property"
                  style="margin-left:10px;margin-bottom:0; width:30%;clear:both"
                >
                  {{ value1.property }}
                </p>
                <div
                  v-for="(value2,index2) in value1.values"
                  v-bind:item="value2"
                  v-bind:index="index2"
                  v-bind:key="index2"
                >
                  <p
                    class="wset3-property"
                    style="margin-left:calc(15% + 10px);margin-bottom:0; width:15%;float:left"
                  >
                    {{ value2.property }}
                  </p>
                  <div style="padding-left:15%">
                    <div
                      v-for="(value3,index3) in value2.values"
                      v-bind:item="value3"
                      v-bind:index="index3"
                      v-bind:key="index3"
                    >
                      <p v-if="wine_lexicon == ''"
                          class="wset3-value w-cursor-click" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                          v-on:click="onSelect_WSET3([index,index1,index2,index3])"
                        >
                        <span v-if="isSelected([index,index1,index2,index3])" style="font-family:'proximanova-bold'"><b><u>{{ value3 }}</u></b></span>
                        <span v-else>{{ value3 }}</span>
                        <span v-if="index3 < value2.values.length-1"> - </span>
                      </p>
                      <p v-else>
                        CANNOT BE IN WSET2
                      </p>
                    </div>
                  </div>
                  <br style="clear:both"/>
                </div>
              </div>
              <div v-else>
                <div style="float: left; width:30%">
                  <p
                    class="wset3-property"
                    style="margin-left:10px;margin-bottom:0;"
                  >
                    {{ value1.property }}
                  </p>
                </div>
                <div style="float:left; width:70%">
                  <div
                    v-for="(value2,index2) in value1.values"
                    v-bind:item="value2"
                    v-bind:index="index2"
                    v-bind:key="index2"
                    style="display:inline-block"
                  >
                    <p v-if="wine_lexicon == ''"
                        class="wset3-value w-cursor-click" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                        v-on:click="onSelect_WSET3([index,index1,index2])"
                      >
                      <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                    <p v-if="wine_lexicon == 'aroma'"
                        class="wset3-value w-cursor-click" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                        v-on:click="handleClick([1,2,index,index1,index2], $event,value2)"
                        @mouseover="mouseOver(value2)"
                        @mouseleave="mouseLeave"
                        @mousemove="mouseMove"
                      >
                      <span v-if="isSelected([1,2,index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>

                    <p v-if="wine_lexicon == 'flavour'"
                        class="wset3-value w-cursor-click" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                        v-on:click="handleClick([2,7,index,index1,index2], $event, value2)"
                        @mouseover="mouseOver(value2)"
                        @mouseleave="mouseLeave"
                        @mousemove="mouseMove"
                      >
                      <span v-if="isSelected([2,7,index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                  </div>
                </div>
              </div>
            <br style="clear:both" />  
          </div>
         </div>
         <div v-else> 
            <p>Level 0: NOT APPLICATABLE IN WSET2.</p>
          </div>
      </div>
      <hr>
      <div style="float:left; margin-left:10px;width:28%">
        <b-img
          width="90px"
          :src="require('@/assets/img/WSET3_Landscape_Descriptor.png')"
        >

        </b-img>
      </div>
      <div style="float:left; margin-left:10px; width:50%">
        <p class="w-smalltext" style="margin-bottom:0"><b>WSET Level 3 {{wset3.version}} Systematic Approach to Tasting Wine.</b></p>
        <p class="w-smalltext" style="margin-bottom:0">© Copyright Wine & Spirit Education Trust 2022. All Rights Reserved.</p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="wset3-button-left" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="wset3-button-right" @click="ok()">
              back
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="AdditionalRemarks" title="additional remarks" size="sm" @ok="onSaveAdditionalRemarks" @cancel="hideModal_AdditionalRemarks" scrollable>
      <b-row style="margin-left:0px;margin-right:5px">
        <b-col cols="1" class="text-left" style="padding:0">
          <b-img v-if="leftVisible_AR"
            class="w-cursor-click"
            width=30px
            height=30px
            :src="require('@/assets/img/left.png')"
            @click="onLeft_AR"
          >
          </b-img>
        </b-col>                
        <b-col cols="10" class="text-center" style="padding:0">
          <p v-if="this.pageCount_AR == 0" 
            class="current-page w-cursor-default"
          >
            <b>appearance</b> -
          </p>
          <p v-else 
            class="w-page w-cursor-click"
            @click="onAppearance_AR"
          >
            appearance -
          </p>

          <p v-if="this.pageCount_AR == 1" 
            class="current-page w-cursor-default"
          >
            <b>nose</b> -
          </p>
          <p v-else 
            class="w-page w-cursor-click"
            @click="onNose_AR"
          >
            nose -
          </p>

          <p v-if="this.pageCount_AR == 2" 
            class="current-page w-cursor-click"
          >
            <b>palate</b> -
          </p>
          <p v-else 
            class="w-page w-cursor-click"
            @click="onPalate_AR"
          >
            palate -
          </p>

          <p v-if="this.pageCount_AR == 3" 
            class="current-page w-cursor-click"
          >
            <b>conclusions</b>
          </p>
          <p v-else 
            class="w-page w-cursor-click"
            @click="onConclusions_AR"
          >
            conclusions
          </p>

          <p class="w-smalltext" style="text-align:center;">page {{this.pageCount_AR+1}}/{{this.maxPage_AR+1}}</p>
        </b-col>
        <b-col cols="1" class="text-right" style="padding:0">
          <b-img v-if="rightVisible_AR"
            class="w-cursor-click"
            width=30px
            height=30px
            :src="require('@/assets/img/right.png')"
            @click="onRight_AR"
          >
          </b-img>
        </b-col>
        <br>                
      </b-row>
      <hr style="margin:0 0 1rem 0">
      <div v-if="pageCount_AR == 0">
        <b-row>
          <b-col cols="3" style="padding-right:0">
            <p class="t-text text-right" style="font-size:14px"><b>wset:</b></p>
          </b-col>
          <b-col cols="9">
            <p class="t-text" style="font-size:14px;padding-left:5px">{{tasting_note.wset.appearance}}</p>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="3" style="padding-right:0">
            <p class="t-text text-right" style="font-size:14px"><b>additional remarks:</b></p>
          </b-col>
          <b-col cols="9" style="padding-left:10px">
            <b-form-textarea
              id="input-additional-appearance"
              class="t-text w-textarea"
              rows="3"
              max-rows="10"
              v-model="tasting_note.additional_remarks.appearance"
              @change="onSaveAdditionalRemarks"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="additional comments on the appearance of the wine"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </div>
      <div v-if="pageCount_AR == 1">
        <b-row>
          <b-col cols="3" style="padding-right:0">
            <p class="t-text text-right" style="font-size:14px"><b>wset:</b></p>
          </b-col>
          <b-col cols="9">
            <p class="t-text" style="font-size:14px;padding-left:5px">{{tasting_note.wset.nose}}</p>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="3" style="padding-right:0">
            <p class="t-text text-right" style="font-size:14px"><b>additional remarks:</b></p>
          </b-col>
          <b-col cols="9" style="padding-left:10px">
            <b-form-textarea
              id="input-additional-appearance"
              class="t-text w-textarea"
              rows="3"
              max-rows="10"
              v-model="tasting_note.additional_remarks.nose"
              @change="onSaveAdditionalRemarks"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="additional comments on the nose of the wine"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </div>
      <div v-if="pageCount_AR == 2">
        <b-row>
          <b-col cols="3" style="padding-right:0">
            <p class="t-text text-right" style="font-size:14px"><b>wset:</b></p>
          </b-col>
          <b-col cols="9">
            <p class="t-text" style="font-size:14px;padding-left:5px">{{tasting_note.wset.palate}}</p>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="3" style="padding-right:0">
            <p class="t-text text-right" style="font-size:14px"><b>additional remarks:</b></p>
          </b-col>
          <b-col cols="9" style="padding-left:10px">
            <b-form-textarea
              id="input-additional-appearance"
              class="t-text w-textarea"
              rows="3"
              max-rows="10"
              v-model="tasting_note.additional_remarks.palate"
              @change="onSaveAdditionalRemarks"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="additional comments on the palate of the wine"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </div>
      <div v-if="pageCount_AR == 3">
        <b-row>
          <b-col cols="3" style="padding-right:0">
            <p class="t-text text-right" style="font-size:14px"><b>wset:</b></p>
          </b-col>
          <b-col cols="9">
            <p class="t-text" style="font-size:14px;padding-left:5px">{{tasting_note.wset.conclusions}}</p>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="3" style="padding-right:0">
            <p class="t-text text-right" style="font-size:14px"><b>additional remarks:</b></p>
          </b-col>
          <b-col cols="9" style="padding-left:10px">
            <b-form-textarea
              id="input-additional-appearance"
              class="t-text w-textarea"
              rows="3"
              max-rows="10"
              v-model="tasting_note.additional_remarks.conclusions"
              @change="onSaveAdditionalRemarks"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="additional comments on the conclusions of the wine"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="Warning" size="md" @ok="onSaveNewChoice" @cancel="onCancelNewChoice">
      <template #modal-header>
        <div>
          <font-awesome-icon 
          id="warning"
          class="w-cursor-click" 
          style="font-size:24px;display:inline-block" 
          icon="exclamation-triangle"
          color="rgb(195,147,130)" 
        />
        <p style="display:inline-block; margin-bottom:0; font-size:24px; font-weight:bold; color:rgb(195,147,130)">&nbsp warning</p>       
        </div>
      </template>

      <p class="w-text"> {{ change_message }} </p>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="ok()">
              proceed and init
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="w-button-green" @click="cancel()">
              don't proceed
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>    
  </div>
</template>

<script>
  import formatDate from '../assets/js/formatDate.js';
  import {isEqualAoA,isInAoA,removeFromAoA,sliceAoA,orderAoA} from '../assets/js/AoA.js';
  import {wset2,wset2_lookup} from '../assets/js/wset2_issue2.js';
  import {wset3,wset3_lookup} from '../assets/js/wset3_issue2.js';

  import NoAccess from './NoAccess.vue'

  import Tastings from '../Tastings.js' 
  import TastingNotes from '../TastingNotes.js'
  import Items from '../Items.js'
  
  import RangeSlider from "vue-range-slider";
  import "vue-range-slider/dist/vue-range-slider.css";

  // At the top of your script, outside of the export default
  const imagesContext = require.context('@/assets/img/wset/', false, /\.webp$/);

  export default {
    name: "TastingNote",
    components: {
      NoAccess,
      RangeSlider,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      number_of_notes() {
        return this.tasting.tasting_note_ids.length;
      },
      style() {
        // let style = "position:absolute;left:"+this.Y+"px;top:"+this.X+"px"
        let style = "position:absolute;left:60px;top:390px;background-color:white"
        console.log("style change: style = ", style)
        return style
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        nameValid: true,
        nameFeedback:'',

        isValid: true,

        user:{},
        user_id:0,

        // tasting_note
        tasting_note:{
          date:'',
          owner:'',
          name:'',
          appearance:'',
          nose:'',
          palate:'',
          conclusions:'',
          score:0,
          item_id:0,
          wset:{level:"", selected_indices:[],text:""},
          additional_remarks:{appearance:"",nose:"",palate:"",conclusions:""},
          wset_text:"",
          share:true,
        },
        tasting_note_id:0,

        // tasting_note form
        selected: 'short',
        options: [
          { text: 'short', value: 'short' },
          { text: 'long', value: 'long' },
          { text: 'wset2', value: 'wset2' },
          { text: 'wset3', value: 'wset3' }
        ],
        dateValid: true,
        dateFeedback: '',
        scoreValid:true,
        scoreFeedback:'',

        tasting_note_isValid: true,

        // paging 'long'
        pageCount: 0,
        maxPage: 3,
        leftVisible: false,
        rightVisible: true,

        // paging 'AdditionalRemarks'
        pageCount_AR: 0,
        maxPage_AR: 3,
        leftVisible_AR: false,
        rightVisible_AR: true,

        letter_score:["E-","E","E+","D-","D","D+","C-","C","C+","B-","B","B+","A-","A","A+"],
      
        //wset
        wset2:{},
        wset3:{},
        selected_indices:[],
        flavour_profiling:[],
        wine_lexicon:'',
        update_wset:0,

        //show images
        showPicture: true,
        showPicture_save: true,
        imageSrc:"",
        mouseX: 0,
        mouseY: 0,

        // clicked flavour
        flavourName:"",
        flavourIndexArray:[],
        flavourImage:"",

        //change tasting_note type
        change_message: "",
        update_toggle:0, 
        
        // Click/DblClick
        lastClickTime:0,
        clicks:0,
        showFlavourProfile: false,

        flavour_di:"standard",
        options_di:[
                    {text:'delicate',value:'delicate'},
                    {text:'standard',value:'standard'},
                    {text:'intense',value:'intense'}
                    ],
        flavour_sc:"standard",
        options_sc:[
                    {text:'simple',value:'simple'},
                    {text:'standard',value:'standard'},
                    {text:'complex',value:'complex'}
                    ],
        flavour_gw:"standard",
        options_gw:[
                    {text:'generic',value:'generic'},
                    {text:'standard',value:'standard'},
                    {text:'well-defined',value:'well-defined'}
                    ],
        flavour_fc:"standard",
        options_fc:[
                    {text:'fresh',value:'fresh'},
                    {text:'standard',value:'standard'},
                    {text:'cooked',value:'cooked'}
                    ],
        flavour_ur:"ripe",
        options_ur:[
                    {text:'under-ripe',value:'under-ripe'},
                    {text:'ripe',value:'ripe'},
                    {text:'over-ripe',value:'over-ripe'}
                    ],
        X:0,
        Y:0,
      }
    }, 
    async mounted() {
      let local_debug = false
      if (local_debug || this.debug) console.log("")
      if (local_debug || this.debug) console.log("=====> TastingNote.mounted: START")

      this.wset2 = wset2
      let test_wset2 = false
      if (test_wset2) {     
        if (test_wset2) console.log("TEST wset2 - START")
        if (test_wset2) console.log("wset2=",wset2)

        for ( let h=0 ; h < wset2.SAT.length ; h++) {
          if (test_wset2) console.log("wset2.SAT[",h,"]=",wset2.SAT[h])
          if (test_wset2) console.log("wset2.SAT[",h,"].property=",wset2.SAT[h].property)
          if (wset2.SAT[h].goesDeeper == true) {
            for (let i=0 ; i < wset2.SAT[h].values.length ; i++) {
              if (test_wset2) console.log("-- wset2.SAT[",h,"].values[",i,"]=",wset2.SAT[h].values[i])
              if (test_wset2) console.log("-- wset2.SAT[",h,"].values[",i,"].property=",wset2.SAT[h].values[i].property)
              if ( wset2.SAT[h].values[i].goesDeeper == true) {
                for (let j=0 ; j < wset2.SAT[h].values[i].values.length ; j++) {
                  if (test_wset2) console.log("---- wset2[",h,"].values[",i,"].values[",j,"]=",wset2.SAT[h].values[i].values[j])
                  if (test_wset2) console.log("---- wset2[",h,"].values[",i,"].values[",j,"].property=",wset2.SAT[h].values[i].values[j].property)
                  if (wset2.SAT[h].values[i].values[j].goesDeeper == true) {
                    for (let k=0 ; k < wset2.SAT[h].values[i].values[j].values.length ; k++) {
                      if (test_wset2) console.log("------ wset2[",h,"].values[",i,"].values[",j,"].values[",k,"]=",wset2.SAT[h].values[i].values[j].values[k])
                      if (test_wset2) console.log("------ wset2[",h,"].values[",i,"].values[",j,"].values[",k,"].property=",wset2.SAT[h].values[i].values[j].values[k].property)
                      if(wset2.SAT[h].values[i].values[j].values[k].goesDeeper == true) {
                        for (let l=0 ; l < wset2.SAT[h].values[i].values[j].values[k].values.length ; k++) {
                          if (test_wset2) console.log("-------- wset2[",h,"].values[",i,"].values[",j,"].values[",k,"]..values[",l,"]=",wset2.SAT[h].values[i].values[j].values[k].values[l])
                          if (test_wset2) console.log("-------- wset2[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"].property=",wset2.SAT[h].values[i].values[j].values[k].values[l].property)
                          if(wset2.SAT[h].values[i].values[j].values[k].values[l].goesDeeper == true) {
                            if (test_wset2) console.log("-------- ERROR depth > 4")
                          } else {
                            for (let m=0 ; m < wset2.SAT[h].values[i].values[j].values[k].values[l].values.length ; m++) {
                              if (test_wset2) console.log("-------- wset2[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"].values[",m,"]=",wset2.SAT[h].values[i].values[j].values[k].values[l].values[m])
                            }
                          }
                        }
                      } else {
                        for (let l=0 ; l < wset2.SAT[h].values[i].values[j].values[k].values.length ; l++) {
                          if (test_wset2) console.log("------ wset2[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"]=",wset2.SAT[h].values[i].values[j].values[k].values[l])
                        }
                      }
                    }
                  } else {
                    for (let k=0 ; k < wset2.SAT[h].values[i].values[j].values.length ; k++) {
                      if (test_wset2) console.log("---- wset2[",h,"][",i,"].values[",j,"].values[",k,"]=",wset2.SAT[h].values[i].values[j].values[k])
                    }
                  }
                }
              } else {
                for (let j=0 ; j < wset2.SAT[h].values[i].values.length ; j++){
                  if (test_wset2) console.log("-- wset2[",h,"].values[",i,"].values[",j,"] =",wset2.SAT[h].values[i].values[j])
                }
              }
            }
          } else {
            for (let i=0 ; i < wset2[i].values.length ; i++){
              if (test_wset2) console.log(" wset2[",h,"].values[",i,"] =",wset2.SAT[h].values[i])
            }
          }
        }

        for ( let h=0 ; h < wset2.wine_lexicon.length ; h++) {
          if (test_wset2) console.log("wset2.SAT[",h,"]=",wset2.SAT[h])
          if (test_wset2) console.log("wset2.SAT[",h,"].property=",wset2.wine_lexicon[h].property)
          if (wset2.wine_lexicon[h].goesDeeper == true) {
            for (let i=0 ; i < wset2.wine_lexicon[h].values.length ; i++) {
              if (test_wset2) console.log("-- wset2.SAT[",h,"].values[",i,"]=",wset2.wine_lexicon[h].values[i])
              if (test_wset2) console.log("-- wset2.SAT[",h,"].values[",i,"].property=",wset2.wine_lexicon[h].values[i].property)
              if ( wset2.wine_lexicon[h].values[i].goesDeeper == true) {
                for (let j=0 ; j < wset2.wine_lexicon[h].values[i].values.length ; j++) {
                  if (test_wset2) console.log("---- wset2[",h,"].values[",i,"].values[",j,"]=",wset2.wine_lexicon[h].values[i].values[j])
                  if (test_wset2) console.log("---- wset2[",h,"].values[",i,"].values[",j,"].property=",wset2.wine_lexicon[h].values[i].values[j].property)
                  if (wset2.wine_lexicon[h].values[i].values[j].goesDeeper == true) {
                    for (let k=0 ; k < wset2.wine_lexicon[h].values[i].values[j].values.length ; k++) {
                      if (test_wset2) console.log("------ wset2[",h,"].values[",i,"].values[",j,"].values[",k,"]=",wset2.wine_lexicon[h].values[i].values[j].values[k])
                      if (test_wset2) console.log("------ wset2[",h,"].values[",i,"].values[",j,"].values[",k,"].property=",wset2.wine_lexicon[h].values[i].values[j].values[k].property)
                      if(wset2.wine_lexicon[h].values[i].values[j].values[k].goesDeeper == true) {
                        for (let l=0 ; l < wset2.wine_lexicon[h].values[i].values[j].values[k].values.length ; k++) {
                          if (test_wset2) console.log("-------- wset2[",h,"].values[",i,"].values[",j,"].values[",k,"]..values[",l,"]=",wset2.wine_lexicon[h].values[i].values[j].values[k].values[l])
                          if (test_wset2) console.log("-------- wset2[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"].property=",wset2.wine_lexicon[h].values[i].values[j].values[k].values[l].property)
                          if(wset2.wine_lexicon[h].values[i].values[j].values[k].values[l].goesDeeper == true) {
                            if (test_wset2) console.log("-------- ERROR depth > 4")
                          } else {
                            for (let m=0 ; m < wset2.wine_lexicon[h].values[i].values[j].values[k].values[l].values.length ; m++) {
                              if (test_wset2) console.log("-------- wset2[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"].values[",m,"]=",wset2.wine_lexicon[h].values[i].values[j].values[k].values[l].values[m])
                            }
                          }
                        }
                      } else {
                        for (let l=0 ; l < wset2.wine_lexicon[h].values[i].values[j].values[k].values.length ; l++) {
                          if (test_wset2) console.log("------ wset2[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"]=",wset2.wine_lexicon[h].values[i].values[j].values[k].values[l])
                        }
                      }
                    }
                  } else {
                    for (let k=0 ; k < wset2.wine_lexicon[h].values[i].values[j].values.length ; k++) {
                      if (test_wset2) console.log("---- wset2[",h,"][",i,"].values[",j,"].values[",k,"]=",wset2.wine_lexicon[h].values[i].values[j].values[k])
                    }
                  }
                }
              } else {
                for (let j=0 ; j < wset2.wine_lexicon[h].values[i].values.length ; j++){
                  if (test_wset2) console.log("-- wset2[",h,"].values[",i,"].values[",j,"] =",wset2.wine_lexicon[h].values[i].values[j])
                }
              }
            }
          } else {
            for (let i=0 ; i < wset2[i].values.length ; i++){
              if (test_wset2) console.log(" wset2[",h,"].values[",i,"] =",wset2.wine_lexicon[h].values[i])
            }
          }
        }
        if (test_wset2) console.log(" TEST wset2 - STOP")
      }

      this.wset3 = wset3
      let test_wset3 = false
      if (test_wset3) {     
        if (test_wset3) console.log("TEST wset3 - START")
        if (test_wset3) console.log("wset3=",wset3)

        for ( let h=0 ; h < wset3.SAT.length ; h++) {
          if (test_wset3) console.log("wset3.SAT[",h,"]=",wset3.SAT[h])
          if (test_wset3) console.log("wset3.SAT[",h,"].property=",wset3.SAT[h].property)
          if (wset3.SAT[h].goesDeeper == true) {
            for (let i=0 ; i < wset3.SAT[h].values.length ; i++) {
              if (test_wset3) console.log("-- wset3.SAT[",h,"].values[",i,"]=",wset3.SAT[h].values[i])
              if (test_wset3) console.log("-- wset3.SAT[",h,"].values[",i,"].property=",wset3.SAT[h].values[i].property)
              if ( wset3.SAT[h].values[i].goesDeeper == true) {
                for (let j=0 ; j < wset3.SAT[h].values[i].values.length ; j++) {
                  if (test_wset3) console.log("---- wset3[",h,"].values[",i,"].values[",j,"]=",wset3.SAT[h].values[i].values[j])
                  if (test_wset3) console.log("---- wset3[",h,"].values[",i,"].values[",j,"].property=",wset3.SAT[h].values[i].values[j].property)
                  if (wset3.SAT[h].values[i].values[j].goesDeeper == true) {
                    for (let k=0 ; k < wset3.SAT[h].values[i].values[j].values.length ; k++) {
                      if (test_wset3) console.log("------ wset3[",h,"].values[",i,"].values[",j,"].values[",k,"]=",wset3.SAT[h].values[i].values[j].values[k])
                      if (test_wset3) console.log("------ wset3[",h,"].values[",i,"].values[",j,"].values[",k,"].property=",wset3.SAT[h].values[i].values[j].values[k].property)
                      if(wset3.SAT[h].values[i].values[j].values[k].goesDeeper == true) {
                        for (let l=0 ; l < wset3.SAT[h].values[i].values[j].values[k].values.length ; k++) {
                          if (test_wset3) console.log("-------- wset3[",h,"].values[",i,"].values[",j,"].values[",k,"]..values[",l,"]=",wset3.SAT[h].values[i].values[j].values[k].values[l])
                          if (test_wset3) console.log("-------- wset3[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"].property=",wset3.SAT[h].values[i].values[j].values[k].values[l].property)
                          if(wset3.SAT[h].values[i].values[j].values[k].values[l].goesDeeper == true) {
                            if (test_wset3) console.log("-------- ERROR depth > 4")
                          } else {
                            for (let m=0 ; m < wset3.SAT[h].values[i].values[j].values[k].values[l].values.length ; m++) {
                              if (test_wset3) console.log("-------- wset3[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"].values[",m,"]=",wset3.SAT[h].values[i].values[j].values[k].values[l].values[m])
                            }
                          }
                        }
                      } else {
                        for (let l=0 ; l < wset3.SAT[h].values[i].values[j].values[k].values.length ; l++) {
                          if (test_wset3) console.log("------ wset3[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"]=",wset3.SAT[h].values[i].values[j].values[k].values[l])
                        }
                      }
                    }
                  } else {
                    for (let k=0 ; k < wset3.SAT[h].values[i].values[j].values.length ; k++) {
                      if (test_wset3) console.log("---- wset3[",h,"][",i,"].values[",j,"].values[",k,"]=",wset3.SAT[h].values[i].values[j].values[k])
                    }
                  }
                }
              } else {
                for (let j=0 ; j < wset3.SAT[h].values[i].values.length ; j++){
                  if (test_wset3) console.log("-- wset3[",h,"].values[",i,"].values[",j,"] =",wset3.SAT[h].values[i].values[j])
                }
              }
            }
          } else {
            for (let i=0 ; i < wset3[i].values.length ; i++){
              if (test_wset3) console.log(" wset3[",h,"].values[",i,"] =",wset3.SAT[h].values[i])
            }
          }
        }

        for ( let h=0 ; h < wset3.wine_lexicon.length ; h++) {
          if (test_wset3) console.log("wset3.SAT[",h,"]=",wset3.SAT[h])
          if (test_wset3) console.log("wset3.SAT[",h,"].property=",wset3.wine_lexicon[h].property)
          if (wset3.wine_lexicon[h].goesDeeper == true) {
            for (let i=0 ; i < wset3.wine_lexicon[h].values.length ; i++) {
              if (test_wset3) console.log("-- wset3.SAT[",h,"].values[",i,"]=",wset3.wine_lexicon[h].values[i])
              if (test_wset3) console.log("-- wset3.SAT[",h,"].values[",i,"].property=",wset3.wine_lexicon[h].values[i].property)
              if ( wset3.wine_lexicon[h].values[i].goesDeeper == true) {
                for (let j=0 ; j < wset3.wine_lexicon[h].values[i].values.length ; j++) {
                  if (test_wset3) console.log("---- wset3[",h,"].values[",i,"].values[",j,"]=",wset3.wine_lexicon[h].values[i].values[j])
                  if (test_wset3) console.log("---- wset3[",h,"].values[",i,"].values[",j,"].property=",wset3.wine_lexicon[h].values[i].values[j].property)
                  if (wset3.wine_lexicon[h].values[i].values[j].goesDeeper == true) {
                    for (let k=0 ; k < wset3.wine_lexicon[h].values[i].values[j].values.length ; k++) {
                      if (test_wset3) console.log("------ wset3[",h,"].values[",i,"].values[",j,"].values[",k,"]=",wset3.wine_lexicon[h].values[i].values[j].values[k])
                      if (test_wset3) console.log("------ wset3[",h,"].values[",i,"].values[",j,"].values[",k,"].property=",wset3.wine_lexicon[h].values[i].values[j].values[k].property)
                      if(wset3.wine_lexicon[h].values[i].values[j].values[k].goesDeeper == true) {
                        for (let l=0 ; l < wset3.wine_lexicon[h].values[i].values[j].values[k].values.length ; k++) {
                          if (test_wset3) console.log("-------- wset3[",h,"].values[",i,"].values[",j,"].values[",k,"]..values[",l,"]=",wset3.wine_lexicon[h].values[i].values[j].values[k].values[l])
                          if (test_wset3) console.log("-------- wset3[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"].property=",wset3.wine_lexicon[h].values[i].values[j].values[k].values[l].property)
                          if(wset3.wine_lexicon[h].values[i].values[j].values[k].values[l].goesDeeper == true) {
                            if (test_wset3) console.log("-------- ERROR depth > 4")
                          } else {
                            for (let m=0 ; m < wset3.wine_lexicon[h].values[i].values[j].values[k].values[l].values.length ; m++) {
                              if (test_wset3) console.log("-------- wset3[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"].values[",m,"]=",wset3.wine_lexicon[h].values[i].values[j].values[k].values[l].values[m])
                            }
                          }
                        }
                      } else {
                        for (let l=0 ; l < wset3.wine_lexicon[h].values[i].values[j].values[k].values.length ; l++) {
                          if (test_wset3) console.log("------ wset3[",h,"].values[",i,"].values[",j,"].values[",k,"].values[",l,"]=",wset3.wine_lexicon[h].values[i].values[j].values[k].values[l])
                        }
                      }
                    }
                  } else {
                    for (let k=0 ; k < wset3.wine_lexicon[h].values[i].values[j].values.length ; k++) {
                      if (test_wset3) console.log("---- wset3[",h,"][",i,"].values[",j,"].values[",k,"]=",wset3.wine_lexicon[h].values[i].values[j].values[k])
                    }
                  }
                }
              } else {
                for (let j=0 ; j < wset3.wine_lexicon[h].values[i].values.length ; j++){
                  if (test_wset3) console.log("-- wset3[",h,"].values[",i,"].values[",j,"] =",wset3.wine_lexicon[h].values[i].values[j])
                }
              }
            }
          } else {
            for (let i=0 ; i < wset3[i].values.length ; i++){
              if (test_wset3) console.log(" wset3[",h,"].values[",i,"] =",wset3.wine_lexicon[h].values[i])
            }
          }
        }
        if (test_wset3) console.log(" TEST wset3 - STOP")
      }

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      if (this.$store.getters.getTastingID != 0) {
        this.tasting_id = this.$store.getters.getTastingID
        this.tasting = this.$store.getters.getTasting
        this.tasting.nice_date = formatDate(new Date(this.tasting.date))
        if (local_debug || this.debug) console.log("=====> TastingNote.mounted: tasting ", this.tasting, " with id ", this.tasting_id,  " found in store.")
      } else {
        if (local_debug || this.debug) console.log("=====> TastingNote.mounted: NO tasting found in store.")
      }
      
      if (this.$store.getters.getTastingnoteID != 0) {
        this.tasting_note_id = this.$store.getters.getTastingnoteID
        this.tasting_note = this.$store.getters.getTastingnote
        if (local_debug || this.debug) console.log("=====> TastingNote.mounted: tasting_note ", this.tasting_note, " with id ", this.tasting_note_id,  " found in store.")
      } else {
        if (local_debug || this.debug) console.log("=====> TastingNote.mounted: NO tasting_note found in store.")
      }

      // TEMP
      if (typeof this.tasting_note.share == "undefined") this.tasting_note.share = true
      if (typeof this.tasting_note.wset == "undefined") this.tasting_note.wset = {level:"",selected_indices:[],text:""}
      if (typeof this.tasting_note.additional_remarks == "undefined") this.tasting_note.additional_remarks = {appearance:"",nose:"",palate:"",conclusions:""}
      // TEMP

      if (
        (this.tasting_note.appearance != "" && typeof this.tasting_note.appearance != "undefined") ||
        (this.tasting_note.nose != "" && typeof this.tasting_note.nose != "undefined") ||
        (this.tasting_note.palate != "" && typeof this.tasting_note.palate != "undefined")
        ) {
        this.selected = 'long'
      } else {
        this.selected = 'short'
        this.update_toggle++
      }
      if (local_debug || this.debug) console.log("=====> TastingNote.mounted: this.tasting_note.wset = ",this.tasting_note.wset)
      if (typeof this.tasting_note.wset == "object" && this.tasting_note.wset.level != "") {
        this.selected = this.tasting_note.wset.level
      }

      let today = new Date();
      // Initialise tasting_note.date
      if (this.tasting_note_id == 0) {
        this.tasting_note.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      }

      this.isLoading = false

      if (local_debug || this.debug) console.log("=====> TastingNote.mounted: STOP")
    },

    methods: {
      validate() {
        if( this.tasting_note.name.length >= 3) {
          this.nameValid = true;
          this.nameFeedback = ''
        } else {
          this.nameValid = false
          this.nameFeedback = 'please select name of minimum 3 characters'
        }
        this.isValid = this.nameValid
      },
      
      onLeft() {
        this.pageCount--;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },
      onRight() {
        this.pageCount++;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },

      async onSave() {
        let local_debug = false
        if (this.debug || local_debug) console.log("=====> TastingNote.onSafe: START")
        await this.onSoftSave()

        //Update Parent List        
        await this.$emit('refreshRequest');
        
        if (this.debug || local_debug) console.log("=====> TastingNote.onSafe: emmited refreshRequest")
        if (this.debug || local_debug) console.log("=====> TastingNote.onSafe: STOP")
      },

      async onSoftSave() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("=====> TastingNote.onSoftSafe: START")

        this.validate()
        if (this.isValid == false) {
          return
        }
        
        this.tasting_note.tasting_id = this.tasting_id

        if (this.tasting_note_id != 0) {
          if (this.debug || local_debug) console.log("=====> TastingNote.onSoftSafe: Tasting Note exists and has id = ",this.tasting_note_id," we update it")
          delete this.tasting_note._id

          // START- This code snippet is to make shure the proper isDisclosed property is respected in this Save
          if (this.tasting_note.item_id != 0) {
            await Items.getone(this.tasting_note.item_id)
            .then(response => {
              if (typeof response == "object") {
                if (typeof response.isDisclosed != "undefined") this.tasting_note.isDisclosed = response.isDisclosed
                if (local_debug || this.debug) console.log("TastingNote.onSoftSave: setting tasting_note.isDisclosed = ", response.isDisclosed)
              } else {
                if (local_debug || this.debug) console.log("TastingNote.onSoftSave: ERROR retrieved tasting_note is not an object.")
              }
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("TastingNote.onSoftSave: ==> await TastingNotes.getone", error);
            })
          }
          // STOP- This code snippet is to make shure the proper isDisclosed property is respected in this Save

          await TastingNotes.update(this.tasting_note_id,this.tasting_note)
          .then(response => {
            if(this.debug) console.log("=====> TastingNote.onSoftSafe: Tasting Note ",this.tasting_note," succesfully updated with response ", response);
          })
          .catch(error => {
            if(this.debug) console.log("=====> TastingNote.onSoftSafe: catch await TastingNotes.update ", error);
          })
        } else {
          if (this.debug || local_debug) console.log("=====> TastingNote.onSoftSafe: Tasting Note DOES NOT exists - we create it")
          await TastingNotes.create(this.tasting_note)
          .then(response => {
            this.tasting_note_id = response;
            if(this.debug) console.log("=====> TastingNote.onSoftSafe: Tasting Note '",this.tasting_note,"' succesfully created with response ", response);
          })
          .catch(error => {
            if(this.debug) console.log("=====> TastingNote.onSoftSafe: catch await TastingNotes.create ", error);
          })

          // Adding this.tasting_note_id to the tasting_note_ids list of this.tasting 
          if (this.debug || local_debug) console.log("========> TastingNote.onSoftSafe: Adding tasting_note_id ",this.tasting_note_id," to the tasting_note_ids list of this.tasting ", this.tasting)       
          this.tasting.tasting_note_ids.push(this.tasting_note_id);
          await Tastings.update(this.tasting_id,this.tasting)
          .then(response=>{
            if (this.debug || local_debug) console.log("========> TastingNote.onSoftSafe: tasting_note_id ",this.tasting_note_id," succesfully added to tasting ", this.tasting, " with response ", response);
          })
          .catch(error=>{
            if (this.debug || local_debug) console.log("========> TastingNote.onSoftSafe: catch await TastingNotes.update ", error);
          })
       }
        if (this.debug || local_debug) console.log("=====> TastingNote.onSoftSafe: STOP")
      },

      async onAppearance() {
        this.pageCount = 0;
        this.leftVisible = false;
        this.rightVisible = true;
      }, 
      async onNose() {
        this.pageCount = 1;
        this.leftVisible = true;
        this.rightVisible = true;
      },
      async onPalate() {
        this.pageCount = 2;
        this.leftVisible = true;
        this.rightVisible = true;
      },
      async onConclusions() {
        this.pageCount = 3;
        this.leftVisible = true;
        this.rightVisible = false;
      },

      onLeft_AR() {
        this.pageCount_AR--;
        if (this.pageCount_AR > 0) {
          this.leftVisible_AR = true;
        } else {
          this.leftVisible_AR = false;
        }
        if (this.pageCount_AR >= this.maxPage_AR) {
          this.rightVisible_AR = false;
        } else {
          this.rightVisible_AR = true;
        }
      },
      onRight_AR() {
        this.pageCount_AR++;
        if (this.pageCount_AR > 0) {
          this.leftVisible_AR = true;
        } else {
          this.leftVisible_AR = false;
        }
        if (this.pageCount_AR >= this.maxPage_AR) {
          this.rightVisible_AR = false;
        } else {
          this.rightVisible_AR = true;
        }
      },

      async onAppearance_AR() {
        this.pageCount_AR = 0;
        this.leftVisible_AR = false;
        this.rightVisible_AR = true;
      }, 
      async onNose_AR() {
        this.pageCount_AR = 1;
        this.leftVisible_AR = true;
        this.rightVisible_AR = true;
      },
      async onPalate_AR() {
        this.pageCount_AR = 2;
        this.leftVisible_AR = true;
        this.rightVisible_AR = true;
      },
      async onConclusions_AR() {
        this.pageCount_AR = 3;
        this.leftVisible_AR = true;
        this.rightVisible_AR = false;
      },
      
      async showModal_WSET2_SAT() {
        this.selected_indices = this.tasting_note.wset.selected_indices
        this.$root.$emit("bv::show::modal", "WSET2_SAT");
      },

      async showModal_WSET3_SAT() {
        this.selected_indices = this.tasting_note.wset.selected_indices
        if ( typeof this.tasting_note.wset.flavour_profiling != "undefined") {
          this.flavour_profiling = this.tasting_note.wset.flavour_profiling
        } else {
          this.flavour_profiling = []
        }
        
        this.$root.$emit("bv::show::modal", "WSET3_SAT");
      },

      async showModal_WSET2_WineLexicon() {
        this.selected_indices = this.tasting_note.wset.selected_indices
        this.$root.$emit("bv::show::modal", "WSET2_WineLexicon");
      },

      async showModal_WSET3_WineLexicon() {
        this.selected_indices = this.tasting_note.wset.selected_indices
        this.$root.$emit("bv::show::modal", "WSET3_WineLexicon");
      },

      async hideModal_WSET2_SAT() {
        this.$root.$emit("bv::hide::modal", "WSET2_SAT");
      },

      async hideModal_WSET3_SAT() {
        this.$root.$emit("bv::hide::modal", "WSET3_SAT");
      },

      async hideModal_WSET2_WineLexicon() {
        this.wine_lexicon = ''
        this.$root.$emit("bv::hide::modal", "WSET2_WineLexicon");
      },

      async hideModal_WSET3_WineLexicon() {
        this.wine_lexicon = ''
        this.$root.$emit("bv::hide::modal", "WSET3_WineLexicon");
      },

      async showModal_AdditionalRemarks() {
        this.$root.$emit("bv::show::modal", "AdditionalRemarks");
      },

      async hideModal_AdditionalRemarks() {
        this.$root.$emit("bv::hide::modal", "AdditionalRemarks");
      },

      async saveWSET2_SAT() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: START")
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: this.tasting_note = ", JSON.stringify(this.tasting_note))        

        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: unsorted this.selected_indices = ", JSON.stringify(this.selected_indices))
        this.selected_indices = await orderAoA(this.selected_indices)
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: sorted this.selected_indices = ", JSON.stringify(this.selected_indices))
     
        let  {appearance, nose, palate, conclusions,text} = await this.generateText_WSET2(this.selected_indices)
        let wset = {
          "level": "wset2",
          "issue": wset2.issue,
          "selected_indices": this.selected_indices,
          "appearance": appearance,
          "nose": nose,
          "palate": palate,
          "conclusions": conclusions,
        }

        this.tasting_note.wset = wset
        this.update_wset++

        this.tasting_note.appearance = wset.appearance + this.tasting_note.additional_remarks.appearance
        this.tasting_note.nose = wset.nose + this.tasting_note.additional_remarks.nose
        this.tasting_note.palate = wset.palate + this.tasting_note.additional_remarks.palate
        this.tasting_note.conclusions = wset.conclusions + this.tasting_note.additional_remarks.conclusions

        this.tasting_note.wset_text   = ""
        this.tasting_note.wset_text  += "appearance: " + this.tasting_note.appearance + "\r\n"
        this.tasting_note.wset_text  += "nose: " + this.tasting_note.nose + "\r\n"
        this.tasting_note.wset_text  += "palate: " + this.tasting_note.palate + "\r\n"
        this.tasting_note.wset_text  += "conclusions: " + this.tasting_note.conclusions + "\r\n"

        this.onSave();

        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: this.tasting_note = ", JSON.stringify(this.tasting_note))
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: this.tasting_note.wset_text = ", JSON.stringify(this.tasting_note.wset_text))
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: STOP")
      },

      async saveWSET2_WineLexicon() {},

      async saveWSET3_SAT() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: START")
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: this.tasting_note = ", JSON.stringify(this.tasting_note))        

        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: unsorted this.selected_indices = ", JSON.stringify(this.selected_indices))
        this.selected_indices = await orderAoA(this.selected_indices)
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: sorted this.selected_indices = ", JSON.stringify(this.selected_indices))
     
        let  {appearance, nose, palate, conclusions, text} = await this.generateText_WSET3(this.selected_indices)

        let wset = {
          "level": "wset3",
          "issue": wset3.issue,
          "selected_indices": this.selected_indices,
          "flavour_profiling": this.flavour_profiling,
          "appearance": appearance,
          "nose": nose,
          "palate": palate,
          "conclusions": conclusions,
        }

        this.tasting_note.wset = wset
        this.update_wset++

        this.tasting_note.appearance = wset.appearance + this.tasting_note.additional_remarks.appearance
        this.tasting_note.nose = wset.nose + this.tasting_note.additional_remarks.nose
        this.tasting_note.palate = wset.palate + this.tasting_note.additional_remarks.palate
        this.tasting_note.conclusions = wset.conclusions + this.tasting_note.additional_remarks.conclusions

        this.tasting_note.wset_text   = ""
        this.tasting_note.wset_text  += "appearance: " + this.tasting_note.appearance + "\r\n"
        this.tasting_note.wset_text  += "nose: " + this.tasting_note.nose + "\r\n"
        this.tasting_note.wset_text  += "palate: " + this.tasting_note.palate + "\r\n"
        this.tasting_note.wset_text  += "conclusions: " + this.tasting_note.conclusions + "\r\n"

        this.onSave();

        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: this.tasting_note = ", JSON.stringify(this.tasting_note))
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: this.tasting_note.wset_text = ", JSON.stringify(this.tasting_note.wset_text))
        if (this.debug || local_debug) console.log("TastingNote.saveTastingGrid: STOP")
      },

      async saveWSET3_WineLexicon() {},

      async onSaveAdditionalRemarks() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.onSaveAdditionalRemarks: START")
        if (this.debug || local_debug) console.log("TastingNote.onSaveAdditionalRemarks: additional_remarks", this.tasting_note.additional_remarks)


        if (typeof this.tasting_note.additional_remarks.appearance != "undefined" && this.tasting_note.additional_remarks.appearance != "") {
          this.tasting_note.appearance = this.tasting_note.wset.appearance + "\r\n\t " + this.tasting_note.additional_remarks.appearance
        } else {
          this.tasting_note.appearance = this.tasting_note.wset.appearance
        }
        if (typeof this.tasting_note.additional_remarks.nose != "undefined" && this.tasting_note.additional_remarks.nose != "") {
          this.tasting_note.nose = this.tasting_note.wset.nose + "\r\n\t " + this.tasting_note.additional_remarks.nose
        } else {
          this.tasting_note.nose = this.tasting_note.wset.nose
        }
        if (typeof this.tasting_note.additional_remarks.palate != "undefined" && this.tasting_note.additional_remarks.palate != "") {
          this.tasting_note.palate = this.tasting_note.wset.palate + "\r\n\t " + this.tasting_note.additional_remarks.palate
        } else {
          this.tasting_note.palate = this.tasting_note.wset.palate
        }
        if (typeof this.tasting_note.additional_remarks.conclusions != "undefined" && this.tasting_note.additional_remarks.conclusions != "") {
          this.tasting_note.conclusions = this.tasting_note.wset.conclusions + "\r\n\t " + this.tasting_note.additional_remarks.conclusions
        } else {
          this.tasting_note.conclusions = this.tasting_note.wset.conclusions
        }

        this.tasting_note.wset_text   = ""
        this.tasting_note.wset_text  += "appearance: " + this.tasting_note.appearance + "\r\n"
        this.tasting_note.wset_text  += "nose: " + this.tasting_note.nose + "\r\n"
        this.tasting_note.wset_text  += "palate: " + this.tasting_note.palate + "\r\n"
        this.tasting_note.wset_text  += "conclusions: " + this.tasting_note.conclusions + "\r\n"

        this.update_wset++

        await TastingNotes.update(this.tasting_note_id,this.tasting_note)
          .then(response => {
            if(this.debug) console.log("=====> TastingNote.onSaveAdditionalRemarks: Tasting Note ",this.tasting_note," succesfully updated with response ", response);
          })
          .catch(error => {
            if(this.debug) console.log("=====> TastingNote.onSaveAdditionalRemarks: catch await TastingNotes.update ", error);
          })
       
        if (this.debug || local_debug) console.log("TastingNote.onSaveAdditionalRemarks: STOP")
      },

      async generateText_WSET2(selected_indices) {
        let local_debug = false

        let appearance = ""
        let nose = ""
        let palate = ""
        let conclusions = ""

        let set_primary_aroma = true
        let set_secondary_aroma = true
        let set_tertiary_aroma = true

        let set_primary_flavour = true
        let set_secondary_flavour = true
        let set_tertiary_flavour = true

        let current_index = []

        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.generateText_WSET2: START")
        if (this.debug || local_debug) console.log("TastingNote.generateText_WSET2: selected_indices = ", JSON.stringify(selected_indices))

        for ( let i = 0 ; i < this.selected_indices.length ; i++) {
          current_index = this.selected_indices[i]
          if (local_debug) console.log("TastingNote.generateText_WSET2: treating ", JSON.stringify(current_index), " with text:", wset2_lookup(current_index))
          if (current_index[0] == 0) { //appearance
            if ( appearance == "") {
              appearance = "appearance: "
            } else {
              appearance += ", "
            }
            if (current_index.length == 3) {
              appearance += wset2_lookup([current_index[0],current_index[1]]) + " "+ wset2_lookup(current_index)
            } else if (current_index.length == 4) {
              appearance += wset2_lookup([current_index[0],current_index[1]]) + " "+ wset2_lookup(current_index)
            } else {
              console.log("TastingNote.generateText_WSET2: ERROR - appearanceindex out range.")
            }
          } else if (current_index[0] == 1) { //nose
            if ( nose == "") {
              nose = "nose: "
            } else {
              if (current_index.length < 5) nose += ", "
            }
            if (current_index.length == 3) {
              nose += wset2_lookup([current_index[0],current_index[1]]) + " "+ wset2_lookup(current_index)
            } else if (current_index.length == 5) {
               // Primary
               if (current_index[2] == 0) {
                if (set_primary_aroma) {
                  nose += "\r\n\t with primary aroma's of "+ wset2_lookup(current_index)
                  set_primary_aroma = false
                } else {
                  nose += ", "+ wset2_lookup(current_index)
                }
              }
              else if (current_index[2] == 1) {
                // Secondary
                if (set_secondary_aroma) {
                  nose += "\r\n\t with secondary aroma's of "+ wset2_lookup(current_index)
                  set_secondary_aroma = false
                } else {
                  nose += ", "+ wset2_lookup(current_index)
                }
              }
              else if (current_index[2] == 2) {
                // Tertiary
                if (set_tertiary_aroma) {
                  nose += "\r\n\t with tertiary aroma's of "+ wset2_lookup(current_index)
                  set_tertiary_aroma = false
                } else {
                  nose += ", "+ wset2_lookup(current_index)
                }
              }
              else {
                console.log("TastingNote.generateText_WSET2: ERROR - unknow aroma type.")
              }
            }
          } else if (current_index[0] == 2) { //palate
            if (palate == "" ){
              palate = "palate:  "
            } else {
              if (current_index.length < 5) palate += ", "
            }
            if (current_index.length == 3) {
              palate += wset2_lookup([current_index[0],current_index[1]]) + " "+ wset2_lookup(current_index)
            } else if (current_index.length == 5) {
              if (current_index[2] == 0) {
               // Primary
               if (set_primary_flavour) {
                  palate += "\r\n\t with primary flavours of "+ wset2_lookup(current_index)
                  set_primary_flavour = false
                } else {
                  palate += ", "+ wset2_lookup(current_index)
                }
              }
              else if (current_index[2] == 1) {
                // Secondary
                if (set_secondary_flavour) {
                  palate += "\r\n\t with secondary flavours of "+ wset2_lookup(current_index)
                  set_secondary_flavour = false
                } else {
                  palate += ", "+ wset2_lookup(current_index)
                }
              }
              else if (current_index[2] == 2) {
                // Tertiary
                if (set_tertiary_flavour) {
                  palate += "\r\n\t with tertiary flavours of "+ wset2_lookup(current_index)
                  set_tertiary_flavour = false
                } else {
                  palate += ", "+ wset2_lookup(current_index)
                }
              }
              else {
                console.log("TastingNote.generateText_WSET2: ERROR - unknow flavour type.")
              }
            }
          } else if (current_index[0] == 3) { //conclusions
            if (conclusions == "" ){
              conclusions = "conclusions:  "
            } else {
              if (current_index.length < 5) conclusions += ", "
            }
            conclusions += wset2_lookup([current_index[0],current_index[1]]) + " "+ wset2_lookup(current_index)

            // if (this.tasting_note.score == 0) {
            //   if (current_index[2] == 0) this.tasting_note.score = 25
            //   else if (current_index[2] == 1) this.tasting_note.score = 50
            //   else if (current_index[2] == 2) this.tasting_note.score = 70
            //   else if (current_index[2] == 3) this.tasting_note.score = 80
            //   else if (current_index[2] == 4) this.tasting_note.score = 90
            // }
          } else {
            console.log("TastingNote.generateText_WSET2: ERROR - index 0 out range.")
          }
        }

        appearance = appearance.substring(appearance.indexOf(" ") + 1)
        nose = nose.substring(nose.indexOf(" ") + 1)
        palate = palate.substring(palate.indexOf(" ") + 1)
        conclusions = conclusions.substring(conclusions.indexOf(" ") + 1)

        let return_value = {"appearance":appearance, "nose":nose, "palate":palate, "conclusions":conclusions}
        if (this.debug || local_debug) console.log("TastingNote.generateText_WSET2: return_value = ", return_value)
        if (this.debug || local_debug) console.log("TastingNote.generateText_WSET2: STOP")
        return  return_value
      },

      async generateText_WSET3(selected_indices) {
        let local_debug = false

        let appearance = ""
        let nose = ""
        let palate = ""
        let conclusions = ""

        let set_primary_aroma = true
        let set_secondary_aroma = true
        let set_tertiary_aroma = true

        let set_primary_flavour = true
        let set_secondary_flavour = true
        let set_tertiary_flavour = true

        let current_index = []

        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.generateText_WSET3: START")
        if (this.debug || local_debug) console.log("TastingNote.generateText_WSET3: selected_indices = ", JSON.stringify(selected_indices))

        for ( let i = 0 ; i < this.selected_indices.length ; i++) {
          current_index = this.selected_indices[i]
          if (local_debug) console.log("TastingNote.generateText_WSET3: treating ", JSON.stringify(current_index), " with text:", wset3_lookup(current_index))
          if (current_index[0] == 0) { //appearance
            if ( appearance == "") {
              appearance = "appearance: "
            } else {
              appearance += ", "
            }
            if (current_index.length == 3) {
              appearance += wset3_lookup([current_index[0],current_index[1]]) + " "+ wset3_lookup(current_index)
            } else if (current_index.length == 4) {
              appearance += wset3_lookup([current_index[0],current_index[1]]) + " "+ wset3_lookup(current_index)
            } else {
              console.log("TastingNote.generateText_WSET3: ERROR - appearanceindex out range.")
            }
          } else if (current_index[0] == 1) { //nose
            if ( nose == "") {
              nose = "nose: "
            } else {
              if (current_index.length < 5) nose += ", "
            }
            if (current_index.length == 3) {
              nose += wset3_lookup([current_index[0],current_index[1]]) + " "+ wset3_lookup(current_index)
            } else if (current_index.length == 5) {
               // Primary
               if (current_index[2] == 0) {
                if (set_primary_aroma) {
                  nose += "\r\n\t with primary aroma's of "
                  let flavour_profile = this.flavourProfile(current_index)
                  if (flavour_profile != "") nose += flavour_profile + " "
                  nose += wset3_lookup(current_index)
                  set_primary_aroma = false
                } else {
                  let flavour_profile = this.flavourProfile(current_index)
                  if (flavour_profile != "") nose += ", " + flavour_profile + " "
                  nose += wset3_lookup(current_index)
                }
              }
              else if (current_index[2] == 1) {
                // Secondary
                if (set_secondary_aroma) {
                  nose += "\r\n\t with secondary aroma's of "+ wset3_lookup(current_index)
                  set_secondary_aroma = false
                } else {
                  nose += ", "+ wset3_lookup(current_index)
                }
              }
              else if (current_index[2] == 2) {
                // Tertiary
                if (set_tertiary_aroma) {
                  nose += "\r\n\t with tertiary aroma's of "+ wset3_lookup(current_index)
                  set_tertiary_aroma = false
                } else {
                  nose += ", "+ wset3_lookup(current_index)
                }
              }
              else {
                console.log("TastingNote.generateText_WSET3: ERROR - unknow aroma type.")
              }
            }
          } else if (current_index[0] == 2) { //palate
            if (palate == "" ){
              palate = "palate:  "
            } else {
              if (current_index.length < 5) palate += ", "
            }
            if (current_index.length == 3) {
              palate += wset3_lookup([current_index[0],current_index[1]]) + " "+ wset3_lookup(current_index)
            } else if (current_index.length == 5) {
              if (current_index[2] == 0) {
               // Primary
               if (set_primary_flavour) {
                  palate += "\r\n\t with primary flavours of "
                  let flavour_profile = this.flavourProfile(current_index)
                  if (flavour_profile != "") palate += flavour_profile + " "
                  palate += wset3_lookup(current_index)
                  set_primary_flavour = false
                } else {
                  let flavour_profile = this.flavourProfile(current_index)
                  if (flavour_profile != "") palate += ", " + flavour_profile + " "
                  palate += wset3_lookup(current_index)
                }
              }
              else if (current_index[2] == 1) {
                // Secondary
                if (set_secondary_flavour) {
                  palate += "\r\n\t with secondary flavours of "+ wset3_lookup(current_index)
                  set_secondary_flavour = false
                } else {
                  palate += ", "+ wset3_lookup(current_index)
                }
              }
              else if (current_index[2] == 2) {
                // Tertiary
                if (set_tertiary_flavour) {
                  palate += "\r\n\t with tertiary flavours of "+ wset3_lookup(current_index)
                  set_tertiary_flavour = false
                } else {
                  palate += ", "+ wset3_lookup(current_index)
                }
              }
              else {
                console.log("TastingNote.generateText_WSET3: ERROR - unknow flavour type.")
              }
            }
          } else if (current_index[0] == 3) { //conclusions
            if (conclusions == "" ){
              conclusions = "conclusions:  "
            } else {
              if (current_index.length < 5) conclusions += ", "
            }
            conclusions += wset3_lookup([current_index[0],current_index[1]]) + " "+ wset3_lookup(current_index)

            // if (this.tasting_note.score == 0) {
            //   if (current_index[2] == 0) this.tasting_note.score = 25
            //   else if (current_index[2] == 1) this.tasting_note.score = 50
            //   else if (current_index[2] == 2) this.tasting_note.score = 70
            //   else if (current_index[2] == 3) this.tasting_note.score = 80
            //   else if (current_index[2] == 4) this.tasting_note.score = 90
            // }
          } else {
            console.log("TastingNote.generateText_WSET3: ERROR - index 0 out range.")
          }
        }

        appearance = appearance.substring(appearance.indexOf(" ") + 1)
        nose = nose.substring(nose.indexOf(" ") + 1)
        palate = palate.substring(palate.indexOf(" ") + 1)
        conclusions = conclusions.substring(conclusions.indexOf(" ") + 1)

        let return_value = {"appearance":appearance, "nose":nose, "palate":palate, "conclusions":conclusions}
        if (this.debug || local_debug) console.log("TastingNote.generateText_WSET3: return_value = ", return_value)
        if (this.debug || local_debug) console.log("TastingNote.generateText_WSET3: STOP")
        return  return_value
      },

      flavourProfile(index_array) {
        let return_value = ""
        for (let i=0 ; i < this.flavour_profiling.length ; i++) {
          if (isEqualAoA(this.flavour_profiling[i].index_array, index_array) ) {
            if (typeof this.flavour_profiling[i].di == "string") {
              if (return_value == "") {
                return_value += this.flavour_profiling[i].di
              } else {
                return_value += ", " +this.flavour_profiling[i].di
              }
            }
            if (typeof this.flavour_profiling[i].sc == "string") {
              if (return_value == "") {
                return_value += this.flavour_profiling[i].sc
              } else {
                return_value += ", " +this.flavour_profiling[i].sc
              }
            }
            if (typeof this.flavour_profiling[i].gw == "string") {
              if (return_value == "") {
                return_value += this.flavour_profiling[i].gw
              } else {
                return_value += ", " +this.flavour_profiling[i].gw
              }
            }
            if (typeof this.flavour_profiling[i].fc == "string") {
              if (return_value == "") {
                return_value += this.flavour_profiling[i].fc
              } else {
                return_value += ", " +this.flavour_profiling[i].fc
              }
            }
            if (typeof this.flavour_profiling[i].ur == "string") {
              if (return_value == "") {
                return_value += this.flavour_profiling[i].ur
              } else {
                return_value += ", " +this.flavour_profiling[i].ur
              }
            }
            return return_value                                                
          }
        }
        return return_value
      },

      onSelect_WSET2(index_array) {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: START")
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: index_array = ", index_array, " index_array.length = ", index_array.length)

        if (isEqualAoA(index_array,[1,2,0])) {
          this.wine_lexicon = 'aroma'
          this.showModal_WSET2_WineLexicon()
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: showing Aroma WineLexicon")
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: STOP")    
          return
        } else if(isEqualAoA(index_array,[2,6,0])) {
          this.wine_lexicon = 'flavour'
          this.showModal_WSET2_WineLexicon()
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: showing Palate WineLexicon")
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: STOP")    
          return
        } else {
          if (index_array[0] == 1 && index_array[1] == 2) {
          }
          else if (index_array[0] == 2 && index_array[1] == 6) {
          } else {
            this.wine_lexicon = ''
          }
        }

        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: BEFORE this.selected_indices = ", JSON.stringify(this.selected_indices))
        
        if (isInAoA(index_array,this.selected_indices) != -1) {
          if (local_debug) console.log("==> TastingNote.onSelect_WSET2: index_array is in current_array -> remove it")
          let tmp = this.selected_indices
          if (local_debug) console.log("==> TastingNote.onSelect_WSET2: tmp = ", tmp)
          this.selected_indices = removeFromAoA(index_array,this.selected_indices)

          if (local_debug) console.log("==> TastingNote.onSelect_WSET2: this.selected_indices = ", this.selected_indices)
        } else {
          if (local_debug) console.log("==> TastingNote.onSelect_WSET2: index_array is NOT current_array -> process it")
          if (this.wine_lexicon == '') this.cleanChilderen_WSET2(index_array)
          this.selected_indices.push(index_array)
        }

        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: AFTER this.selected_indices = ", JSON.stringify(this.selected_indices))
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: STOP")        
      },

      onSelect_WSET3(index_array) {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: START")
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: index_array = ", index_array, " index_array.length = ", index_array.length)

        if (isEqualAoA(index_array,[1,2,0])) {
          this.wine_lexicon = 'aroma'
          this.showModal_WSET3_WineLexicon()
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: showing Aroma WineLexicon")
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: STOP")    
          return
        } else if(isEqualAoA(index_array,[2,7,0])) {
          this.wine_lexicon = 'flavour'
          this.showModal_WSET3_WineLexicon()
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: showing Palate WineLexicon")
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: STOP")    
          return
        } else {
          if (index_array[0] == 1 && index_array[1] == 2) {
          }
          else if (index_array[0] == 2 && index_array[1] == 7) {
          } else {
            this.wine_lexicon = ''
          }
        }

        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: BEFORE this.selected_indices = ", JSON.stringify(this.selected_indices))
        
        if (isInAoA(index_array,this.selected_indices) != -1) {
          if (local_debug) console.log("==> TastingNote.onSelect_WSET3: index_array is in current_array -> remove it")
          let tmp = this.selected_indices
          if (local_debug) console.log("==> TastingNote.onSelect_WSET3: tmp = ", tmp)
          this.selected_indices = removeFromAoA(index_array,this.selected_indices)

          if (local_debug) console.log("==> TastingNote.onSelect_WSET3: this.selected_indices = ", this.selected_indices)
        } else {
          if (local_debug) console.log("==> TastingNote.onSelect_WSET3: index_array is NOT current_array -> process it")
          if (this.wine_lexicon == '') this.cleanChilderen_WSET3(index_array)
          this.selected_indices.push(index_array)
        }

        this.flavour_di = "standard"
        this.flavour_sc = "standard"
        this.flavour_gw = "standard"
        this.flavour_fc = "standard"
        this.flavour_ur = "ripe"

        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: AFTER this.selected_indices = ", JSON.stringify(this.selected_indices))
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: STOP")        
      },

      cleanChilderen_WSET2(index_array) {
        let local_debug = false
        let position = 0
        if (local_debug) console.log("===> cleanChilderen_WSET2: START")
        if (local_debug) console.log("===> cleanChilderen_WSET2: index_array = ", index_array, " index_array.length = ", index_array.length)
        // backtrack till oneOption = true
        if ( index_array.length == 1) {
          if (local_debug) console.log("===> cleanChilderen_WSET2: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
        } else if ( index_array.length == 2) {
          if (local_debug) console.log("===> cleanChilderen_WSET2: this.wset2.SAT[index_array[0]].values=", this.wset2.SAT[index_array[0]].values)
          if (typeof this.wset2.SAT[index_array[0]].oneOption != 'undefined') {
            position = 0
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].oneOption", this.wset2.SAT[index_array[0]].oneOption)
          } else {
            if (local_debug) console.log("===> cleanChilderen_WSET2: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
          }  
        } else if ( index_array.length == 3) {
          if (local_debug) console.log("===> cleanChilderen_WSET2: this.wset2.SAT[index_array[0]].values[index_array[1]].values=", this.wset2.SAT[index_array[0]].values[index_array[1]].values)
          if (typeof this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption != 'undefined') {
            position = 1
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption", this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption)
          } else if (typeof this.wset2.SAT[index_array[0]].oneOption != 'undefined') {
            position = 2
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].oneOption", this.wset2.SAT[index_array[0]].oneOption)
          } else {
            if (local_debug) console.log("===> cleanChilderen_WSET2: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
          }    
        } else if ( index_array.length == 4) {
          if (local_debug) console.log("===> cleanChilderen_WSET2: this.wset2.SAT[index_array[0]].values[index_array[1]].values[2].values=", this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values)
          if (typeof this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].oneOption != 'undefined') {
            position = 3
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption", this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption)
          } else if (typeof this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption != 'undefined') {
            position = 2
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption", this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption)
          } else if (typeof this.wset2.SAT[index_array[0]].oneOption != 'undefined') {
            position = 1
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].oneOption", this.wset2.SAT[index_array[0]].oneOption)
          } else {
            if (local_debug) console.log("===> cleanChilderen_WSET2: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
          }        
        } else if ( index_array.length == 5) {
          if (local_debug) console.log("===> cleanChilderen_WSET2: this.wset2.SAT[index_array[0]].values[index_array[1]].values[2].values[index_array[3]].values=", this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].values)
          if (typeof this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].oneOption != 'undefined') {
            position = 4
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption", this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].oneOption)
          } else if (typeof this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].oneOption != 'undefined') {
            position = 3
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption", this.wset2.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption)
          } else if (typeof this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption != 'undefined') {
            position = 2
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption", this.wset2.SAT[index_array[0]].values[index_array[1]].oneOption)
          } else if (typeof this.wset2.SAT[index_array[0]].oneOption != 'undefined') {
            position = 1
            if (local_debug) console.log("===> cleanChilderen_WSET2: position = ", position ," this.wset2.SAT[index_array[0]].oneOption", this.wset2.SAT[index_array[0]].oneOption)
          } else {
            if (local_debug) console.log("===> cleanChilderen_WSET2: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
          }
        } else {
          if (local_debug) console.log("===> cleanChilderen_WSET2: index_array.length out of range")
        }
        // limit index_array
        let end = index_array.length - position
        let limit_index_array = sliceAoA(index_array,0,end)
        if (local_debug) console.log("===> cleanChilderen_WSET2: CONCLUSION position = ", position ," limit_index_array = ", limit_index_array, " index_array = ", index_array)
        // clean the childeren from this.selected_indices
        let test = []
        for ( let i = 0 ; i < this.selected_indices.length ; i++ ) {
          test = sliceAoA(this.selected_indices[i],0,end)
          if (isEqualAoA(test,limit_index_array)) {
            removeFromAoA(this.selected_indices[i],this.selected_indices)
          }
        } 
        if (local_debug) console.log("===> cleanChilderen_WSET2: STOP")
      },

      cleanChilderen_WSET3(index_array) {
        let local_debug = false
        let position = 0
        if (local_debug) console.log("===> cleanChilderen_WSET3: START")
        if (local_debug) console.log("===> cleanChilderen_WSET3: index_array = ", index_array, " index_array.length = ", index_array.length)
        // backtrack till oneOption = true
        if ( index_array.length == 1) {
          if (local_debug) console.log("===> cleanChilderen_WSET3: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
        } else if ( index_array.length == 2) {
          if (local_debug) console.log("===> cleanChilderen_WSET3: this.wset3.SAT[index_array[0]].values=", this.wset3.SAT[index_array[0]].values)
          if (typeof this.wset3.SAT[index_array[0]].oneOption != 'undefined') {
            position = 0
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].oneOption", this.wset3.SAT[index_array[0]].oneOption)
          } else {
            if (local_debug) console.log("===> cleanChilderen_WSET3: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
          }  
        } else if ( index_array.length == 3) {
          if (local_debug) console.log("===> cleanChilderen_WSET3: this.wset3.SAT[index_array[0]].values[index_array[1]].values=", this.wset3.SAT[index_array[0]].values[index_array[1]].values)
          if (typeof this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption != 'undefined') {
            position = 1
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption", this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption)
          } else if (typeof this.wset3.SAT[index_array[0]].oneOption != 'undefined') {
            position = 2
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].oneOption", this.wset3.SAT[index_array[0]].oneOption)
          } else {
            if (local_debug) console.log("===> cleanChilderen_WSET3: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
          }    
        } else if ( index_array.length == 4) {
          if (local_debug) console.log("===> cleanChilderen_WSET3: this.wset3.SAT[index_array[0]].values[index_array[1]].values[2].values=", this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values)
          if (typeof this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].oneOption != 'undefined') {
            position = 3
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption", this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption)
          } else if (typeof this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption != 'undefined') {
            position = 2
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption", this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption)
          } else if (typeof this.wset3.SAT[index_array[0]].oneOption != 'undefined') {
            position = 1
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].oneOption", this.wset3.SAT[index_array[0]].oneOption)
          } else {
            if (local_debug) console.log("===> cleanChilderen_WSET3: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
          }        
        } else if ( index_array.length == 5) {
          if (local_debug) console.log("===> cleanChilderen_WSET3: this.wset3.SAT[index_array[0]].values[index_array[1]].values[2].values[index_array[3]].values=", this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].values)
          if (typeof this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].oneOption != 'undefined') {
            position = 4
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption", this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].oneOption)
          } else if (typeof this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].oneOption != 'undefined') {
            position = 3
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption", this.wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].oneOption)
          } else if (typeof this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption != 'undefined') {
            position = 2
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption", this.wset3.SAT[index_array[0]].values[index_array[1]].oneOption)
          } else if (typeof this.wset3.SAT[index_array[0]].oneOption != 'undefined') {
            position = 1
            if (local_debug) console.log("===> cleanChilderen_WSET3: position = ", position ," this.wset3.SAT[index_array[0]].oneOption", this.wset3.SAT[index_array[0]].oneOption)
          } else {
            if (local_debug) console.log("===> cleanChilderen_WSET3: WARNING in index_array.length = ", index_array.length, " oneOption NOT found.")
          }
        } else {
          if (local_debug) console.log("===> cleanChilderen_WSET3: index_array.length out of range")
        }
        // limit index_array
        let end = index_array.length - position
        let limit_index_array = sliceAoA(index_array,0,end)
        if (local_debug) console.log("===> cleanChilderen_WSET3: CONCLUSION position = ", position ," limit_index_array = ", limit_index_array, " index_array = ", index_array)
        // clean the childeren from this.selected_indices
        let test = []
        for ( let i = 0 ; i < this.selected_indices.length ; i++ ) {
          test = sliceAoA(this.selected_indices[i],0,end)
          if (isEqualAoA(test,limit_index_array)) {
            removeFromAoA(this.selected_indices[i],this.selected_indices)
          }
        } 
        if (local_debug) console.log("===> cleanChilderen_WSET3: STOP")
      },

      isSelected(index_array) {
        if (isInAoA(index_array,this.selected_indices) != -1) {
          return true
        } else {
          return false
        }
      },

      mouseOver(value) {
        let local_debug = false
        let filename = ''
        if (local_debug) console.log("mouseOver: value = ", value);
        try {
          if (value == 'black/white pepper') {
            filename = 'blackwhite pepper'
          } else if (value == 'baked/stewed fruits') {
            filename = 'bakedstewed fruits'
          } else {
            filename = value
          }
          const imageUrl = imagesContext('./' + filename + '.webp');
          const availableImages = imagesContext.keys().map(key => key.replace('./', ''));
          
          // Check if the desired image exists in the available images array
          if (availableImages.includes(filename + '.webp')) {
            this.imageSrc = imageUrl;
          } else {
            // Fallback to a default image if the desired image doesn't exist
            if (local_debug) console.log(`mouseOver: Image '${filename}.webp' not found.`);
            this.imageSrc = imagesContext('./blank.webp');
          }
        } catch (error) {
          // Fallback to a default image if there's an error
          if (local_debug)  console.log("mouseOver: Error loading image:", error);
          this.imageSrc = imagesContext('./blank.webp');
        }
      },

      async mouseLeave(event) {
        this.imageSrc=0
      },

      async mouseMove(event) {
        this.mouseX = event.clientX; 
        this.mouseY = event.clientY;
      },

      async changeChoice() {
        let local_debug = false
        if (local_debug == true) console.log("selected = "+this.selected+ " tasting_note.wset.level = "+ this.tasting_note.wset.level)
        
        if (this.tasting_note.wset.level == "wset2" && this.selected == "wset3") {
          this.change_message = "You are about to change this tasting note from WSET Level 2 to WSET Level 3. Please note, this will permanently erase your existing WSET Level 2 notes and initiate a new set for WSET Level 3. Do you wish to proceed?"
          this.$root.$emit("bv::show::modal", "Warning");
        }

        if (this.tasting_note.wset.level == "wset3" && this.selected == "wset2") {
          this.change_message = "You are about to change this tasting note from WSET Level 3 to WSET Level 2. Please note, this will permanently erase your existing WSET Level 3 notes and initiate a new set for WSET Level 2. Do you wish to proceed?"
          this.$root.$emit("bv::show::modal", "Warning");
        } 
      },

      async onSaveNewChoice() {
        if (this.selected == "wset2" || this.selected == "wset3") {
          this.tasting_note.wset.level = this.selected
          this.tasting_note.wset.selected_indices = []
          this.tasting_note.wset.text = ""
          this.tasting_note.wset_text = ""
          this.selected_indices = []
          this.wine_lexicon = ''
          this.update_wset = 0

          await TastingNotes.update(this.tasting_note_id,this.tasting_note)
          .then(response => {
            if(this.debug) console.log("=====> TastingNote.onSaveNewChoice: Tasting Note ",this.tasting_note," succesfully updated with response ", response);
          })
          .catch(error => {
            if(this.debug) console.log("=====> TastingNote.onSaveNewChoice: catch await TastingNotes.update ", error);
          })

        } else {
          console.log("Tasting_Note.onSaveNewChoice: ERROR - invalid this.selected = ", this.selected)
        }
        this.$root.$emit("bv::hide::modal", "Warning");
      },

      async onCancelNewChoice() {
        this.$root.$emit("bv::hide::modal", "Warning");
        this.selected = this.tasting_note.wset.level
        this.update_toggle++
      },

      handleClick(index_array,event,flavour_name) {
        let local_debug = false
        const currentTime = new Date().getTime();
        const timeBetweenClicks = currentTime - this.lastClickTime;

        if (timeBetweenClicks > 300) {
          this.clicks = 0
        } 
        this.clicks++

        this.X = event.clientX
        this.Y = event.clientY

        if (local_debug) console.log("===========================================")
        if (local_debug) console.log("clicks = ", this.clicks)
        if (local_debug) console.log("timeBetweenClicks = ", timeBetweenClicks)

        if (this.clicks == 1) {
            setTimeout(() => {
              if (this.clicks == 1) {
                if (local_debug) console.log("Single click detected.");
                this.onSelect_WSET3(index_array)
              } 
            }, 300)
        } else {
          if (local_debug) console.log("2. Double click detected");
          if (this.isSelected(index_array)) {
            if (local_debug) console.log("isSelected = true")
            this.flavourName = flavour_name
            this.flavourIndexArray = index_array
            this.flavourImage = imagesContext('./' + this.value2filename(flavour_name) + '.webp');

            this.DblClick(index_array)
          } else {
            if (local_debug) console.log("isSelected = false")
          }

          this.clicks = 0
        }

        this.lastClickTime = new Date().getTime();

        if (local_debug) console.log("===========================================")
      },

      DblClick(index_array) {
        let local_debug = false
        if (local_debug) console.log("TastingNote.DblClick: START")
        if (local_debug) console.log("TastingNote.DblClick: index_array = ", index_array)
        this.doubleClickDetected = true
        this.showFlavourProfile = true
        
        this.showPicture_save = this.showPicture
        this.showPicture = false

        this.flavour_di = "standard"
        this.flavour_sc = "standard"
        this.flavour_gw = "standard"
        this.flavour_fc = "standard"
        this.flavour_ur = "ripe"

        for ( let i=0 ; i < this.flavour_profiling.length ; i++ ){
          if (isEqualAoA(this.flavour_profiling[i].index_array,this.flavourIndexArray)) {
            if (typeof this.flavour_profiling[i].di != "undefined") {
              this.flavour_di = this.flavour_profiling[i].di
            } 
            if (typeof this.flavour_profiling[i].sc != "undefined") {
              this.flavour_sc = this.flavour_profiling[i].sc
            } 
            if (typeof this.flavour_profiling[i].gw != "undefined") {
              this.flavour_gw = this.flavour_profiling[i].gw
            } 
            if (typeof this.flavour_profiling[i].fc != "undefined") {
              this.flavour_fc = this.flavour_profiling[i].fc
            } 
            if (typeof this.flavour_profiling[i].ur != "undefined") {
              this.flavour_ur = this.flavour_profiling[i].ur
            }          
          } 
        }

        if (local_debug) console.log("TastingNote.DblClick: this.flavour_profiling = ", JSON.stringify(this.flavour_profiling))
        if (local_debug) console.log("TastingNote.DblClick: ==> flavour_di = ", JSON.stringify(this.flavour_di))
        if (local_debug) console.log("TastingNote.DblClick: ==> flavour_sc = ", JSON.stringify(this.flavour_sc))
        if (local_debug) console.log("TastingNote.DblClick: ==> flavour_gw = ", JSON.stringify(this.flavour_gw))
        if (local_debug) console.log("TastingNote.DblClick: ==> flavour_fc = ", JSON.stringify(this.flavour_fc))
        if (local_debug) console.log("TastingNote.DblClick: ==> flavour_ur = ", JSON.stringify(this.flavour_ur))
        
        if (local_debug) console.log("TastingNote.DblClick: this.selected_indices = ", JSON.stringify(this.selected_indices))

        if (local_debug) console.log("TastingNote.DblClick: STOP")
      },

      cancelOptions() {
        this.showFlavourProfile = false
        this.showPicture = this.showPicture_save
      },

      async saveOptions() {
        let local_debug = false
        if (local_debug) console.log("TastingNote.saveOptions: START")
        this.showFlavourProfile = false
        this.showPicture = this.showPicture_save
        if (local_debug) console.log("TastingNote.saveOptions: this.flavour_profiling = ", JSON.stringify(this.flavour_profiling))
        if (local_debug) console.log("TastingNote.saveOptions: this.selected_indices = ", JSON.stringify(this.selected_indices))
        this.tasting_note.wset.flavour_profiling = this.flavour_profiling
        this.tasting_note.wset.selected_indices = this.selected_indices
        await TastingNotes.update(this.tasting_note_id,this.tasting_note)
          .then(response => {
            if(local_debug) console.log("TastingNote.saveOptions: Tasting Note ",this.tasting_note," succesfully updated with response ", response);
          })
          .catch(error => {
            if(local_debug) console.log("TastingNote.saveOptions: catch await TastingNotes.update ", error);
          })
          await TastingNotes.update(this.tasting_note_id,this.tasting_note)
          .then(response => {
            if(local_debug) console.log("TastingNote.saveOptions: Tasting Note ",this.tasting_note," succesfully updated with response ", response);
          })
          .catch(error => {
            if(local_debug) console.log("TastingNote.saveOptions: catch await TastingNotes.update ", error);
          })
        if (local_debug) console.log("TastingNote.saveOptions: START")
      },

      value2filename(value) {
        if (value == 'black/white pepper') {
          return 'blackwhite pepper'
        } else if (value == 'baked/stewed fruits') {
          return 'bakedstewed fruits'
        } else {
          return value
        }
      },

      changeDI() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.changeDI: START")

        if (this.debug || local_debug) console.log("TastingNote.changeDI: BEFORE this.flavour_profiling = ", this.flavour_profiling)
        // 1. Is the index_array already in the flavour_profiling list, if not add it!
        let isIn = false
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
            isIn = true
          }
        }
        if (isIn == false) {
          this.flavour_profiling.push({index_array: this.flavourIndexArray })
        }

        // 2. Remove the current flavour_di for the correspontant flavour_profiling[i]
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
            delete this.flavour_profiling[i].di;
            break
          } 
        }

        // 3. add the new flavour_di to the flavour_profiling[i]
        if (this.flavour_di != "standard") {
          for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
            if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
              this.flavour_profiling[i].di = this.flavour_di  
              break
            } 
          }
        }

        // 4. if flavour_profiling[i] does not contain profiles, delete it
        this.cleanProfile()

        if (this.debug || local_debug) console.log("TastingNote.changeDI: AFTER this.flavour_profiling = ", this.flavour_profiling)
        if (this.debug || local_debug) console.log("TastingNote.changeDI: STOP")
      },

      changeSC() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.changeDI: START")

        if (this.debug || local_debug) console.log("TastingNote.changeDI: BEFORE this.flavour_profiling = ", this.flavour_profiling)
        // 1. Is the index_array already in the flavour_profiling list, if not add it!
        let isIn = false
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
            isIn = true
          }
        }
        if (isIn == false) {
          this.flavour_profiling.push({index_array: this.flavourIndexArray })
        }

        // 2. Remove the current flavour_sc for the correspontant flavour_profiling[i]
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray,this.flavour_profiling[i].index_array)) {
            delete this.flavour_profiling[i].sc;
            break
          } 
        }

        // 3. add the new flavour_sc to the flavour_profiling[i]
        if (this.flavour_sc != "standard") {
          for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
            if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
              this.flavour_profiling[i].sc = this.flavour_sc  
              break
            } 
          }
        }

        // 4. if flavour_profiling[i] does not contain profiles, delete it
        this.cleanProfile()

        if (this.debug || local_debug) console.log("TastingNote.changeDI: AFTER this.flavour_profiling = ", this.flavour_profiling)
        if (this.debug || local_debug) console.log("TastingNote.changeDI: STOP")
      },

      changeGW() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.changeDI: START")

        if (this.debug || local_debug) console.log("TastingNote.changeDI: BEFORE this.flavour_profiling = ", this.flavour_profiling)
        // 1. Is the index_array already in the flavour_profiling list, if not add it!
        let isIn = false
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
            isIn = true
          }
        }
        if (isIn == false) {
          this.flavour_profiling.push({index_array: this.flavourIndexArray })
        }

        // 2. Remove the current flavour_gw for the correspontant flavour_profiling[i]
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray,this.flavour_profiling[i].index_array)) {
            delete this.flavour_profiling[i].gw;
            break
          } 
        }

        // 3. add the new flavour_gw to the flavour_profiling[i]
        if (this.flavour_gw != "standard") {
          for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
            if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
              this.flavour_profiling[i].gw = this.flavour_gw  
              break
            } 
          }
        }

        // 4. if flavour_profiling[i] does not contain profiles, delete it
        this.cleanProfile()

        if (this.debug || local_debug) console.log("TastingNote.changeDI: AFTER this.flavour_profiling = ", this.flavour_profiling)
        if (this.debug || local_debug) console.log("TastingNote.changeDI: STOP")
      },

      changeFC() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.changeDI: START")

        if (this.debug || local_debug) console.log("TastingNote.changeDI: BEFORE this.flavour_profiling = ", this.flavour_profiling)
        // 1. Is the index_array already in the flavour_profiling list, if not add it!
        let isIn = false
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
            isIn = true
          }
        }
        if (isIn == false) {
          this.flavour_profiling.push({index_array: this.flavourIndexArray })
        }

        // 2. Remove the current flavour_fc for the correspontant flavour_profiling[i]
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray,this.flavour_profiling[i].index_array)) {
            delete this.flavour_profiling[i].fc;
            break
          } 
        }

        // 3. add the new flavour_fc to the flavour_profiling[i]
        if (this.flavour_fc != "standard") {
          for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
            if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
              this.flavour_profiling[i].fc = this.flavour_fc  
              break
            } 
          }
        }

        // 4. if flavour_profiling[i] does not contain profiles, delete it
        this.cleanProfile()

        if (this.debug || local_debug) console.log("TastingNote.changeDI: AFTER this.flavour_profiling = ", this.flavour_profiling)
        if (this.debug || local_debug) console.log("TastingNote.changeDI: STOP")
      },

      changeUR() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.changeDI: START")

        if (this.debug || local_debug) console.log("TastingNote.changeDI: BEFORE this.flavour_profiling = ", this.flavour_profiling)
        // 1. Is the index_array already in the flavour_profiling list, if not add it!
        let isIn = false
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
            isIn = true
          }
        }
        if (isIn == false) {
          this.flavour_profiling.push({index_array: this.flavourIndexArray })
        }

        // 2. Remove the current flavour_ur for the correspontant flavour_profiling[i]
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (isEqualAoA(this.flavourIndexArray,this.flavour_profiling[i].index_array)) {
            delete this.flavour_profiling[i].ur;
            break
          } 
        }

        // 3. add the new flavour_ur to the flavour_profiling[i]
        if (this.flavour_ur != "ripe") {
          for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
            if (isEqualAoA(this.flavourIndexArray, this.flavour_profiling[i].index_array)) {
              this.flavour_profiling[i].ur = this.flavour_ur  
              break
            } 
          }
        }

        // 4. if flavour_profiling[i] does not contain profiles, delete it
        this.cleanProfile()

        if (this.debug || local_debug) console.log("TastingNote.changeDI: AFTER this.flavour_profiling = ", this.flavour_profiling)
        if (this.debug || local_debug) console.log("TastingNote.changeDI: STOP")
      },

      cleanProfile() {
        let i_delete = -1
        for (let i = 0 ; i < this.flavour_profiling.length ; i++ ) {
          if (  typeof this.flavour_profiling[i].di == "undefined" &&
                typeof this.flavour_profiling[i].sc == "undefined" &&
                typeof this.flavour_profiling[i].gw == "undefined" &&
                typeof this.flavour_profiling[i].fc == "undefined" &&
                typeof this.flavour_profiling[i].ur == "undefined" ) {
            i_delete = i      
          }
        }
        if (i_delete > -1) this.flavour_profiling.splice(i_delete,1)
      },

      doNothing() {
      },
    },
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");


</style>

<style scoped>
.item-title {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(16px,2vw,20px) !important;
  font-weight:bold;
  margin-bottom:0;
}

.item-subtitle {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(14px,1.8vw,18px) !important;
  margin-bottom:0;
}
.w-value {
  color:rgb(109,122,82);
  font-size: 12px;
  text-align:left;
  margin-top: 0.2rem;
  margin-bottom:0.5rem
}

.w-debug {
  font-size: 11px;
  color:red;
  margin:0;
}

.floating-div {
  position: absolute;
  border-color: rgb(112,112,112) ;
  background:white;
  border-radius: 0px;

  -webkit-box-shadow: 0px 0px 10px 0px  rgba(0,0,0,0.37);
  -moz-box-shadow: 0px 0px 10px 0px  rgba(0,0,0,0.37);
  box-shadow:  0px 0px 10px 0px rgba(0,0,0,0.37);
  padding: 10px;
  border-radius: 5px;
  z-index: 9999; /* Ensure the div is on top of other elements */
}

</style>

