import { render, staticRenderFns } from "./TastingNote_Desktop.vue?vue&type=template&id=83be1d5c&scoped=true&"
import script from "./TastingNote_Desktop.vue?vue&type=script&lang=js&"
export * from "./TastingNote_Desktop.vue?vue&type=script&lang=js&"
import style0 from "./TastingNote_Desktop.vue?vue&type=style&index=0&id=83be1d5c&prod&lang=css&"
import style1 from "./TastingNote_Desktop.vue?vue&type=style&index=1&id=83be1d5c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83be1d5c",
  null
  
)

export default component.exports