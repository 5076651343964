<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container> 
    <div v-else>
      <b-container v-if="this.inSession" style="padding:0">
        <!-- <p>{{item_id}} {{bottle_id}} {{bottle.name}}</p> -->
        <div v-if="item_id !=0">
          <b-row>
            <b-col v-if="(tasting.isBlind == true) && (!tasting_note.isDisclosed || tasting_note.isDisclosed == false)" cols="3">
              <b-img center fluid 
                :src="require('@/assets/img/bottle.png')"
              />
            </b-col>
            <b-col v-else cols="3">
              <b-img center fluid
                v-if="(wine_id != 0 && wine.image_files.length > 0)"
                v-bind:src="wine.image_files[0].dataURL"  
              >
              </b-img>
              <b-img center fluid
                v-else-if="c_wine_id != 0 && c_wine.image_files.length > 0"
                v-bind:src="c_wine.image_files[0].dataURL"  
              >
              </b-img>
              <div v-else>
                <b-img center fluid v-if="c_wine.color == 'Red'"
                  max-width="100"
                  height="300"
                  :src="require('@/assets/img/bottle_red.png')"
                ></b-img>
                <b-img center fluid v-else-if="c_wine.color == 'White'"
                  max-width="100"
                  height="300"
                  :src="require('@/assets/img/bottle_white.png')"
                ></b-img>
                <b-img center fluid v-else-if="c_wine.color == 'Rosé'"
                  max-width="100"
                  height="300"
                  :src="require('@/assets/img/bottle_rose.png')"
                ></b-img>
                <b-img center fluid v-else
                  max-width="100"
                  height="300"
                  :src="require('@/assets/img/bottle.png')"
                ></b-img>
              </div>
            </b-col>
            <b-col cols="9">
                  <!-- <p class="w-smalltext" style="margin-bottom:2px">TMP this.tasting_note_id = {{ this.tasting_note_id }}</p>
                  <p class="w-smalltext" style="margin-bottom:2px">TMP this.c_wine_id = {{ this.c_wine_id }}</p>
                  <p class="w-smalltext" style="margin-bottom:2px">TMP this.item_id = {{ this.item_id }}</p>
                  <hr> -->
              <b-row v-if="tasting.isBlind && (!tasting_note.isDisclosed || tasting_note.isDisclosed == false)" style="display:flex">
                <b-col cols="9" style="padding-right:0">
                  <p class="item-title" style="text-align:left">{{this.tasting_note.name}}</p>
                </b-col>
              </b-row>
              <div v-else>
                <b-row style="display:flex">
                  <b-col cols="9" style="padding-right:0">
                    <p v-if="wine_id != 0" class="item-title" style="text-align:left">{{this.wine.name}}</p>
                    <p v-if="c_wine_id != 0" class="item-title" style="text-align:left">{{this.c_wine.name}}</p>
                  </b-col>
                  <b-col cols="3" style="padding-right:15px;padding-left:0">
                    <p class="item-title" v-if="this.bottle.vintage != '-'" style="text-align:right;">{{this.bottle.vintage}}</p>
                  </b-col>
                </b-row>               
                <b-row>
                  <b-col v-if="wine_id != 0">
                    <b-row>
                      <b-col cols="10" style="padding-right:0">
                        <p v-if="this.wine.type != ''" class="item-subtitle" style="text-align:left">{{this.wine.type}}</p>
                        <p v-if="this.wine.appellation !=''" class="item-text" style="text-align:left">{{this.wine.appellation}} <span  v-if="this.wine.classification !=''">- {{this.wine.classification}}</span></p>
                        <p class="item-text" style="text-align:left">{{this.wine.country}} <span  v-if="this.wine.region !=''">- {{this.wine.region}}</span></p>
                      </b-col>
                      <b-col cols="2" style="padding-right:0">
                        <p class="item-subtitle" style="color:white">blanc</p>
                        <p v-if="this.wine.appellation !=''" class="item-text" style="color:white">blanc</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col style="padding-right:0">
                        <p v-if="item.price" class="item-price" style="padding-right:15px;text-align:right;margin-bottom:0"><span v-if="item.origin" style="color:rgb(112,112,112)">{{item.origin}} : </span>{{this.item.price}}</p>
                      </b-col>
                    </b-row>                
                    <b-row v-if="this.wine.grapes.length > 0 || this.wine.longevity.length > 0 || this.wine.vines.length > 0 || this.wine.yields.length > 0 || this.wine.vinification.length > 0">
                      <b-col style="padding-right:0">
                        <hr style="margin:5px 0 5px 0">
                        <p class="item-text" style="text-align:left" v-if="this.wine.grapes !=''"><b>grapes: </b>{{this.wine.grapes}}</p>
                        <p class="item-text" style="text-align:left" v-if="this.wine.longevity !=''"><b>longevity: </b>{{this.wine.longevity}}</p>              
                        <p class="item-text" style="text-align:left" v-if="this.wine.vines !=''"><b>vines: </b>{{this.wine.vines}}</p>   
                        <p class="item-text" style="text-align:left" v-if="this.wine.yields !=''"><b>yields: </b>{{this.wine.yields}}</p>
                        <p class="item-text" style="text-align:left" v-if="this.wine.vinification !=''"><b>vinification: </b>{{this.wine.vinification}}</p> 
                      </b-col>  
                    </b-row>
                    <b-row>
                      <b-col class="text-right">
                        <font-awesome-icon id="q" style="font-size:24px;font-weight:bold;color:rgb(109, 122, 82)" icon="check" />
                        <b-tooltip target="q" custom-class="w-tooltip-good">certified by the WyneMaker</b-tooltip>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col v-if="c_wine_id != 0">
                    <b-row>
                      <b-col cols="10" style="padding-right:0">
                        <p v-if="this.c_wine.type != ''" class="item-subtitle" style="text-align:left">{{this.c_wine.type}}</p>
                        <p v-if="this.c_wine.appellation !=''" class="item-text" style="text-align:left">{{this.c_wine.appellation}} <span  v-if="this.c_wine.classification !=''">- {{this.c_wine.classification}}</span></p>
                        <p class="item-text" style="text-align:left">{{this.c_wine.country}} <span  v-if="this.c_wine.region !=''">- {{this.c_wine.region}}</span></p>
                      </b-col>
                      <b-col cols="2" style="padding-right:0">
                        <p class="item-subtitle" style="color:white">blanc</p>
                        <p v-if="this.c_wine.appellation !=''" class="item-text" style="color:white">blanc</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col style="padding-right:0">
                        <p v-if="item.price" class="item-price" style="padding-right:15px;text-align:right;margin-bottom:0"><span v-if="item.origin" style="color:rgb(112,112,112)">{{item.origin}} : </span>{{this.item.price}}</p>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.c_wine.grapes.length > 0 || this.c_wine.longevity.length > 0 || this.c_wine.vines.length > 0 || this.c_wine.yields.length > 0 || this.c_wine.vinification.length > 0">
                      <b-col style="padding-right:0">
                        <hr style="margin:5px 0 5px 0">
                        <p class="item-text" style="text-align:left" v-if="typeof this.c_wine.grapes == 'string' && this.c_wine.grapes !=''"><b>grapes: </b>{{this.c_wine.grapes}}</p>
                        <p class="item-text" style="text-align:left" v-if="typeof this.c_wine.vines == 'string' && this.c_wine.longevity !=''"><b>longevity: </b>{{this.c_wine.longevity}}</p>              
                        <p class="item-text" style="text-align:left" v-if="typeof this.c_wine.longevity == 'string' && this.c_wine.vines !=''"><b>vines: </b>{{this.c_wine.vines}}</p>   
                        <p class="item-text" style="text-align:left" v-if="typeof this.c_wine.yields == 'string' && this.c_wine.yields !=''"><b>yields: </b>{{this.c_wine.yields}}</p>
                        <p class="item-text" style="text-align:left" v-if="typeof this.c_wine.vinification == 'string' && this.c_wine.vinification !=''"><b>vinification: </b>{{this.c_wine.vinification}}</p>
                      </b-col>   
                    </b-row>
                    <b-row v-if="tasting.isOrganised == false && c_wine.user_id != currentUserID">
                      <b-col class="text-right">
                        <font-awesome-icon id="q" style="font-size:24px;font-weight:bold;color:rgb(195, 147, 130)" icon="question" />
                        <b-tooltip target="q" custom-class="w-tooltip-bad">not certified</b-tooltip>
                      </b-col>
                    </b-row>
                  </b-col> 
                </b-row>
              </div>
              <b-row v-if="tasting_id != 0">
                <b-col 
                  v-if="(typeof tasting_note.appearance != 'undefined' && tasting_note.appearance.length > 0) || (typeof tasting_note.palate != 'undefined' && tasting_note.palate.length > 0) || (typeof tasting_note.conclusions != 'undefined' && tasting_note.conclusions.length > 0)"
                  style="padding-right:0"
                  > 
                  <hr style="margin:5px 0 5px 0">
                  <div v-if="tasting_note.wset.appearance">
                    <p class="item-text"><b>appearence: </b>{{this.tasting_note.wset.appearance}}</p>
                    <p v-if="this.tasting_note.additional_remarks.appearance" class="item-text">{{this.tasting_note.additional_remarks.appearance}}</p>
                  </div>
                  <div v-else-if="tasting_note.appearance">
                    <p class="item-text"><b>appearence: </b>{{this.tasting_note.appearance}}</p>
                  </div>
                  <div v-if="tasting_note.wset.nose">
                    <p class="item-text"><b>nose: </b>{{this.tasting_note.wset.nose}}</p>
                    <p v-if="this.tasting_note.additional_remarks.nose" class="item-text">{{this.tasting_note.additional_remarks.nose}}</p>
                  </div>
                  <div v-else-if="tasting_note.nose">
                    <p class="item-text"><b>nose: </b>{{this.tasting_note.nose}}</p>
                  </div>
                  <div v-if="tasting_note.wset.palate">
                    <p class="item-text"><b>palate: </b>{{this.tasting_note.wset.palate}}</p>
                    <p v-if="this.tasting_note.additional_remarks.palate" class="item-text">{{this.tasting_note.additional_remarks.palate}}</p>
                  </div>
                  <div v-else-if="tasting_note.palate">
                    <p class="item-text"><b>palate: </b>{{this.tasting_note.palate}}</p>
                  </div>
                  <div v-if="tasting_note.wset.conclusions">
                    <p class="item-text"><b>conclusions: </b>{{this.tasting_note.wset.conclusions}}</p>
                    <p v-if="this.tasting_note.additional_remarks.conclusions" class="item-text">{{this.tasting_note.additional_remarks.conclusions}}</p>
                  </div>
                  <div v-else-if="tasting_note.conclusions">
                    <p class="item-text"><b>conclusions: </b>{{this.tasting_note.conclusions}}</p>
                  </div>
                </b-col>
                <hr>
              </b-row>
              <hr style="margin:5px 0 5px 0">
              <div v-if="source == 'WineList'">
                <b-img 
                  id="feedback"
                  :src="require('@/assets/img/feedback.png')"
                  style="width:25px"
                  v-on:click="showModal_tastersFeedback()"
                >
                </b-img>
                <b-tooltip target="feedback" triggers="hover" placement="bottom">
                  tasters feedback
                </b-tooltip>
              </div>
              <b-row>
                <b-col v-if="tasting_id != 0" style="overflow: hidden; display: flex; align-items: center;">
                  <div v-if="tasting.user_id == tasting.organiser_id" style="margin:0 10px 0 0;">
                      <b-img 
                          id="feedback"
                          :src="require('@/assets/img/feedback.png')"
                          style="width:25px;"
                          v-on:click="showModal_tastersFeedback()"
                      >
                      </b-img>
                      <b-tooltip target="feedback" triggers="hover" placement="left">
                          tasters feedback
                      </b-tooltip>
                  </div>
                  <font-awesome-icon
                      id="heart"
                      v-bind:style="{'color':heart_color, 'font-size':'24px'}"
                      icon="heart"
                      @click="toggleWishList"/>
                  <b-tooltip target="heart" triggers="hover" placement="bottom">
                      {{heart_tooltip_text}}
                  </b-tooltip>
                  <div v-if="tasting.isBlind && tasting.user_id == tasting.organiser_id" style="margin:0 0 0 10px;">
                      <font-awesome-icon
                          id="eye"
                          v-bind:style="{'color':disclosed_color, 'font-size':'24px'}"
                          icon="eye"
                          @click="toggleDisclosure"/>
                      <b-tooltip target="eye" triggers="hover" placement="right">
                          {{disclosed_tooltip_text}}
                      </b-tooltip>
                  </div>
              </b-col>
                <b-col v-if="tasting_note.score > 0">
                  <p v-if="tasting_note.score" class="item-title" style="text-align:right;margin-bottom:0; color:rgb(109,122,82)">{{tasting_note.score}}/100</p>
                </b-col>
              </b-row>  
            </b-col>
          </b-row>
          <hr>
          <b-row v-if="!tasting.isOrganised">
            <b-col class="text-right" style="padding-right:0">
              <b-button v-on:click="showModal_editBottle()" class="w-button-green" style="margin-right:15px" >edit</b-button>
            </b-col>
          </b-row>    
        </div>
        <div v-else>
          <p v-if="this.bottle_id == 0" class="w-pagetitle">There is no wine yet associated with this tasting note.</p>
          <b-row>
            <b-col class="text-right" style="padding-right:0">
              <b-button v-on:click="showModal_addBottle()" class="w-button-green" style="margin-right:15px">add</b-button>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    <b-modal v-if="tabIndex==0" id="addBottle" title="add bottle" hide-footer>
      <b-tabs v-if="renderChild" class="w-tabs" v-model="tabIndex" fill>
        <div>
          <b-tab class="w-tab" title="from database" active>
            <br>
            <LibrarySearch
              @refreshRequest="refreshParent"
              ref="library_search"
            />
          </b-tab>
          <b-tab class="w-tab" title="from scratch">
            <FullDefineTasting v-if="source == 'TastingNote'"
              ref="full_define_tasting"
              @refreshRequest="refreshParent"
            />
            <FullDefineWineList v-else-if="source == 'WineList'"
              ref="full_define_winelist"
              @refreshRequest="refreshParent"
            />
            <p v-else>UNKNOWN SOURCE = {{ source }}</p>
          </b-tab>
        </div>
      </b-tabs>
    </b-modal>
    <b-modal v-else id="addBottle" title="add bottle" @ok="addBottle()" @cancel="doNothing()">
      <b-tabs v-if="renderChild" class="w-tabs" v-model="tabIndex" fill>
        <div>
          <b-tab class="w-tab" title="from database" active>
            <br>
            <LibrarySearch
              @refreshRequest="refreshParent"
              ref="library_search"  
            />
          </b-tab>
          <b-tab class="w-tab" title="from scratch">
            <FullDefineTasting v-if="source == 'TastingNote'"
              @refreshRequest="refreshParent"
              ref="full_define_tasting"
            />
            <FullDefineWineList v-else-if="source == 'WineList'"
              @refreshRequest="refreshParent"
              ref="full_define_winelist"
            />
            <p v-else>UNKNOWN SOURCE = {{ source }}</p>
          </b-tab>
        </div>
      </b-tabs>
        <template #modal-footer="{ ok, cancel }">
          <b-row style="width:100%;height:0;padding:0">
            <b-col class="text-left" style="padding-left:20px;margin:0">
              <b-button class="w-button-pink" style="position: relative;top:-60px" @click="cancel()">
                back
              </b-button>
            </b-col>  
          </b-row>
        </template>
    </b-modal>
    <b-modal id="editBottle" title="edit bottle" @ok="editBottle()" @cancel="doNothing()">
      <b-tabs v-if="renderChild" class="w-tabs" v-model="tabIndex" fill>
        <div>
          <b-tab v-if="wine_id !=0" class="w-tab" title="from database" active>
            <br>
            <LibrarySearch
              @refreshRequest="refreshParent"
              ref="library_search"
            />
          </b-tab>
          <b-tab v-else-if="c_wine_id !=0 && c_wine.user_id == currentUserID" class="w-tab" title="from scratch">
            <FullDefineTasting v-if="source == 'TastingNote'"
              @refreshRequest="refreshParent"
              ref="full_define_tasting"
            />
            <FullDefineWineList v-else-if="source == 'WineList'"
              @refreshRequest="refreshParent"
              ref="full_define_winelist"
            />
            <p v-else>UNKNOWN SOURCE = {{ source }}</p>
          </b-tab>
          <b-tab v-else-if="c_wine_id !=0 && c_wine.user_id != currentUserID" class="w-tab" title="from database">
            <br>
            <LibrarySearch
              @refreshRequest="refreshParent"
              ref="library_search"
            />
          </b-tab>
          <b-tab v-else class="w-tab" title="error" active>
            <p>UNKNOWN CONDITION</p>
            <p>bottle.wine_id = {{ bottle.wine_id }}</p>
            <p>bottle.c_wine_id = {{ bottle.c_wine_id }}</p>
            <p>c_wine.user_id = {{ c_wine.user_id  }}</p>
            <p>currentUserID = {{ currentUserID  }}</p>
          </b-tab>  
        </div>
      </b-tabs>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%;height:0;padding:0">
          <b-col class="text-left" style="padding-left:20px;margin:0">
            <b-button class="w-button-pink" style="position: relative;top:-63px" @click="cancel()">
              back
            </b-button>
          </b-col> 
          <!-- <b-col class="text-right"  style="padding-right:20px;margin:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col> -->
        </b-row>
      </template>
    </b-modal>
    <b-modal id="tastersFeedback" title="tasters feedback" @ok="doNothing()" @cancel="doNothing()">
      <div :key="feedback_count">
        <!-- {{ feedbacks.length }} - {{ feedback_count }} -->
        <div v-if="feedbacks.length > 0">
          <div
            v-for="(feedback,index) in feedbacks"
            v-bind:item="feedback"
            v-bind:index="index"
            v-bind:key="feedback._id"
            style="padding 10px 10px"
          >
            <b-row>
              <b-col cols="3" style="padding-right:0; padding-left:15px">
                <b-avatar v-if="feedback.profile_picture" 
                  size="80px" 
                  v-bind:src="feedback.profile_picture"
                >
                </b-avatar>
              </b-col>
              <b-col v-if="(feedback.appearance.length+feedback.nose.length+feedback.palate.length+feedback.conclusions.length) == 0" cols="9">
                <p class="t-title" style="margin-top:0">{{feedback.user}}</p>
                <p class="t-text"> created a tasting note but is still empty</p>
              </b-col>
              <b-col v-else cols="9" style="padding-left:0">
                <b-row style="height:25px">
                  <b-col cols="9">
                    <p class="t-title" style="margin-top:0">{{feedback.user}}</p>
                  </b-col>
                  <b-col cols="3" style="padding-right:15px">
                    <p 
                      v-if="feedback.wset_level == 'wset2'"
                      class="wset2-property w-cursor-click" 
                      style="color:rgb(39, 97, 147)" 
                      align="right"
                      v-on:click=showModal_ReadOnly_WSET2_SAT(index)>
                      <u>{{feedback.wset_level.toUpperCase()}}</u>
                    </p>
                    <p 
                      v-else-if="feedback.wset_level == 'wset3'"
                      class="wset3-property w-cursor-click" 
                      style="color:rgb(1, 140, 83)" 
                      align="right"
                      v-on:click=showModal_ReadOnly_WSET3_SAT(index)>
                      <u>{{feedback.wset_level.toUpperCase()}}</u>
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p v-if="feedback.appearance" class="t-text"><b>appearence: </b>{{feedback.appearance}}</p>
                    <p v-if="feedback.nose" class="t-text"><b>nose: </b>{{feedback.nose}}</p>
                    <p v-if="feedback.palate" class="t-text"><b>palate: </b>{{feedback.palate}}</p>
                    <p v-if="feedback.conclusions" class="t-text"><b>conclusions: </b>{{feedback.conclusions}}</p>
                    <p v-if="feedback.score" class="t-text text-right"><b>{{feedback.score}}/100</b></p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr v-if="index < feedbacks.length -1">
          </div>
        </div>
        <div v-else>
          <p class="t-title" style="margin-top:0">no one has completed their tasting note yet.</p>
        </div>

      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%;padding:0">
          <b-col class="text-left" style="margin:0;padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>  
          <b-col class="text-right"  style="margin:0;padding:0">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="ReadOnly_WSET2_SAT" size="lg" @ok="hideModal_ReadOnly_WSET2_SAT" @cancel="hideModal_ReadOnly_WSET2_SAT" scrollable>
      <template #modal-header>
        <div style="display: block;">
          <p class="wset2-title">WSET Level 2 Systematic Approach to Tasting Wine®</p>
          <p class="wset2-subtitle">{{selected_wine}} tasted by {{selected_user}}</p>
        </div>
      </template>
        <div class="watermark">Read Only</div>
        <div
          v-for="(category,index) in wset2.SAT"
          v-bind:item="category"
          v-bind:index="index"
          v-bind:key="index"
          style="padding-bottom:0"
        >
            <p
              class="wset2-category"
            >
              {{ category.property }}
            </p>
            <div v-if="category.goesDeeper == true">
              <div 
                v-for="(value1,index1) in category.values"
                v-bind:item="value1"
                v-bind:index="index1"
                v-bind:key="index1"
              > 
                <div v-if="value1.goesDeeper == true">
                  <p
                    class="wset2-property"
                    style="margin-left:10px;margin-bottom:0; width:30%;clear:both"
                  >
                    {{ value1.property }}
                  </p>
                  <div
                    v-for="(value2,index2) in value1.values"
                    v-bind:item="value2"
                    v-bind:index="index2"
                    v-bind:key="index2"
                  >
                    <div style="float:left; margin-left:15%;width:15%">
                      <p
                        class="wset2-property"
                        style="margin-left:10px;margin-bottom:0; width:15%;float:left"
                      >
                        {{ value2.property }}
                      </p>                    
                    </div> 
                    <div style="float:left; width:70%">
                      <div
                        v-for="(value3,index3) in value2.values"
                        v-bind:item="value3"
                        v-bind:index="index3"
                        v-bind:key="index3"
                        style="display:inline-block"
                      >
                        <p 
                            class="wset2-value" 
                            style="margin-left:5px;float:left;margin-bottom: 0px"
                          >
                          <span v-if="isSelected([index,index1,index2,index3])" style="font-family:'proximanova-bold'"><b><u>{{ value3 }}</u></b></span>
                          <span v-else>{{ value3 }}</span>
                          <span v-if="index3 < value2.values.length-1"> - </span>
                        </p>
                      </div>
                    </div>
                    <br style="clear:both"/>
                  </div>
                </div>
                <div v-else>
                  <div style="float: left; width:30%">
                    <p
                      class="wset2-property"
                      style="margin-left:10px;float:left;margin-bottom:0; width:100%"
                    >
                      {{ value1.property }}
                    </p>
                  </div>
                  <div style="float:left; width:70%">
                    <div
                      v-for="(value2,index2) in value1.values"
                      v-bind:item="value2"
                      v-bind:index="index2"
                      v-bind:key="index2"
                      style="display:inline-block"
                    >
                      <p 
                          v-if="(index==1 && index1==2) || (index==2 && index1==6) "
                          class="wset2-value w-cursor-click" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                          v-on:click="onSelect_WSET2([index,index1,index2])"
                        >
                        <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                        <span v-else-if="(index==1 && index1==2) || (index==2 && index1==6) " style="font-family:'proximanova-bold'; color:rgb(39, 97, 147)">{{value2}}</span>
                        <span v-else>{{ value2 }}</span>
                        <span v-if="index2 < value1.values.length-1"> - </span>
                      </p>
                      <p 
                          v-else
                          class="wset2-value" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                        >
                        <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                        <span v-else-if="(index==1 && index1==2) || (index==2 && index1==6) " style="font-family:'proximanova-bold'; color:rgb(39, 97, 147)">{{value2}}</span>
                        <span v-else>{{ value2 }}</span>
                        <span v-if="index2 < value1.values.length-1"> - </span>
                      </p>
                    </div>
                  </div>
                  <br style="clear:both" />
                </div>
              </div>
              <br style="clear:both" />  
            </div>
            <div v-else> 
              <p>Level 0: NOT APPLICATABLE IN WSET2.</p>
            </div>
        </div>
      <hr>
      <div style="float:left; margin-left:10px;width:28%">
        <b-img
          width="110px"
          :src="require('@/assets/img/WSET2_Landscape_Descriptor.png')"
        >

        </b-img>
      </div>
      <div style="float:left; margin-left:10px; width:68%">
        <p class="w-smalltext" style="margin-bottom:0"><b>{{wset2.version}}</b></p>
        <p class="w-smalltext" style="margin-bottom:0">© Copyright Wine & Spirit Education Trust 2023. All Rights Reserved.</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="wset2-button-left" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="wset2-button-right" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="ReadOnly_WSET3_SAT" size="lg" @ok="hideModal_ReadOnly_WSET3_SAT" @cancel="hideModal_ReadOnly_WSET3_SAT" scrollable>
      <template #modal-header>
        <div style="display: block;">
          <p class="wset3-title">WSET Level 3 Systematic Approach to Tasting Wine®</p>
          <p class="wset3-subtitle">{{selected_wine}} tasted by {{selected_user}}</p>
        </div>
      </template>
        <div class="watermark">Read Only</div>
        <div
          v-for="(category,index) in wset3.SAT"
          v-bind:item="category"
          v-bind:index="index"
          v-bind:key="index"
          style="padding-bottom:0"
        >
            <p
              class="wset3-category"
            >
              {{ category.property }}
            </p>
            <div v-if="category.goesDeeper == true">
              <div 
                v-for="(value1,index1) in category.values"
                v-bind:item="value1"
                v-bind:index="index1"
                v-bind:key="index1"
              > 
                <div v-if="value1.goesDeeper == true">
                  <p
                    class="wset3-property"
                    style="margin-left:10px;margin-bottom:0; width:30%;clear:both"
                  >
                    {{ value1.property }}
                  </p>
                  <div
                    v-for="(value2,index2) in value1.values"
                    v-bind:item="value2"
                    v-bind:index="index2"
                    v-bind:key="index2"
                  >
                    <div style="float:left; margin-left:15%;width:15%">
                      <p
                        class="wset3-property"
                        style="margin-left:10px;margin-bottom:0; width:15%;float:left"
                      >
                        {{ value2.property }}
                      </p>                    
                    </div> 
                    <div style="float:left; width:70%">
                      <div
                        v-for="(value3,index3) in value2.values"
                        v-bind:item="value3"
                        v-bind:index="index3"
                        v-bind:key="index3"
                        style="display:inline-block"
                      >
                        <p 
                            class="wset3-value" 
                            style="margin-left:5px;float:left;margin-bottom: 0px"
                          >
                          <span v-if="isSelected([index,index1,index2,index3])" style="font-family:'proximanova-bold'"><b><u>{{ value3 }}</u></b></span>
                          <span v-else>{{ value3 }}</span>
                          <span v-if="index3 < value2.values.length-1"> - </span>
                        </p>
                      </div>
                    </div>
                    <br style="clear:both"/>
                  </div>
                </div>
                <div v-else>
                  <div style="float: left; width:30%">
                    <p
                      class="wset3-property"
                      style="margin-left:10px;float:left;margin-bottom:0; width:100%"
                    >
                      {{ value1.property }}
                    </p>
                  </div>
                  <div style="float:left; width:70%">
                    <div
                      v-for="(value2,index2) in value1.values"
                      v-bind:item="value2"
                      v-bind:index="index2"
                      v-bind:key="index2"
                      style="display:inline-block"
                    >
                      <p 
                          v-if="(index==1 && index1==2) || (index==2 && index1==7) "
                          class="wset3-value w-cursor-click" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                          v-on:click="onSelect_WSET3([index,index1,index2])"
                        >
                        <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                        <span v-else-if="(index==1 && index1==2) || (index==2 && index1==7) " style="font-family:'proximanova-bold'; color:rgb(1, 140, 83)">{{value2}}</span>
                        <span v-else>{{ value2 }}</span>
                        <span v-if="index2 < value1.values.length-1"> - </span>
                      </p>
                      <p 
                          v-else
                          class="wset3-value" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                        >
                        <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                        <span v-else-if="(index==1 && index1==2) || (index==2 && index1==7) " style="font-family:'proximanova-bold'; color:rgb(1, 140, 83)">{{value2}}</span>
                        <span v-else>{{ value2 }}</span>
                        <span v-if="index2 < value1.values.length-1"> - </span>
                      </p>
                    </div>
                  </div>
                  <br style="clear:both" />
                </div>
              </div>
              <br style="clear:both" />  
            </div>
            <div v-else> 
              <p>Level 0: NOT APPLICATABLE IN WSET3.</p>
            </div>
        </div>
      <hr>
      <div style="float:left; margin-left:10px;width:28%">
        <b-img
          width="110px"
          :src="require('@/assets/img/WSET3_Landscape_Descriptor.png')"
        >

        </b-img>
      </div>
      <div style="float:left; margin-left:10px; width:68%">
        <p class="w-smalltext" style="margin-bottom:0"><b>{{wset3.version}}</b></p>
        <p class="w-smalltext" style="margin-bottom:0">© Copyright Wine & Spirit Education Trust 2023. All Rights Reserved.</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="wset3-button-left" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="wset3-button-right" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="ReadOnly_WSET2_WineLexicon" size="lg" @ok="hideModal_ReadOnly_WSET2_WineLexicon" @cancel="hideModal_ReadOnly_WSET2_WineLexicon" scrollable>
      <!-- <p>selected: {{selected_indices}}</p> -->
      <template #modal-header>
        <div style="display:block">
          <p class="wset2-title">WSET Level 2 Wine-Lexicon</p>
          <p class="wset2-subtitle">Supporting the WSET Level 2 Systematic Approach to Tasting Wine®</p>
          <p class="wset2-subtitle">{{selected_wine}} tasted by {{selected_user}}</p>
        </div>
      </template>

      <p class="wset2-category">Describing {{ wine_lexicon }}</p>
      <div class="watermark">Read Only</div>
      <div
        v-for="(category,index) in wset2.wine_lexicon"
        v-bind:item="category"
        v-bind:index="index"
        v-bind:key="index"
        style="padding-bottom:0"
      >
          <p
            v-if="index == 0"
            class="wset2-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of the grape and alcoholic fermentation
          </p>
          <p
            v-else-if="index == 1"
            class="wset2-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of post-fermentation winemaking
          </p>
          <p
            v-else-if="index == 2"
            class="wset2-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of of maturation
          </p>
          <p
            v-else
            class="wset2-subcategory"
          >
            ERROR
          </p>
          <div v-if="category.goesDeeper == true">
            <div 
              v-for="(value1,index1) in category.values"
              v-bind:item="value1"
              v-bind:index="index1"
              v-bind:key="index1"
            > 
              <div v-if="value1.goesDeeper == true">
                <p
                  class="wset2-property"
                  style="margin-left:10px;margin-bottom:0; width:30%;clear:both"
                >
                  {{ value1.property }}
                </p>
                <div
                  v-for="(value2,index2) in value1.values"
                  v-bind:item="value2"
                  v-bind:index="index2"
                  v-bind:key="index2"
                >
                  <p
                    class="wset2-property"
                    style="margin-left:calc(15% + 10px);margin-bottom:0; width:15%;float:left"
                  >
                    {{ value2.property }}
                  </p>
                  <div style="padding-left:15%">
                    <div
                      v-for="(value3,index3) in value2.values"
                      v-bind:item="value3"
                      v-bind:index="index3"
                      v-bind:key="index3"
                    >
                      <p v-if="wine_lexicon == ''"
                          class="wset2-value w-cursor-click" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                          v-on:click="onSelect_WSET2([index,index1,index2,index3])"
                        >
                        <span v-if="isSelected([index,index1,index2,index3])" style="font-family:'proximanova-bold'"><b><u>{{ value3 }}</u></b></span>
                        <span v-else>{{ value3 }}</span>
                        <span v-if="index3 < value2.values.length-1"> - </span>
                      </p>
                      <p v-else>
                        CANNOT BE IN WSET2
                      </p>
                    </div>
                  </div>
                  <br style="clear:both"/>
                </div>
              </div>
              <div v-else>
                <div style="float: left; width:30%">
                  <p
                    class="wset2-property"
                    style="margin-left:10px;margin-bottom:0;"
                  >
                    {{ value1.property }}
                  </p>
                </div>
                <div style="float:left; width:70%">
                  <div
                    v-for="(value2,index2) in value1.values"
                    v-bind:item="value2"
                    v-bind:index="index2"
                    v-bind:key="index2"
                    style="display:inline-block"
                  >
                    <p v-if="wine_lexicon == ''"
                        class="wset2-value" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                      >
                      <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                    <p v-if="wine_lexicon == 'aroma'"
                        class="wset2-value" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                      >
                      <span v-if="isSelected([1,2,index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                    <p v-if="wine_lexicon == 'flavour'"
                        class="wset2-value" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                      >
                      <span v-if="isSelected([2,6,index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                  </div>
                </div>
              </div>
            <br style="clear:both" />  
          </div>
         </div>
         <div v-else> 
            <p>Level 0: NOT APPLICATABLE IN WSET2.</p>
          </div>
      </div>
      <hr>
      <div style="float:left; margin-left:10px;width:28%">
        <b-img
          width="110px"
          :src="require('@/assets/img/WSET2_Landscape_Descriptor.png')"
        >

        </b-img>
      </div>
      <div style="float:left; margin-left:10px; width:68%">
        <p class="w-smalltext" style="margin-bottom:0"><b>{{wset2.version}}</b></p>
        <p class="w-smalltext" style="margin-bottom:0">© Copyright Wine & Spirit Education Trust 2023. All Rights Reserved.</p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="wset2-button-left" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="wset2-button-right" @click="ok()">
              back
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="ReadOnly_WSET3_WineLexicon" size="lg" @ok="hideModal_ReadOnly_WSET3_WineLexicon" @cancel="hideModal_ReadOnly_WSET3_WineLexicon" scrollable>
      <!-- <p>selected: {{selected_indices}}</p> -->
      <template #modal-header>
        <div style="display:block">
          <p class="wset3-title">WSET Level 3 Wine-Lexicon</p>
          <p class="wset3-subtitle">Supporting the WSET Level 3 Systematic Approach to Tasting Wine®</p>
          <p class="wset3-subtitle">{{selected_wine}} tasted by {{selected_user}}</p>
        </div>
      </template>

      <p class="wset3-category">Describing {{ wine_lexicon }}</p>
      <div class="watermark">Read Only</div>
      <div
        v-for="(category,index) in wset3.wine_lexicon"
        v-bind:item="category"
        v-bind:index="index"
        v-bind:key="index"
        style="padding-bottom:0"
      >
          <p
            v-if="index == 0"
            class="wset3-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of the grape and alcoholic fermentation
          </p>
          <p
            v-else-if="index == 1"
            class="wset3-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of post-fermentation winemaking
          </p>
          <p
            v-else-if="index == 2"
            class="wset3-subcategory"
          >
            {{ category.property }} {{wine_lexicon}}s - The {{wine_lexicon}}s of of maturation
          </p>
          <p
            v-else
            class="wset3-subcategory"
          >
            ERROR
          </p>
          <div v-if="category.goesDeeper == true">
            <div 
              v-for="(value1,index1) in category.values"
              v-bind:item="value1"
              v-bind:index="index1"
              v-bind:key="index1"
            > 
              <div v-if="value1.goesDeeper == true">
                <p
                  class="wset3-property"
                  style="margin-left:10px;margin-bottom:0; width:30%;clear:both"
                >
                  {{ value1.property }}
                </p>
                <div
                  v-for="(value2,index2) in value1.values"
                  v-bind:item="value2"
                  v-bind:index="index2"
                  v-bind:key="index2"
                >
                  <p
                    class="wset3-property"
                    style="margin-left:calc(15% + 10px);margin-bottom:0; width:15%;float:left"
                  >
                    {{ value2.property }}
                  </p>
                  <div style="padding-left:15%">
                    <div
                      v-for="(value3,index3) in value2.values"
                      v-bind:item="value3"
                      v-bind:index="index3"
                      v-bind:key="index3"
                    >
                      <p v-if="wine_lexicon == ''"
                          class="wset3-value w-cursor-click" 
                          style="margin-left:5px;float:left;margin-bottom: 0px"
                          v-on:click="onSelect_WSET3([index,index1,index2,index3])"
                        >
                        <span v-if="isSelected([index,index1,index2,index3])" style="font-family:'proximanova-bold'"><b><u>{{ value3 }}</u></b></span>
                        <span v-else>{{ value3 }}</span>
                        <span v-if="index3 < value2.values.length-1"> - </span>
                      </p>
                      <p v-else>
                        CANNOT BE IN WSET3
                      </p>
                    </div>
                  </div>
                  <br style="clear:both"/>
                </div>
              </div>
              <div v-else>
                <div style="float: left; width:30%">
                  <p
                    class="wset3-property"
                    style="margin-left:10px;margin-bottom:0;"
                  >
                    {{ value1.property }}
                  </p>
                </div>
                <div style="float:left; width:70%">
                  <div
                    v-for="(value2,index2) in value1.values"
                    v-bind:item="value2"
                    v-bind:index="index2"
                    v-bind:key="index2"
                    style="display:inline-block"
                  >
                    <p v-if="wine_lexicon == ''"
                        class="wset3-value" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                      >
                      <span v-if="isSelected([index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                    <p v-if="wine_lexicon == 'aroma'"
                        class="wset3-value" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                      >
                      <span v-if="isSelected([1,2,index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                    <p v-if="wine_lexicon == 'flavour'"
                        class="wset3-value" 
                        style="margin-left:5px;float:left;margin-bottom: 0px"
                      >
                      <span v-if="isSelected([2,7,index,index1,index2])" style="font-family:'proximanova-bold'"><u>{{ value2 }}</u></span>
                      <span v-else>{{ value2 }}</span>
                      <span v-if="index2 < value1.values.length-1"> - </span>
                    </p>
                  </div>
                </div>
              </div>
            <br style="clear:both" />  
          </div>
         </div>
         <div v-else> 
            <p>Level 0: NOT APPLICATABLE IN WSET3.</p>
          </div>
      </div>
      <hr>
      <div style="float:left; margin-left:10px;width:28%">
        <b-img
          width="110px"
          :src="require('@/assets/img/WSET3_Landscape_Descriptor.png')"
        >

        </b-img>
      </div>
      <div style="float:left; margin-left:10px; width:68%">
        <p class="w-smalltext" style="margin-bottom:0"><b>{{wset3.version}}</b></p>
        <p class="w-smalltext" style="margin-bottom:0">© Copyright Wine & Spirit Education Trust 2023. All Rights Reserved.</p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="wset3-button-left" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="wset3-button-right" @click="ok()">
              back
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>    
  </div>
</template>

<script>
  import {isEqualAoA,isInAoA,removeFromAoA,sliceAoA,orderAoA} from '../assets/js/AoA.js';
  import {wset2,wset2_lookup} from '../assets/js/wset2_issue2.js';
  import {wset3,wset3_lookup} from '../assets/js/wset3_issue2.js';
  
  import NoAccess from './NoAccess.vue'

  import LibrarySearch from './LibrarySearch_Desktop.vue';
  import FullDefineTasting from './FullDefine_Tasting_Desktop.vue';
  import FullDefineWineList from './FullDefine_WineList_Desktop.vue';

  import Users from '../Users.js'
  import WineLists from '../WineLists.js'
  import Items from '../Items.js'
  import WishLists from '../WishLists.js'
  import Tastings from '../Tastings.js';
  import TastingNotes from '../TastingNotes.js';

  import fromItemID from '../fromItemID.js';

  export default {
    name: "Item",
    components: {
      NoAccess,
      LibrarySearch,
      FullDefineTasting,
      FullDefineWineList
    },
    props: ['tastingNote','tastingnoteId'],
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      nice_appellation() {
        let result = ''
        if (this.wine_id != 0 ) {
          if (this.wine.country != '') result = this.wine.country
          if (this.wine.region != '') {
            result = this.wine.region + ' - ' + result;
          }
          if (this.wine.appellation != '') {
            result = this.wine.appellation + ' - ' + result;
          }
        }
        else if (this.c_wine_id != 0 ) {
          if (this.c_wine.country != '') result = this.c_wine.country
          if (this.c_wine.region != '') {
            result = this.c_wine.region + ' - ' + result;
          }
          if (this.c_wine.appellation != '') {
            result = this.c_wine.appellation + ' - ' + result;
          }
        }
        return result
      },
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        isValid: true,

        user:{},
        user_id:0,

        tasting:{},
        tasting_id:0,

        tasting_note:{},
        tasting_note_id:0,

        item:{},
        item_id:0,

        bottle:{},
        bottle_id:0,

        c_wine:{
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          longevity:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
          user_id:0,
        },
        c_wine_id:0,

        wine:{
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          longevity:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
          winery_id:0,
          tasting_notes:[],
        },
        wine_id:0,

        wishlist:{
          user_id:0,
          bottle_ids:[],
        },
        wishlist_id:0, 

        tasting_note_isValid: true,

        //Tabs
        tabIndex:0,

        //disclosed
        disclosed: false,
        disclosed_color: "rgb(220,220,220)",
        disclosed_tooltip_text: 'click to disclose',

        //WishList
        heart_color: "rgb(220,220,220)",
        heart_tooltip_text: 'click to add to wishlist',

        //Refresh
        renderChild: true,

        //Source
        source:'none',

        //Feedback
        feedbacks:[],
        feedback_count:0,

        //wset
        wset2:{},
        wset3:{},
        selected_indices:[],
        wine_lexicon:'',
      }
    }, 
    async mounted() {
      await this.mounted_as_method()
    },
    methods: {   
      async mounted_as_method() {
        let local_debug = false
        if (local_debug || this.debug) console.log("")
        if (local_debug || this.debug) console.log("=====> Item.mounted: START")

        this.wset2 = wset2
        this.wset3 = wset3

        this.user_id = this.currentUserID;
        this.user = this.currentUser;

        this.source = this.$store.getters.getSource;
        if ( this.source != 'WineList' && this.source != 'TastingNote') {
          if (local_debug || this.debug) console.log("=====> Item.mounted: FATAL ERROR: ILLEGAL SOURCE = ", this.source)
          return
        }

        if (local_debug || this.debug) console.log("=====> Item.mounted: SOURCE = ", this.source)
        if (local_debug || this.debug) console.log("=====> Item.mounted: READING THE STORE")

        if (this.$store.getters.getTastingID != 0) {
          this.tasting_id = this.$store.getters.getTastingID
          this.tasting = this.$store.getters.getTasting
          if (local_debug || this.debug) console.log("=====> Item.mounted: tasting ", this.tasting, " with id ", this.tasting_id,  " found in store.")
        } else {
          if (local_debug || this.debug) console.log("=====> Item.mounted: NO tasting found in store.")
        }

        if (this.$store.getters.getTastingnoteID != 0) {
          this.tasting_note_id = this.$store.getters.getTastingnoteID
          this.tasting_note = this.$store.getters.getTastingnote
          if (this.tasting_note.onWishList == true) {
            this.heart_color = "rgb(213,27,17)"
            this.heart_tooltip_text = 'click to remove from wishlist'
          }
          if (local_debug || this.debug) console.log("=====> Item.mounted: tasting_note ", this.tasting_note, " with id ", this.tasting_note_id,  " found in store.")
        } else {
          if (local_debug || this.debug) console.log("=====> Item.mounted: NO tasting_note found in store.")
        }

        if (this.$store.getters.getItemID != 0) {
          this.item_id = this.$store.getters.getItemID
          this.item = this.$store.getters.getItem
          if (local_debug || this.debug) console.log("=====> Item.mounted: item ", this.item, " with id ", this.item_id,  " found in store.")
        } else {
          if (local_debug || this.debug) console.log("=====> Item.mounted: NO item found in store.")
        }

        if (this.$store.getters.getBottleID != 0) {
          this.bottle_id = this.$store.getters.getBottleID
          this.bottle = this.$store.getters.getBottle
          if (local_debug || this.debug) console.log("=====> Item.mounted: bottle ", this.bottle, " with id ", this.bottle_id,  " found in store.")
        } else {
          if (local_debug || this.debug) console.log("=====> Item.mounted: NO bottle found in store.")
        }
        
        if (this.$store.getters.getCWineID != 0) {
          this.c_wine_id = this.$store.getters.getCWineID
          this.c_wine = this.$store.getters.getCWine
          if (local_debug || this.debug) console.log("=====> Item.mounted: c_wine ", this.c_wine, " with id ", this.c_wine_id,  " found in store.")
        } else {
          if (local_debug || this.debug) console.log("=====> Item.mounted: NO c_wine found in store.")
        }
        
        if (this.$store.getters.getWineID != 0) {
          this.wine_id = this.$store.getters.getWineID
          this.wine = this.$store.getters.getWine
          if (local_debug || this.debug) console.log("=====> Item.mounted: wine ", this.wine, " with id ", this.wine_id,  " found in store.")
        } else {
          if (local_debug || this.debug) console.log("=====> Item.mounted: NO wine found in store.")
        }
        
        if (local_debug || this.debug) console.log( "=====> Item.mounted: user.wishlist_id = ", this.user.wishlist_id);
        if (typeof this.user.wishlist_id != "undefined" && this.user.wishlist_id != 0) {
          this.wishlist_id = this.user.wishlist_id
          if (local_debug || this.debug) console.log("=====> Item.mounted: WishList exists. We use it.")
          await WishLists.getone(this.wishlist_id)
          .then(response=> {
            this.wishlist = response
            delete this.wishlist._id
            if (local_debug || this.debug) console.log( "=====> Item.mounted: WishList ", JSON.stringify(this.wishlist, null, 2), " with id ",this.wishlist_id," succesfully fetched from DB with response ", response);
          })
          .catch(error=> {
            if (local_debug || this.debug) console.log("=====> Item.mounted: await WishLists.getone", error);
          })
        } else {
          if (local_debug || this.debug) console.log("=====> Item.mounted: WishList DOES NOT exists. We make it.")
          this.wishlist.user_id = this.user_id
          await WishLists.create(this.wishlist)
          .then(response=> {
            if (local_debug || this.debug) console.log( "=====> Item.mounted: WishList ", JSON.stringify(this.wishlist, null, 2), " succesfully created in DB with response ", response);
            this.wishlist_id = response
          })
          .catch(error=> {
            if (local_debug || this.debug) console.log("=====> Item.mounted: await WishLists.create", error);
          })
          this.$store.commit('setWishlist',this.wishlist)
          this.$store.commit('setWishlistID',this.wishlist_id)

          this.user.wishlist_id = this.wishlist_id
          await Users.update(this.user_id, this.user)
          .then(response=> {
            if (local_debug || this.debug) console.log( "=====> Item.mounted: User ", this.user, " succesfully update in DB with response ", response);
          })
          .catch(error=> {
            if (local_debug || this.debug) console.log("=====> Item.mounted: await Users.update", error);
          })
          this.$store.commit('setUser',this.user)
        }

        if (this.item.isDisclosed) {
            this.disclosed = true
            this.disclosed_color = "rgb(109,122,82)"
            this.disclosed_tooltip_text = "click to undisclose"
          } else {
            this.disclosed = false
            this.disclosed_color = "rgb(220,220,220)"
            this.disclosed_tooltip_text = "click to disclose"          
          }

        this.isLoading = false

        if (local_debug || this.debug) console.log("=====> Item.mounted: STOP")
      },   

      async refreshParent() {
        let local_debug = false
        if (local_debug) console.log("")
        if (local_debug) console.log("=====> Item.refreshParent: START")
      
        if (this.bottle_id == 0 ) {
          if (this.$store.getters.getBottleID != 0) {
            this.bottle_id = this.$store.getters.getBottleID
            this.bottle = this.$store.getters.getBottle
            if (local_debug || this.debug) console.log("=====> Item.refreshParent: bottle ", this.bottle, " with id ", this.bottle_id,  " found in store.")
          } else {
            if (local_debug || this.debug) console.log("=====> Item.refreshParent: NO bottle found in store.")
          }
        }

        if (this.bottle.wine_id != 0) {
          await this.$refs.library_search.onUpdate()
        } else if (this.bottle.c_wine_id != 0) {
          if (this.source == 'TastingNote') {
            await this.$refs.full_define_tasting.onSave()
          } else if (this.source == 'WineList') {
            await this.$refs.full_define_winelist.onSave()
          } else {
            alert("ERROR: ILLEGAL SOURCE = "+ this.source)
          } 
        } else {
          if (local_debug) console.log("=====> Item.refreshParent: ERROR - this.bottle.wine_id = ",this.bottle.wine_id," this.bottle.c_wine_id = ", this.bottle.c_wine_id)
        }

        if (local_debug) console.log("=====> Item.refreshParent: emiting refreshRequest")
        await this.$emit('refreshRequest');
        this.$root.$emit("bv::hide::modal", "addBottle");
        this.$root.$emit("bv::hide::modal", "editBottle");
        if (local_debug) console.log("=====> Item.refreshParent: STOP")
      },

      async toggleWishList() {
        let local_debug = false
        if (local_debug) console.log("=====> Item.toggleWishList: START")
        if (this.tasting_note.onWishList == true ) {
          if (local_debug) console.log("=====> Item.toggleWishList: from true to false")
          this.tasting_note.onWishList = false
          this.heart_color = "rgb(220,220,220)"
          this.heart_tooltip_text = "click to add to wishlist"
          let i = this.wishlist.bottle_ids.indexOf(this.bottle_id)
          if (local_debug) console.log("i = ", i)
          if ( i != -1 ) {
            this.wishlist.bottle_ids.splice(i,1)
          } else {
            if (local_debug) console.log("=====> Item.toggleWishList: bottle_id = ", this.bottle_id, " not found in wishlist.bottle_ids = ", this.wishlist.bottle_ids)
          }
        } else {
          if (local_debug) console.log("=====> Item.toggleWishList: from false to true")
          this.tasting_note.onWishList = true
          this.heart_color = "rgb(213,27,17)"
          this.heart_tooltip_text = "click to remove from wishlist"
          this.wishlist.bottle_ids.push(this.bottle_id)
        }
        if (typeof this.tasting_note._id != "undefined") {
          delete this.tasting_note._id
        }
        await TastingNotes.update(this.tasting_note_id, this.tasting_note)
        .then(response=> {
          if (local_debug) console.log( "=====> Item.toggleWishList: TastingNote ", this.tasting_note, " succesfully update in DB with response ", response);
        })
        .catch(error=> {
          if (local_debug) console.log("=====> Item.toggleWishList: await TastingNotes.update", error);
        })
        this.$store.commit('setTastingnote',this.tasting_note)

        await WishLists.update(this.wishlist_id, this.wishlist)
        .then(response=> {
          if (local_debug) console.log( "=====> Item.toggleWishList: Wishlist ", this.wishlist, " succesfully update in DB with response ", response);
        })
        .catch(error=> {
          if (local_debug) console.log("=====> Item.toggleWishList: await Wishlists.update", error);
        })
        this.$store.commit('setWishlist',this.wishlist)
        this.$store.commit('setWishlistID',this.wishlist_id)

        await this.$emit('refreshRequest');
        if (local_debug) console.log("=====> Item.toggleWishList: STOP")
      },

      async toggleDisclosure() {
        let local_debug = false
        if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: START")
        if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: winelist_id = ",this.tasting.winelist_id)
        if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: item_id = ",this.item_id)
        if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: item = ",JSON.stringify(this.item))
        
        if (this.disclosed == false) {
          this.disclosed = true
          this.disclosed_color = "rgb(109,122,82)"
          this.disclosed_tooltip_text = "click to undisclose"
        } else {
          this.disclosed = false
          this.disclosed_color = "rgb(220,220,220)"
          this.disclosed_tooltip_text = "click to disclose"          
        }

        // 1. retrieving winelist
        let winelist = {}
        let winelist_id = 0
        await WineLists.getone(this.tasting.winelist_id)
        .then(response => {
          winelist = response
          winelist_id = response._id
          delete winelist._id
          if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: winelist successfully retreived with id: ", winelist_id);
        })
        .catch(error => {
          if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: await WineLists.getone", error);
        })
        // 2. Setting value.isDisclosed to this.disclosed
        for ( let i = 0 ; i < winelist.nodes.length ; i++ ) {
          if ( winelist.nodes[i].isLeaf ) {
            if ( winelist.nodes[i].value._id == this.item_id) {
              winelist.nodes[i].value.isDisclosed = this.disclosed
              if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: found item in winelist setting isDisclosed to ", this.disclosed);
            }
          } else {
            // dig deeper NOT for now.
          }
        }
        await WineLists.update(winelist_id,winelist)
        .then(response =>{
          if (this.debug || local_debug) console.log( "=====> Item.toggleDisclosure: Winelist ", winelist, " succesfully update in DB with response ", response);
        })
        .catch(error =>{
          if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: await Winelists.update", error);
        })
        this.$store.commit('setWinelist',this.winelist)
        // 3. Setting the item.isDisclosed to this.disclosed
        this.item.isDisclosed = this.disclosed

        await Items.update(this.item_id, this.item)
        .then(response =>{
          if (this.debug || local_debug) console.log( "=====> Item.toggleDisclosure: Item ", this.item, " succesfully update in DB with response ", response);
        })
        .catch(error =>{
          if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: await Items.update", error);
        })
        this.$store.commit('setItem',this.item)
        // 4. Setting the corresponding tasting_note.isDisclosed to this.disclosed
        // 4a. Current tasting_notes
        this.tasting_note.isDisclosed = this.disclosed
        let pos = -1
        for (let i=0 ; i < this.tasting.tasting_note_ids.length ; i++) {
          await TastingNotes.getone(this.tasting.tasting_note_ids[i])
          .then(response => {
            if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: TastingNotes.getone ", response, " succesfully fetched from DB");
            if (response._id ==  this.tasting_note_id) {
                pos = i
                if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: tasting_note ", response, " has position = ", i); 
            }
          })
          .catch(error => {
            if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: await TastingNotes.getone - ", error);
          })
        }
        
        if (pos == -1) {
          console.log("=====> Item.toggleDisclosure: SEVERE ERROR: tasting_note not found in tasting.tasting_ids.")
        }

        if (this.tasting.isBlind == true && this.tasting_note.isDisclosed == false) {
          this.tasting_note.name = "Wine " + (pos+1)
        } else {
          this.tasting_note.name = this.item.name
        }
        this.$store.commit('setTastingnote',this.tasting_note)
        // 4b. All tasting_notes containing tasting_note.item_id == item_id
        let tasting_notes = []
        let current_tasting_note_id = 0 
        
        let challenge = {"item_id": this.item_id}
        await fromItemID.getTastingNotes(challenge)
        .then(response => {
          if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: fromItemID.getTastingNotes ", response, " succesfully fetched from DB");
          tasting_notes = response; 
        })
        .catch(error => {
          if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: await fromItem.getTastingNotes - ", error);
        })
        for (let i = 0 ; i < tasting_notes.length ; i++) {
          tasting_notes[i].isDisclosed = this.disclosed
          current_tasting_note_id = tasting_notes[i]._id
          delete tasting_notes[i]._id
          await TastingNotes.update(current_tasting_note_id, tasting_notes[i])
          .then(response =>{
            if (this.debug || local_debug) console.log( "=====> Item.toggleDisclosure: TastingNote ", tasting_notes[i], " succesfully update in DB with response ", response);
          })
          .catch(error =>{
            if (this.debug || local_debug) console.log("=====> Item.toggleDisclosure: await Items.update", error);
          })
        } 

        await this.$emit('refreshRequest');

        if (local_debug) console.log("=====> Item.toggleDisclosure: STOP")
      },

      showModal_addBottle() {
        if (this.debug) console.log("Item.showModal_addBottle: START");
        this.$root.$emit("bv::show::modal", "addBottle");
        if (this.debug) console.log("Item.showModal_addBottle: STOP");
      },

      showModal_editBottle() {
        if (this.debug) console.log("Item.showModal_editBottle: START");
        this.$root.$emit("bv::show::modal", "editBottle");
        if (this.debug) console.log("Item.showModal_editBottle: STOP");
      },

      async showModal_tastersFeedback() {
        if (this.debug) console.log("Item.showModal_tastersFeedback: START");
        await this.updateFeedback()
        this.feedback_count++
        this.$root.$emit("bv::show::modal", "tastersFeedback");
        if (this.debug) console.log("Item.showModal_tastersFeedback: STOP");
      },

      async addBottle() {
        await this.mounted_as_method()
        if (this.source == 'TastingNote') {
          await this.$refs.full_define_tasting.onSave()
        } else if (this.source == 'WineList') {
          await this.$refs.full_define_winelist.onSave()
        } else {
          alert("ERROR: ILLEGAL SOURCE = "+ this.source)
        }
        await this.$emit('refreshRequest');
      },

      async editBottle() {
        if (this.source == 'TastingNote') {
          await this.$refs.full_define_tasting.onSave()
        } else if (this.source == 'WineList') {
          await this.$refs.full_define_winelist.onSave()
        } else {
          alert("ERROR: ILLEGAL SOURCE = "+ this.source)
        }
        await this.$emit('refreshRequest');
      },

      async updateFeedback() {
        let local_debug = false
        if (local_debug || this.debug) console.log("Item.updateFeedback: START");
        let winelist_id = 0
        let winelist = {}
        let tastings = []
        let count=0

        if (this.$store.getters.getWinelistID != 0) {
          winelist_id = this.$store.getters.getWinelistID          
          if (local_debug || this.debug) console.log("Item.updateFeedback: updateFeedback winelist ", winelist, " with id ", winelist_id,  " found in store.")
        } else {
          if (local_debug || this.debug) console.log("Item.updateFeedback: NO winelist found.")
          // GET THE FUCKING WINELIST FROM THE TASTING
          if (typeof this.tasting.winelist_id != "undefined" && this.tasting.winelist_id.length == 24) {
            winelist_id = this.tasting.winelist_id
            if (local_debug || this.debug) console.log("Item.updateFeedback: updateFeedback winelist ", winelist, " with id ", winelist_id,  " found in tasting.")
          } else {
            if (local_debug || this.debug) console.log("Item.updateFeedback: NO winelist found.")
            if (local_debug || this.debug) console.log("Item.updateFeedback: STOP");
            return            
          }          
        }

        await WineLists.getone(winelist_id)
            .then(response=> {
              if (typeof response == "object") {
                winelist = response
                delete winelist._id
                this.$store.commit('setWishlist',this.wishlist)
              } else {
                if (local_debug || this.debug) console.log("Item.updateFeedback: ERROR retrieved winelist is not an object.")
              }
            })
            .catch(error=> {
              if (local_debug || this.debug) console.log("Item.updateFeedback: ==> await Tastings.getone", error);
            })

        if (local_debug || this.debug) console.log("Item.updateFeedback: Winelist ",winelist.name," has ",winelist.tasting_ids.length, " participants.");
        for (let i=0 ; i < winelist.tasting_ids.length ; i++) {
          await Tastings.getone(winelist.tasting_ids[i])
            .then(response=> {
              if (typeof response == "object") {
                tastings.push(response)
                if (local_debug || this.debug) console.log("Item.updateFeedback: ==> (",i,") Tasting ", tastings[i].name, " with id ",tastings[i]._id," succesfully fetched from DB");
              } else {
                if (local_debug || this.debug) console.log("Item.updateFeedback: ERROR retrieved tasting is not an object.")
              }  
            })
            .catch(error=> {
              if (local_debug || this.debug) console.log("Item.updateFeedback: ==> await Tastings.getone", error);
            })

          if (tastings[i].user_id.length == 24) {
            await Users.getone(tastings[i].user_id)
            .then(response=> {
              tastings[i].user = response.firstname + " " + response.lastname
              tastings[i].profile_picture = response.profile_picture
              if (local_debug || this.debug) console.log("Item.updateFeedback: ==> (",i,") User ",tastings[i].user , " succesfully fetched from DB");
            })
            .catch(error=> {
              if (local_debug || this.debug) console.log("Item.updateFeedback: ==> await Users.getone", error);
            })
          } else {
            if (local_debug || this.debug) console.log("Item.updateFeedback: ==> ERROR tasting with id ",tastings[i]._id," has no user_id.");
          }

          for (let j=0 ; j < tastings[i].tasting_note_ids.length ; j++) {
            let tasting_note = await TastingNotes.getone(tastings[i].tasting_note_ids[j])
            if (local_debug || this.debug) console.log("Item.updateFeedback: ====> (",i,j,") tasting_note = ", tasting_note);
            if (tasting_note.item_id == this.item_id) {
              if (local_debug || this.debug) console.log("Item.updateFeedback: ====> has the correct item_id")
              if (tasting_note.share == true &&
                tasting_note.appearance.length+tasting_note.nose.length+tasting_note.palate.length+tasting_note.conclusions.length > 0
              ) {
                this.feedbacks[count] = {}
                this.feedbacks[count]._id = i
                this.feedbacks[count].user = tastings[i].user
                this.feedbacks[count].profile_picture = tastings[i].profile_picture
                this.feedbacks[count].wine = tasting_note.name
                // this.feedbacks[count].appearance = tasting_note.appearance
                // this.feedbacks[count].nose = tasting_note.nose
                // this.feedbacks[count].palate = tasting_note.palate
                // this.feedbacks[count].conclusions = tasting_note.conclusions
                this.feedbacks[count].appearance = tasting_note.appearance.replaceAll("\r\n\t",",")
                this.feedbacks[count].nose = tasting_note.nose.replaceAll("\r\n\t",",")
                this.feedbacks[count].palate = tasting_note.palate.replaceAll("\r\n\t",",")
                this.feedbacks[count].conclusions = tasting_note.conclusions.replaceAll("\r\n\t",",")
                this.feedbacks[count].score = tasting_note.score
                this.feedbacks[count].wset = tasting_note.wset
                this.feedbacks[count].wset_level = tasting_note.wset.level
                this.feedbacks[count].share = tasting_note.share
                if (local_debug || this.debug) console.log("Item.updateFeedback: ====> Adding feedback (",count,") = ", this.feedbacks[count]);
                count++
                break
              } else {
                if (tasting_note.share == false) {
                  if ( local_debug || this.debug) console.log("Item.updateFeedback: ====> ... but users decided not to share")
                } else {
                  if ( local_debug || this.debug) console.log("Item.updateFeedback: ====> ... but tasting is still empty")
                }
                break 
              }
            }
          } 
        }
        if (local_debug || this.debug) console.log("Item.updateFeedback: STOP");
      },

      showModal_ReadOnly_WSET2_SAT(index) {
        this.selected_indices = this.feedbacks[index].wset.selected_indices
        this.selected_user = this.feedbacks[index].user
        this.selected_wine = this.feedbacks[index].wine
        this.$root.$emit("bv::show::modal", "ReadOnly_WSET2_SAT");
      },

      async showModal_ReadOnly_WSET2_WineLexicon() {
        this.$root.$emit("bv::show::modal", "ReadOnly_WSET2_WineLexicon");
      },

      async hideModal_ReadOnly_WSET2_SAT() {
        this.$root.$emit("bv::hide::modal", "ReadOnly_WSET2_SAT");
      },

      async hideModal_ReadOnly_WSET2_WineLexicon() {
        this.wine_lexicon = ''
        this.$root.$emit("bv::hide::modal", "ReadOnly_WSET2_WineLexicon");
      },

      showModal_ReadOnly_WSET3_SAT(index) {
        this.selected_indices = this.feedbacks[index].wset.selected_indices
        this.selected_user = this.feedbacks[index].user
        this.selected_wine = this.feedbacks[index].wine
        this.$root.$emit("bv::show::modal", "ReadOnly_WSET3_SAT");
      },

      async showModal_ReadOnly_WSET3_WineLexicon() {
        this.$root.$emit("bv::show::modal", "ReadOnly_WSET3_WineLexicon");
      },

      async hideModal_ReadOnly_WSET3_SAT() {
        this.$root.$emit("bv::hide::modal", "ReadOnly_WSET3_SAT");
      },

      async hideModal_ReadOnly_WSET3_WineLexicon() {
        this.wine_lexicon = ''
        this.$root.$emit("bv::hide::modal", "ReadOnly_WSET3_WineLexicon");
      },

      isSelected(index) {
        if (isInAoA(index,this.selected_indices) != -1) {
          return true
        } else {
          return false
        }
      },

      onSelect_WSET2(index) {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: START")
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: index = ", index, " index.length = ", index.length)

        if (isEqualAoA(index,[1,2,0])) {
          this.wine_lexicon = 'aroma'
          this.showModal_ReadOnly_WSET2_WineLexicon()
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: showing Aroma WineLexicon")
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: STOP")    
          return
        } else if(isEqualAoA(index,[2,6,0])) {
          this.wine_lexicon = 'flavour'
          this.showModal_ReadOnly_WSET2_WineLexicon()
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: showing Palate WineLexicon")
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: STOP")    
          return
        } else {
          if (index[0] == 1 && index[1] == 2) {
          }
          else if (index[0] == 2 && index[1] == 6) {
          } else {
            this.wine_lexicon = ''
          }
        }

        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: BEFORE this.selected_indices = ", JSON.stringify(this.selected_indices))
        
        if (isInAoA(index,this.selected_indices) != -1) {
          if (local_debug) console.log("==> TastingNote.onSelect_WSET2: index is in current_array -> remove it")
          let tmp = this.selected_indices
          if (local_debug) console.log("==> TastingNote.onSelect_WSET2: tmp = ", tmp)
          this.selected_indices = removeFromAoA(index,this.selected_indices)

          if (local_debug) console.log("==> TastingNote.onSelect_WSET2: this.selected_indices = ", this.selected_indices)
        } else {
          if (local_debug) console.log("==> TastingNote.onSelect_WSET2: index is NOT current_array -> process it")
          if (this.wine_lexicon == '') this.cleanChilderen(index)
          this.selected_indices.push(index)
        }

        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: AFTER this.selected_indices = ", JSON.stringify(this.selected_indices))
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET2: STOP")        
      },

      onSelect_WSET3(index) {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: START")
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: index = ", index, " index.length = ", index.length)

        if (isEqualAoA(index,[1,2,0])) {
          this.wine_lexicon = 'aroma'
          this.showModal_ReadOnly_WSET3_WineLexicon()
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: showing Aroma WineLexicon")
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: STOP")    
          return
        } else if(isEqualAoA(index,[2,7,0])) {
          this.wine_lexicon = 'flavour'
          this.showModal_ReadOnly_WSET3_WineLexicon()
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: showing Palate WineLexicon")
          if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: STOP")    
          return
        } else {
          if (index[0] == 1 && index[1] == 2) {
          }
          else if (index[0] == 2 && index[1] == 7) {
          } else {
            this.wine_lexicon = ''
          }
        }

        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: BEFORE this.selected_indices = ", JSON.stringify(this.selected_indices))
        
        if (isInAoA(index,this.selected_indices) != -1) {
          if (local_debug) console.log("==> TastingNote.onSelect_WSET3: index is in current_array -> remove it")
          let tmp = this.selected_indices
          if (local_debug) console.log("==> TastingNote.onSelect_WSET3: tmp = ", tmp)
          this.selected_indices = removeFromAoA(index,this.selected_indices)

          if (local_debug) console.log("==> TastingNote.onSelect_WSET3: this.selected_indices = ", this.selected_indices)
        } else {
          if (local_debug) console.log("==> TastingNote.onSelect_WSET3: index is NOT current_array -> process it")
          if (this.wine_lexicon == '') this.cleanChilderen(index)
          this.selected_indices.push(index)
        }

        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: AFTER this.selected_indices = ", JSON.stringify(this.selected_indices))
        if (this.debug || local_debug) console.log("TastingNote.onSelect_WSET3: STOP")        
      },

      async doNothing() {
      }
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.item-title {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(16px,2vw,20px) !important;
  font-weight:bold;
  margin-bottom:0;
}

.item-subtitle {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(12px,1.4vw,16px) !important;
  margin-bottom:0;
}

.item-text {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(10px,1.4vw,14px) !important;
  margin-bottom:0;
}

.item-price {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(12px,1.4vw,14px) !important;
  margin-bottom:0;
}

.watermark {
  position: absolute;
  width:100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg); /* Rotate the text */
  font-size: 128px;
  text-align:center;
  color: rgba(39, 97, 147, 0.1); /* Adjust the alpha value for transparency */
  pointer-events: none; /* Make sure the watermark doesn't interfere with user interactions */
}

</style>