<template>
   <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <div v-if="this.bottle_id == 0">
          <div v-if="showSearchZone">
            <b-form aria-autocomplete="off">
              <b-form-group>
                <b-row class="d-flex">
                  <b-col class="text-left">
                    <label class="w-label" for="input-name">name</label>
                    <b-form-input
                      id="input-name"
                      class="w-input"
                      v-model="keyword_forName"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.isValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row class="d-flex">
                  <b-col class="text-left">
                    <label class="w-label" for="input-name">region</label>
                    <b-form-input
                      id="input-name"
                      class="w-input"
                      v-model="keyword_forRegion"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.isValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row class="d-flex">
                  <b-col class="text-left">
                    <label class="w-label" for="input-appellation">appellation</label>
                    <b-form-input
                      id="input-appellation"
                      class="w-input"
                      v-model="keyword_forAppellation"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.isValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group> 
                <b-row class="d-flex">
                  <b-col class="text-left">
                    <label class="w-label" for="input-grapes">grapes</label>
                    <b-form-input
                      id="input-grapes"
                      class="w-input"
                      v-model="keyword_forGrapes"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.isValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-row> 
                <b-col class="text-right">
                  <b-button v-on:click="onSearch()" class="w-button-green">search</b-button>
                </b-col>
            </b-row>
            </b-form>
          </div>
          <br>
          <div style="margin:0 10px 0 10px;padding:0 10px 0 10px!important">
            <b-row>
              <b-col class="text-left">
                <b-img v-if="leftVisible"
                  width=30px
                  height=30px
                  :src="require('@/assets/img/left.png')"
                  @click="onLeft"
                >
                </b-img>
              </b-col>
              <b-col xs="6">
                <p class="w-smalltext" style="text-align:center">this query has {{this.wineCount}} hits, page {{this.pageCount+1}}</p>
              </b-col>
              <b-col class="text-right">
                <b-img v-if="rightVisible"
                  width=30px
                  height=30px
                  :src="require('@/assets/img/right.png')"
                  @click="onRight"
                >
                </b-img>
              </b-col>
            </b-row>
          </div>
          <div v-if="showResultZone"  style="margin:0 10px 0 10px;padding:0 10px 0 10px!important;text-align:center">
            <b-row class="justify-content-center">  
              <b-col
                v-for="(wine, index) in wines"
                v-bind:item="wine"
                v-bind:index="index"
                v-bind:key="wine._id" 
                class="w-tile m-2 d-inline-flex justify-content-center"
                xs="12" sm="6" md="4" lg="2" xl="2"
                style ="display:block; min-width:300px; margin:20px 0 0 0 !important"
              >
                <b-row 
                  style="width:95%"
                  v-on:click="onAdd(wine)"
                >
                  <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                    <b-img center v-if="wine.image_files[0]"
                      style="height:100%;width:auto;position:absolute;right:0px"
                      v-bind:src="wine.image_files[0].dataURL" 
                    ></b-img>
                    <b-img center v-else
                      style="height:100%;width:auto;position:absolute;right:0px"
                      :src="require('@/assets/img/bottle.png')"
                    ></b-img>
                  </b-col>
                  <b-col cols="9" class="d-table-cell">
                    <div>
                      <p class="t-title">{{wine.name}}</p>
                      <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                      <hr>
                      <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                      <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                      <p class="t-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                    </div>

                    <div 
                      v-if="wine.certified==true"
                      style="position:absolute;top:168px;right: 0px;height: 28px;width: 28px;">
                        <font-awesome-icon :id="'q'+ index" style="font-size:24px;font-weight:bold;color:rgb(109, 122, 82)" icon="check" />
                        <b-tooltip :target="'q'+ index" custom-class="w-tooltip-good">certified by the WyneMaker</b-tooltip>
                    </div>
                    <div 
                      v-else
                      style="position:absolute;top:168px;right: 0px;height: 28px;width: 28px;">
                        <font-awesome-icon :id="'q'+ index" style="font-size:24px;font-weight:bold;color:rgb(195, 147, 130)" icon="question" />
                        <b-tooltip :target="'q'+ index" custom-class="w-tooltip-bad">not certified</b-tooltip>
                    </div>
                    
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else>
          <b-row class="justify-content-center">
            <b-col v-if="this.wine_id !=0"
              class="w-tile m-2 d-inline-flex justify-content-center"
              style ="display:block; max-width:300px; margin:20px 0 0 0!important"
            >
              <b-row
                style="width:100%"
              >
                <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                  <b-img center v-if="wine.image_files[0]"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    v-bind:src="wine.image_files[0].dataURL" 
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
                </b-col>
                <b-col cols="9" class="d-table-cell">
                  <div>
                    <p class="t-title">{{wine.name}}</p>
                    <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                    <hr>
                    <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                    <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                    <p class="t-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-else-if="this.c_wine_id !=0"
              class="w-tile m-2 d-inline-flex justify-content-center"
              style ="display:block; max-width:300px; margin:20px 0 0 0!important"
            >
              <b-row
                style="width:100%"
              >
                <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                  <b-img center v-if="c_wine.image_files[0]"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    v-bind:src="c_wine.image_files[0].dataURL" 
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
                </b-col>
                <b-col cols="9" class="d-table-cell">
                  <div>
                    <p class="t-title">{{c_wine.name}}</p>
                    <p class="t-subtitle" v-if="c_wine.type">{{c_wine.type}}</p>
                    <hr>
                    <p class="t-text" v-if="c_wine.appellation">{{c_wine.appellation}}</p>
                    <p class="t-text" v-if="c_wine.classification">{{c_wine.classification}}</p>
                    <p class="t-smalltext" v-if="c_wine.grapes">{{c_wine.grapes}}</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>  
            <b-col v-else>
              <b-row>
                <p>ERROR NO WINE = {{wine_id}} NOR C_WINE = {{ c_wine_id }}</p>
              </b-row>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-container fluid >
              <div>
                <b-form autocomplete="off">
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-form-group style="text-align:left">
                            <label class="w-label w-required" for="vintageList">vintage</label>         
                            <model-select 
                              id="vintageList"
                              class = "w-select"
                              :options="vintages"
                              v-model="bottle.vintage"
                              v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                              @input="inputVintage"
                            >
                            </model-select>             
                            <b-form-invalid-feedback id="input-live-feedback">
                              {{vintageFeedback}}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group style="text-align:left">
                            <label class="w-label" for="input-price">price</label>
                            <b-form-input
                              id="input-price"
                              class="w-input"
                              v-model="item.price"
                              aria-describedby="input-live-help input-live-feedback"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>                                                   
                      </b-row>
                      <b-form-group style="text-align:left">
                        <label class="w-label" for="input-origin">origin</label>
                        <b-form-input
                          id="input-origin"
                          class="w-input"
                          v-model="item.origin"
                          aria-describedby="input-live-help input-live-feedback"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group style="text-align:left">
                        <label class="w-label" for="input-comment">comment</label>
                        <b-form-input
                          id="input-comment"
                          class="w-input"
                          v-model="item.comment"
                          aria-describedby="input-live-help input-live-feedback"
                        ></b-form-input>
                      </b-form-group>                       
                    </b-col>
                  </b-row>         
                </b-form>                                  
              </div>
            </b-container>
          </b-row>
          <br>
          <b-row>
            <b-col class="text-right" style="padding-right:15px">
              <b-button v-on:click="onUpdate()" class="w-button-green">save</b-button>
            </b-col>            
          </b-row>
        </div>
      </b-container>
    </div>
    <b-modal id="addBottle" ref="addBottle" title="add bottle to ..." @ok="onSave">
      <p v-if="source == 'WineList'" class="t-title text-left">winelist: {{this.winelist.code}} - {{this.winelist.name}}</p> 
      <p v-if="source == 'TastingNote'" class="t-title text-left">current tastingnote</p> 
      <b-row>
        <div class="justify-content-center" style="margin-left:auto;margin-right:auto">
          <b-col v-if="this.wine_id !=0"
            class="w-tile m-2 d-inline-flex justify-content-center"
            style ="display:block; max-width:300px; margin:20px 0 0 0!important"
          >
            <b-row
              style="width:100%"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <b-img center v-if="wine.image_files[0]"
                  style="height:100%;width:auto;position:absolute;right:0px"
                  v-bind:src="wine.image_files[0].dataURL" 
                ></b-img>
                <b-img center v-else
                  style="height:100%;width:auto;position:absolute;right:0px"
                  :src="require('@/assets/img/bottle.png')"
                ></b-img>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <div>
                  <p class="t-title">{{wine.name}}</p>
                  <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                  <hr>
                  <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                  <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                  <p class="t-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else-if="this.c_wine_id !=0"
            class="w-tile m-2 d-inline-flex justify-content-center"
            style ="display:block; min-width:300px; margin:20px 0 0 0!important"
          >
            <b-row
              style="width:100%"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <b-img center v-if="c_wine.image_files[0]"
                  style="height:100%;width:auto;position:absolute;right:0px"
                  v-bind:src="c_wine.image_files[0].dataURL" 
                ></b-img>
                <b-img center v-else
                  style="height:100%;width:auto;position:absolute;right:0px"
                  :src="require('@/assets/img/bottle.png')"
                ></b-img>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <div>
                  <p class="t-title">{{c_wine.name}}</p>
                  <p class="t-subtitle" v-if="c_wine.type">{{c_wine.type}}</p>
                  <hr>
                  <p class="t-text" v-if="c_wine.appellation">{{c_wine.appellation}}</p>
                  <p class="t-text" v-if="c_wine.classification">{{c_wine.classification}}</p>
                  <p class="t-smalltext" v-if="c_wine.grapes">{{c_wine.grapes}}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>  
          <b-col v-else>
            <b-row>
              <p>ERROR NO WINE = {{wine_id}} NOR C_WINE = {{ c_wine_id }}</p>
            </b-row>
          </b-col>
        </div>
      </b-row>
      <br>
      <b-row>
        <b-container fluid >
          <div>
            <b-form autocomplete="off">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <b-form-group style="text-align:left">
                        <label class="w-label w-required" for="vintageList">vintage</label>         
                        <model-select 
                          id="vintageList"
                          class = "w-select"
                          :options="vintages"
                          v-model="bottle.vintage"
                          v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                          @input="inputVintage"
                        >
                        </model-select>             
                        <b-form-invalid-feedback id="input-live-feedback">
                          {{vintageFeedback}}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group style="text-align:left">
                        <label class="w-label" for="input-price">price</label>
                        <b-form-input
                          id="input-price"
                          class="w-input"
                          v-model="item.price"
                          aria-describedby="input-live-help input-live-feedback"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>                                                   
                  </b-row>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-origin">origin</label>
                    <b-form-input
                      id="input-origin"
                      class="w-input"
                      v-model="item.origin"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-comment">comment</label>
                    <b-form-input
                      id="input-comment"
                      class="w-input"
                      v-model="item.comment"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-form-group>                       
                </b-col>
              </b-row>         
            </b-form>                                  
          </div>
        </b-container>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>   
  </div>
</template>

<script>
  import Wines_Search from '../Wines_Search.js'

  import WineLists from '../WineLists.js'
  import Tastings from '../Tastings.js'
  import TastingNotes from '../TastingNotes.js'
  
  import Items from '../Items.js'
  import Bottles from '../Bottles.js'

  import { ModelSelect } from 'vue-search-select';

  export default {
    name: "LibrarySearch",
    components: {
      ModelSelect,      
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },

      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },

      searchState() {
        return this.$store.state.search_state
      },          
    },
    data() {
      return {
        debug: false,
        isLoading: true,

        winelist: {},
        winelist_id: 0,
        store_node: [0],

        tasting: {},
        tasting_id: 0,

        tasting_note: {},
        tasting_note_id: 0,

        item:{
          name:'',
          bottle_id:0,
          origin:'',
          price:'',
          comment:'',
          winelist_ids:[],
        },
        item_id:0,

        bottle:{
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        },
        bottle_id:0,
        
        wine: {},
        wine_id:0,

        c_wine: {},
        c_wine_id:0,

        wines: [],
        vintages: [],

        keyword_forName: '',
        search_forName:true,
        keyword_forRegion: '',
        search_forRegion:false,
        keyword_forAppellation: '',
        search_forAppellation:false,
        keyword_forGrapes: '',
        search_forGrapes:false,

        showSearchZone: true,
        showResultZone: false,

        // Validation Logic
        vintageValid : true,
        vintageFeedback : '',

        isValid : true,

        // Paging
        pageCount:0,
        wineCount:0,
        wines_perPage:6,
        leftVisible:false,
        rightVisible:false,

        // Source
        source: ''
        // source:"WineList"
        // source:"TastingNote"
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("=====> LibrarySearch.mounted - START")
  
      // Read the searchState from STORE
      if (this.debug) console.log("=====> LibrarySearch.mounted: searchState = ", this.searchState)
      if (Object.keys(this.searchState).length != 0) {        
        this.keyword_forName = this.searchState.keyword_forName;
        this.search_forName = this.searchState.search_forName;
        
        this.keyword_forRegion = this.searchState.keyword_forRegion;
        this.search_forRegion = this.searchState.search_forRegion;
        
        this.keyword_forAppellation = this.searchState.keyword_forAppellation;
        this.search_forAppellation = this.searchState.search_forAppellation;
        
        this.keyword_forGrapes = this.searchState.keyword_forGrapes;
        this.search_forGrapes = this.searchState.search_forGrapes;
       
        await this.onSearch();
      }

      // Read the arguments form STORE
      this.source = this.$store.getters.getSource;
      if (this.debug) console.log("=====> LibrarySearch.mounted: store get source = ", this.source);
      if (this.source != 'TastingNote' && this.source != 'WineList' ) {
        alert("ERROR: ILLEGAL SOURCE = "+ this.source)
        return
      }

      if (this.debug) console.log("=====> LibrarySearch.mounted: READING THE STORE")
      
      if (this.$store.getters.getWinelistID != 0) {
        this.winelist_id = this.$store.getters.getWinelistID
        this.winelist = this.$store.getters.getWinelist
        if (this.debug) console.log("=====> LibrarySearch.mounted: winelist ", this.winelist, " with id ", this.winelist_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> LibrarySearch.mounted: NO winelist found in store.")
      }

      if (this.$store.getters.getStoreNode != 0) {
        this.store_node = this.$store.getters.getStoreNode
        if (this.debug) console.log("=====> LibrarySearch.mounted: store_node ", JSON.stringify(this.store_node), " found in store.")
      } else {
        if (this.debug) console.log("=====> LibrarySearch.mounted: NO store_node found in store.")
      }

      if (this.$store.getters.getItemID != 0) {
        this.item_id = this.$store.getters.getItemID
        this.item = this.$store.getters.getItem
        if (this.debug) console.log("=====> LibrarySearch.mounted: item ", this.item, " with id ", this.item_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> LibrarySearch.mounted: NO item found in store.")
      }

      if (this.$store.getters.getBottleID != 0) {
        this.bottle_id = this.$store.getters.getBottleID
        this.bottle = this.$store.getters.getBottle
        if (this.debug) console.log("=====> LibrarySearch.mounted: bottle ", this.bottle, " with id ", this.bottle_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> LibrarySearch.mounted: NO bottle found in store.")
      }
      
      if (this.$store.getters.getCWineID != 0) {
        this.c_wine_id = this.$store.getters.getCWineID
        this.c_wine = this.$store.getters.getCWine
        if (this.debug) console.log("=====> LibrarySearch.mounted: c_wine ", this.c_wine, " with id ", this.c_wine_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> LibrarySearch.mounted: NO c_wine found in store.")
      }
      
      if (this.$store.getters.getWineID != 0) {
        this.wine_id = this.$store.getters.getWineID
        this.wine = this.$store.getters.getWine
        if (this.debug) console.log("=====> LibrarySearch.mounted: wine ", this.wine, " with id ", this.wine_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> LibrarySearch.mounted: NO wine found in store.")
      }
      
      if (this.$store.getters.getTastingID != 0) {
        this.tasting_id = this.$store.getters.getTastingID
        this.tasting = this.$store.getters.getTasting
        if (this.debug) console.log("=====> LibrarySearch.mounted: tasting ", this.tasting, " with id ", this.tasting_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> LibrarySearch.mounted: NO tasting found in store.")
      }
      
      if (this.$store.getters.getTastingnoteID != 0) {
        this.tasting_note_id = this.$store.getters.getTastingnoteID
        this.tasting_note = this.$store.getters.getTastingnote
        if (this.debug) console.log("=====> LibrarySearch.mounted: tasting_note ", this.tasting_note, " with id ", this.tasting_note_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> LibrarySearch.mounted: NO tasting_note found in store.")
      }    

      let today = new Date();
      // Fill the vintage list
      var this_year = today.getFullYear();
      for (let i = this_year; i > this_year-100; i--) {
        this.vintages.push({value:i,text:i});
      }

      this.isLoading = false;
      if (this.debug) console.log("=====> LibrarySearch.mounted - STOP")
    },
    methods:{
      async onSearch() {
        if (this.debug) console.log("=====> LibrarySearch.onSearch Search pressed")
        this.wines = [];
        this.pageCount = 0;
        this.leftVisible = false;
        this.rightVisible = false;

        let postdata = {
          "name": this.keyword_forName,
          "region": this.keyword_forRegion,
          "appellation": this.keyword_forAppellation,
          "grapes": this.keyword_forGrapes,
          "skip": 0,
          "limit": this.wines_perPage};
        await Wines_Search.count(postdata)
        .then(response=>{
          if (this.debug) console.log("=====> LibrarySearch.onSearch succesful wines search count = ", response)
          this.wineCount = response
        })
        .catch(error=>{
          if (this.debug) console.log("=====> LibrarySearch.onSearch catch Wines_Search.count error = ", error)
          return
        })

        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("=====> LibrarySearch.onSearch succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("=====> LibrarySearch.onSearch catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("=====> LibrarySearch.onSearch wines = ", this.wines)
        if (this.wines.length > 0) this.showResultZone = true;
        if (this.wineCount > (this.pageCount+1)*this.wines_perPage) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
      },
      async onLeft() {
        if (this.debug) console.log("LibrarySearch.onLeft: Left clicked")
        this.pageCount--;

        this.wines = [];
        let postdata = {
          "name": this.keyword_forName,
          "region":this.keyword_forRegion,
          "appellation":this.keyword_forAppellation,
          "grapes":this.keyword_forGrapes,
          "skip":this.pageCount*this.wines_perPage,
          "limit":this.wines_perPage};
        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("LibrarySearch.onLeft: succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("LibrarySearch.onLeft: catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("LibrarySearch.onLeft: wines = ", this.wines)
        if (this.wines.length > 0) this.showResultZone = true;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.wineCount > (this.pageCount+1)*this.wines_perPage) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
      },
      async onRight() {
        if (this.debug) console.log("LibrarySearch.onRight: Right clicked")
        this.pageCount++;

        this.wines = [];
        let postdata = {
          "name": this.keyword_forName,
          "region":this.keyword_forRegion,
          "appellation":this.keyword_forAppellation,
          "grapes":this.keyword_forGrapes,
          "skip":this.pageCount*this.wines_perPage,
          "limit":this.wines_perPage};
        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("LibrarySearch.onRight: succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("LibrarySearch.onRight: catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("LibrarySearch.onRight: wines = ", this.wines)
        if (this.wines.length > 0) this.showResultZone = true;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.wineCount > (this.pageCount+1)*this.wines_perPage) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
      },
      
      validate(){
        if(this.bottle.vintage == 0) {
          this.vintageValid = false;
          this.vintageFeedback = "select valid vintage"
        } 
        else {
          this.vintageValid = true;
          this.vintageFeedback = ""
        }
        this.isValid = this.vintageValid;
      },
      gotoWine(wine_id) {
        let search_state = {
          search_forName:this.search_forName,
          keyword_forName:this.keyword_forName,
          search_forRegion:this.search_forRegion,
          keyword_forRegion:this.keyword_forRegion,
          search_forAppellation:this.search_forAppellation,
          keyword_forAppellation:this.keyword_forAppellation,
          search_forGrapes:this.search_forGrapes,
          keyword_forGrapes:this.keyword_forGrapes
        }
        this.$store.commit('setPage','WineShow')
        this.$store.commit('setSearchState',search_state)
        this.$router.push({path:'/WineShow', query: {cellar_id:this.$route.query.cellar_id,niche_id:this.$route.query.niche_id,wine_id:wine_id}})
      },

      async onAdd(argument) {
        let local_debug = false
        if (local_debug || this.debug) console.log("==> onAdd: argument = ", argument)
        if (argument.winery_id && argument.winery_id.length == 24) {
          if (local_debug || this.debug) console.log("==> onAdd: argument is a wine")
          this.wine = argument;
          this.wine_id = argument._id
          delete this.wine._id
          this.c_wine_id = 0
          this.c_wine = {}
          if (local_debug || this.debug) console.log("==> onAdd: wine_id = ", this.wine_id)
          // if (local_debug || this.debug) console.log("==> onAdd: wine = ", JSON.stringify(this.wine))
        } else {
          if (local_debug || this.debug) console.log("==> onAdd: argument is a c_wine")
          this.c_wine = argument;
          this.c_wine_id = argument._id
          delete this.c_wine._id
          this.wine_id = 0
          this.wine = {}
          if (local_debug || this.debug) console.log("==> onAdd: c_wine_id = ", this.c_wine_id)
          if (local_debug || this.debug) console.log("==> onAdd: c_wine = ", JSON.stringify(this.c_wine))
        }
        this.bottle.vintage = 0
        await this.$bvModal.show('addBottle')
      },

      async inputVintage(value) {
        this.bottle.vintage = value;
        if (this.debug) console.log("=====> LibrarySearch.inputVintage - vintage = ",this.bottle.vintage)
      },

      async onSave(bvModalEvt) {
        let local_debug = false
        if (local_debug || this.debug) console.log("")
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: START")
        delete this.bottle._id
        if (typeof this.bottle._id != "undefined") {
          delete this.bottle._id
        }
        this.source = this.$store.getters.getSource;

        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.source = ", this.source)  
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.winelist_id = ", this.winelist_id, " this.winelist = ", this.winelist)

        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.tasting_id = ", this.tasting_id, " this.tasting = ", this.tasting)
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.tasting_note_id = ", this.tasting_note_id, " this.tasting_note = ", this.tasting_note)

        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.item_id = ", this.item_id, " this.item = ", this.item)
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.bottle_id = ", this.bottle_id, " this.bottle = ", this.bottle)
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.wine_id = ", this.wine_id, " this.wine = ", JSON.stringify(this.wine))
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.c_wine_id = ", this.c_wine_id, " this.c_wine = ", JSON.stringify(this.c_wine))
        
        //READING THE FORMS
        this.validate()
        if (this.isValid == false) {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: NOT VALID - NOTHING DONE")
          bvModalEvt.preventDefault()
          return
        }

        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: valid")
        // Create || Update bottle
        this.bottle.wine_id = this.wine_id
        if (this.wine_id != 0) {
          this.bottle.wine_id = this.wine_id
          this.bottle.c_wine_id = 0
          if (this.wine.type != '') {
            this.bottle.name = this.wine.name + ' (' + this.wine.type + ') ' + this.bottle.vintage
          } else {
            this.bottle.name = this.wine.name + ' ' + this.bottle.vintage
          }           
        } else if (this.c_wine_id != 0) {
          this.bottle.c_wine_id = this.c_wine_id
          this.bottle.wine_id = 0
          if (this.c_wine.type != '') {
            this.bottle.name = this.c_wine.name + ' (' + this.c_wine.type + ') ' + this.bottle.vintage
          } else {
            this.bottle.name = this.c_wine.name + ' ' + this.bottle.vintage
          }  
        } else {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: ERROR - NO WINE NOR C_WINE")
        }

        if (this.source == 'WineList') {
          
        } else if (this.source == 'TastingNote') {
          this.bottle.tasting_note_ids.push(this.tasting_note_id)
        } else {
          alert("NO SOURCE")
        }

        if (this.bottle_id == 0) {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: bottle DOES NOT exist - we create it")
          await Bottles.create(this.bottle)
          .then(response => {
            this.bottle_id = response;
            if (this.debug) console.log("==> LibrarySearch.onSave: bottle ",this.bottle," succesfully created : id = ", response);
          })
          .catch(error => {
            if (this.debug) console.log("==> LibrarySearch.onSave: catch await Bottles.create ERROR :", error);
          })
        } else {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: bottle DOES exist - we update it")
          await Bottles.update(this.bottle_id,this.bottle)
          .then(response => {
            if (this.debug) console.log("==> LibrarySearch.onSave: bottle ",this.bottle," succesfully updated with response ", response);
          })
          .catch(error => {
            if (this.debug) console.log("==> LibrarySearch.onSave: catch await Bottles.update ERROR :", error);
          })
        }

        // Create || Update item
        this.item.name = this.bottle.name
        this.item.color = this.wine.color
        this.item.bottle_id = this.bottle_id
        this.item.winelist_ids.push(this.winelist_id)
        if (this.winelist.isBlind == true) {
          this.item.isDisclosed = false
        }

        if (this.item_id == 0) {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: item DOES NOT exist - we create it")
          await Items.create(this.item)
          .then(response => {
            this.item_id = response;
            if (this.debug) console.log("==> LibrarySearch.onSave: item ",this.item," succesfully created : id = ", response);
          })
          .catch(error => {
            if (this.debug) console.log("==> LibrarySearch.onSave: catch await Items.create ERROR :", error);
          })
        } else {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: item DOES exist - we update it")
          await Items.update(this.item_id,this.item)
          .then(response => {
            if (this.debug) console.log("==> LibrarySearch.onSave: item ",this.item," succesfully updated with response ", response);
          })
          .catch(error => {
            if (this.debug) console.log("==> LibrarySearch.onSave: catch await Items.update ERROR :", error);
          })
        } 

        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.source = ", this.source)
        if (this.source == "WineList") {
          // Update winelist
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: updating winelist - this.store_node.length = ", this.store_node.length)
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: updating winelist - this.store_node = ", this.store_node)
          if ( this.store_node.length == 0) {
            if (local_debug || this.debug) console.log ("==> LibrarySearch.onSave: store_node not valid")
          } else if ( this.store_node.length == 1) {
            this.winelist.nodes.push({_id:this.item_id,isLeaf:true,value:this.item,parent_id:0,isVisible:true})
          } else if ( this.store_node.length == 2) {
            this.winelist.nodes[this.store_node[0]].nodes.push({_id:this.item_id,isLeaf:true,value:this.item,parent_id:this.winelist.nodes[this.store_node[0]]._id,isVisible:true})
          } else if ( this.store_node.length == 3) {
            this.winelist.nodes[this.store_node[0]].nodes[this.store_node[1]].nodes.push({_id:this.item_id,isLeaf:true,value:this.item,parent_id:this.winelist.nodes[this.store_node[0]].nodes[this.store_node[1]]._id,isVisible:true})
          } else if ( this.store_node.length == 4) {
            this.winelist.nodes[this.store_node[0]].nodes[this.store_node[1]].nodes[this.store_node[2]].nodes.push({_id:this.item_id,isLeaf:true,value:this.item,parent_id:this.winelist.nodes[this.store_node[0]].nodes[this.store_node[1]].nodes[this.store_node[2]]._id,isVisible:true})
          } else {
            alert("LibrarySearch.onSave: No more than 4 level items")
          }
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.winelist.nodes =", this.winelist.nodes)

          this.calcNumberofBottles()
          
          await WineLists.update(this.winelist_id,this.winelist)
          .then(response=>{
            if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: item_id ",this.item_id," succesfully added to winelist ", this.winelist, " with response ", response);
          })
          .catch(error=>{
            if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: catch await Winelist.update ", error);
          })

          this.bottle.winelist_ids.push(this.winelist_id)
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: adding winelist_id to the bottle.winelist_ids");
        } else if (this.source == "TastingNote") {
          this.tasting_note.item_id = this.item_id
          this.tasting_note.name = this.item.name
          var today = new Date();
          this.tasting_note.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
          if (this.tasting_note_id == 0 || typeof this.tasting == "undefined") {
            // Creating tasting_note
            await TastingNotes.create(this.tasting_note)
            .then(response=>{
              this.tasting_note_id = response
              if (this.debug) console.log("==> LibrarySearch.onSave: tasting_note  ",this.tasting_note," succesfully created with response ", response);
            })
            .catch(error=>{
              if (this.debug) console.log("==> LibrarySearch.onSave: catch await TastingNotes.create ", error);
            })

            // Add this.tasting_note_id to the tasting_note_ids list of this.tasting
            this.tasting.tasting_note_ids.push(this.tasting_note_id)
            if (this.debug) console.log("==> LibrarySearch.onSave: adding tasting_note_id  ",this.tasting_note_id," to tasting.tasting_note_ids");
            await Tastings.update(this.tasting_id,this.tasting)
            .then(response=>{
              if (this.debug) console.log("==> LibrarySearch.onSave: tasting  ",this.tasting," succesfully updated with response ", response);
            })
            .catch(error=>{
              if (this.debug) console.log("==> LibrarySearch.onSave: catch await Tastings.update ", error);
            })

            // Add this.tasting_note_id to the tasting_note_ids list of this.bottle
            this.bottle.tasting_note_ids.push(this.tasting_note_id)
            if (this.debug) console.log("==> LibrarySearch.onSave: adding tasting_note_id  ",this.tasting_note_id," to bottle.tasting_note_ids");
            await Bottles.update(this.bottle_id,this.bottle)
            .then(response=>{
              if (this.debug) console.log("==> LibrarySearch.onSave: bottle  ",this.bottle," succesfully updated with response ", response);
            })
            .catch(error=>{
              if (this.debug) console.log("==> LibrarySearch.onSave: catch await Bottles.update ", error);
            })
          } else {
            await TastingNotes.update(this.tasting_note_id,this.tasting_note)
            .then(response=>{
              if (this.debug) console.log("==> LibrarySearch.onSave: tasting_note  ",this.tasting_note," succesfully updated with response ", response);
            })
            .catch(error=>{
              if (this.debug) console.log("==> LibrarySearch.onSave: catch await TastingNotes.update ", error);
            })
          }
          
          if (this.debug) console.log("==> LibrarySearch.onSave: adding tasting_note_id to the bottle.tasting_note_ids");
        } else {
          alert("NO SOURCE")
        }

        await Bottles.update(this.bottle_id,this.bottle)
        .then(response=>{
          if (this.debug) console.log("==> LibrarySearch.onSave: bottle  ",this.bottle," succesfully updated with response ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("==> LibrarySearch.onSave: catch await Bottles.update ", error);
        })

        //Update Store
        if (this.source == "WineList") {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.winelist_id = ",this.winelist_id)
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.winelist = ",JSON.stringify(this.winelist))
          this.$store.commit('setWinelistID',this.winelist_id)
          this.$store.commit('setWinelist',this.winelist)
        } else if (this.source == "TastingNote") {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.tasting_id = ",this.tasting_id)
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.tasting = ",JSON.stringify(this.tasting))
          this.$store.commit('setTastingID',this.tasting_id)
          this.$store.commit('setTasting',this.tasting)

          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.tasting_note_id = ",this.tasting_note_id)
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.tasting_note = ",this.tasting_note)
          this.$store.commit('setTastingnoteID',this.tasting_note_id)
          this.$store.commit('setTastingnote',this.tasting_note)
        } else {
          alert("NO SOURCE")
        }

        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.item_id = ",this.item_id)
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.item = ",this.item)
        this.$store.commit('setItemID',this.item_id)
        this.$store.commit('setItem',this.item)
        
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.bottle_id = ",this.bottle_id)
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.bottle = ",this.bottle)
        this.$store.commit('setBottleID',this.bottle_id)
        this.$store.commit('setBottle',this.bottle)

        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.wine_id = ",this.wine_id)
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.wine = ",this.wine)
        this.$store.commit('setWineID',this.wine_id) 
        this.$store.commit('setWine',this.wine)

        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.c_wine_id = 0")
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: this.c_wine = {}")
        this.$store.commit('setCWineID',0) 
        this.$store.commit('setCWine',{})
           
        //Update Parent List
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: emitting refreshRequest to parent.")
        await this.$emit('refreshRequest');
        if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: STOP")
      },

      async onUpdate(bvModalEvt) {
        let local_debug = false
        if (local_debug || this.debug) console.log("")
        if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: START")
        if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: Source = ", this.source)

        this.validate()
        if (this.isValid == false) {
          if (local_debug || this.debug) console.log("==> LibrarySearch.onSave: NOT VALID - NOTHING DONE")
          bvModalEvt.preventDefault()
          return
        }

        if (this.wine.type !== '') {
          this.bottle.name = this.wine.name + ' (' + this.wine.type + ') ' + this.bottle.vintage
        } else {
          this.bottle.name = this.wine.name + ' ' + this.bottle.vintage
        }

        await Bottles.update(this.bottle_id, this.bottle)
        .then(response => {
          if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: bottle ",this.bottle," and bottle_id ",this.bottle_id,"succesfully updated with response: ", response);
        })
        .catch(error => {
          if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: catch await Bottles.update ERROR :", error);
        })

        this.item.name = this.bottle.name
        await Items.update(this.item_id, this.item)
        .then(response => {
          if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: item ",this.item," and item_id ",this.item_id,"succesfully updated with response: ", response);
        })
        .catch(error => {
          if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: catch await Items.update ERROR :", error);
        })

        if (this.source == 'TastingNote') {
          this.tasting_note.name = this.item.name
          await TastingNotes.update(this.tasting_note_id, this.tasting_note)
          .then(response => {
            if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: tasting_note ",this.tasting_note," and tasting_note_id ",this.tastiing_note_id,"succesfully updated with response: ", response);
          })
          .catch(error => {
            if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: catch await TastingNotes.update ERROR :", error);
          })
        } else if (this.source == 'WineList') {
          // Updating winelist
          this.item.name = this.bottle.name
          if ( this.store_node.length == 0) {
            if (local_debug || this.debug) console.log ("========> LibrarySearch.onUpdate: store_node not valid")
          } else if ( this.store_node.length == 1) {
            this.winelist.nodes[this.store_node[0]].value = this.item
          } else if ( this.store_node.length == 2) {
            console.log(" HERE WE ARE - this.store_node.length = 2")
            this.winelist.nodes[this.store_node[0]].nodes[this.store_node[1]].value = this.item
          } else if ( this.store_node.length == 3) {
            this.winelist.nodes[this.store_node[0]].nodes[this.store_node[1]].nodes[this.store_node[2]].value = this.item
          } else if ( this.store_node.length == 4) {
            this.winelist.nodes[this.store_node[0]].nodes[this.store_node[1]].nodes[this.store_node[2]].nodes[this.store_node[3]].value = this.item
          } else {
            alert("========> LibrarySearch.onUpdate: No more than 4 level items")
          }
          if (local_debug || this.debug) console.log("========> ========> LibrarySearch.onUpdate: updating item ",this.item," in winelist ", this.winelist);
          
          await WineLists.update(this.winelist_id,this.winelist)
          .then(response=>{
            if (local_debug || this.debug) console.log("========> ========> LibrarySearch.onUpdate: winelist ", this.winelist, " succesfully updated with response ", response);
          })
          .catch(error=>{
            if (local_debug || this.debug) console.log("========> ========> LibrarySearch.onUpdate: catch await Winelist.update ", error);
          })  
        } else {
          if (local_debug || this.debug) console.log("========> LibrarySearch.onUpdate: ERROR : source = ", this.source );
        }

        //Update Store
        this.$store.commit('setTasting',this.tasting)
        this.$store.commit('setTastingID',this.tasting_id)
        this.$store.commit('setTastingnote',this.tasting_note)
        this.$store.commit('setTastingnoteID',this.tasting_note_id)
        this.$store.commit('setItem',this.item)
        this.$store.commit('setItemID',this.item_id)
        this.$store.commit('setBottle',this.bottle)
        this.$store.commit('setBottleID',this.bottle_id)
        this.$store.commit('setWine',this.wine)
        this.$store.commit('setWineID',this.wine_id)    

        //Update Parent List
        if (local_debug || this.debug) console.log("==> LibrarySearch.onUpdate: emitting refreshRequest to parent.")
        await this.$emit('refreshRequest');

        if (local_debug || this.debug) console.log("==> LibrarySearch.onUpdate: STOP")
      },
      
      calcNumberofBottles() {
        let num = 0;
        if (this.debug) console.log("");
        if (this.debug) console.log("==========> LibrarySearch.calcNumberofBottles: START")
        for (let i = 0 ; i < this.winelist.nodes.length ; i++) {
          if (this.winelist.nodes[i].isLeaf) {
            num++
            if (this.debug) console.log("==========> LibrarySearch.calcNumberofBottles: num = ",num," nodes[",i,"].value.name =", this.winelist.nodes[i].value.name)
          } else {
            for (let j = 0 ; j < this.winelist.nodes[i].nodes.length ; j++) {
              if (this.winelist.nodes[i].nodes[j].isLeaf) {
                num++
                if (this.debug) console.log("==========> LibrarySearch.calcNumberofBottles: num = ",num," nodes[",i,"].nodes[",j,"].value.name =", this.winelist.nodes[i].nodes[j].value.name)
              } else {
                for (let k = 0 ; k < this.winelist.nodes[i].nodes[j].nodes.length ; k++) {
                  if (this.winelist.nodes[i].nodes[j].nodes[k].isLeaf) {
                    num++
                    if (this.debug) console.log("==========> LibrarySearch.calcNumberofBottles: num = ",num," nodes[",i,"].nodes[",j,"].nodes[",k,"].value.name =", this.winelist.nodes[i].nodes[j].nodes[k].value.name)
                  } else {
                    for (let l = 0 ; l < this.winelist.nodes[i].nodes[j].nodes[k].nodes.length ; l++) {
                      if (this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].isLeaf) {
                        num++
                        if (this.debug) console.log("==========> LibrarySearch.calcNumberofBottles: num = ",num," nodes[",i,"].nodes[",j,"].nodes[",k,"].nodes[",l,"].value.name =", this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].value.name)
                      } else {
                        alert("ERROR - FORTH LEVEL")
                      }
                    }
                  }
                }
              }
            }
          }
        }
        this.winelist.number_of_bottles = num
        if (this.debug) console.log("==========> LibrarySearch.calcNumberofBottles: num = ", num)
        if (this.debug) console.log("==========> LibrarySearch.calcNumberofBottles: STOP")
        if (this.debug) console.log("");
      }
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>