<template>
   <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession" style="margin-bottom:0">
        <div style="width:100%;height:100%;margin:0;padding:15px !important; padding-bottom:0 !important">
         <b-row>
            <b-col class="text-left" style="padding:0">
              <b-img v-if="leftVisible"
                width=30px
                height=30px
                :src="require('@/assets/img/left.png')"
                @click="onLeft"
              >
              </b-img>
            </b-col>
            <b-col cols="8" class="text-center" style="padding-left:0;padding-right:0;">
              <p v-if="this.pageCount == 0" 
                class="current-page w-cursor-default"
              >
                <b>wine</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onWine"
              >
                wine -
              </p>

              <p v-if="this.pageCount == 1" 
                class="current-page w-cursor-default"
              >
                <b>detail</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onDetail"
              >
                detail -
              </p>

              <p v-if="this.pageCount == 2" 
                class="current-page w-cursor-click"
              >
                <b>pictures</b>
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onPictures"
              >
                pictures
              </p>
              <p class="w-smalltext" style="text-align:center;">page {{this.pageCount+1}}/{{this.maxPage+1}}</p>
            </b-col>
            <b-col class="text-right" style="padding:0">
              <b-img v-if="rightVisible"
                width=30px
                height=30px
                :src="require('@/assets/img/right.png')"
                @click="onRight"
              >
              </b-img>
            </b-col>
            <br>
          </b-row>                   
          <b-row>
              <div v-if="pageCount == 0" class="w-page">
                <!-- <p class="w-subtitle" style="text-align:center">wine</p> -->
                <b-form autocomplete="off">
                  <b-form-group style="text-align:left">
                    <label class="w-label w-required" for="input-name">name</label>
                    <b-form-input
                      id="input-name"
                      class="w-input"
                      v-model="c_wine.name"
                      @change="onSave"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.nameValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                    {{nameFeedback}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  
                  <b-row>
                    <b-col>
                      <b-form-group style="text-align:left">
                        <label class="w-label" for="input-type">type</label>
                        <b-form-input
                          id="input-type"
                          class="w-input"
                          v-model="c_wine.type"
                          @change="onSave"
                          aria-describedby="input-live-help input-live-feedback"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group style="text-align:left">
                        <label class="w-label w-required" for="vintageList">vintage</label>         
                        <model-select 
                          id="vintageList"
                          class="w-select"
                          :options="vintages"
                          v-model="bottle.vintage"
                          v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                          @input="inputVintage"
                        >
                        </model-select>             
                        <b-form-invalid-feedback id="input-live-feedback">
                          {{vintageFeedback}}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  
                  <div v-if="!freeFormat">
                    <b-row>
                      <b-col>
                        <b-form-group style="text-align:left">
                          <label class="w-label w-required" for="countyList">country</label>         
                          <model-select 
                            id="vintageList"
                            class="w-select"
                            :options="countries"
                            v-model="c_wine.country_id"
                            v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                            @input="inputCountry"
                          >
                          </model-select>             
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{countryFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>                      
                      <b-col>
                        <b-form-group style="text-align:left">
                          <label class="w-label" for="regionList">region</label>        
                          <model-select
                            class="w-select"
                            :options="regions"
                            :isDisabled= !region_isActive
                            v-model="c_wine.region_id"
                            @input="inputRegion"
                          >
                          </model-select>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-form-group style="text-align:left">
                      <label class="w-label" for="appellationList" v-if="c_wine.country=='Italia'">denominazione</label>
                      <label class="w-label" for="appellationList" v-else-if="c_wine.country=='Espagna'">denominación</label>
                      <label class="w-label" for="appellationList" v-else-if="c_wine.country=='Deutschland'">einzellage	- großlage</label>
                      <label class="w-label" for="appellationList" v-else-if="c_wine.country=='USA'">AVA</label>
                      <label class="w-label" for="appellationList" v-else>appellation</label>
                      <model-select
                        class="w-select"
                        :options="appellations"
                        :isDisabled = !appellation_isActive
                        v-model="c_wine.appellation_id"
                        @input="inputAppellation"
                      >
                      </model-select>
                    </b-form-group>         

                    <b-form-group style="text-align:left">
                      <label class="w-label" for="classificationList">classification</label>        
                      <model-select
                        class="w-select"
                        :options="classifications"
                        :isDisabled = !classification_isActive
                        v-model="c_wine.classification_id"
                        @input="inputClassification"
                      >
                      </model-select>
                    </b-form-group>
                  </div>
                  <div v-else>
                    <b-row>
                      <b-col style="position:relative">
                        <b-form-group style="text-align:left">
                          <b-row>
                            <b-col sm="4" style="padding-right:0">  
                              <label class="w-label w-required" for="countryList">country</label>
                            </b-col>
                            <b-col sm="8" class="text-right" style="padding-left:0">
                              <label class="w-error" v-if="!this.countryValid">{{countryFeedback}}</label>
                            </b-col>
                          </b-row>
                          <b-form-input
                            id="input-country"
                            class="w-input"
                            v-model="c_wine.country"
                            @change="onSave"
                            v-bind:class="{'form-control':true, 'is-invalid' : !this.countryValid}"
                            aria-describedby="input-live-help input-live-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{countryFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <div style="position:absolute;top:30px;right:16px">
                          <b-button 
                            class="inside-button"
                            v-on:click="noFreeFormat()"
                          >
                            back
                          </b-button>
                        </div>
                      </b-col>
                      <b-col>
                        <b-form-group style="text-align:left">
                          <label class="w-label" for="input-region" style="font-weight:bold; color:rgb(109,122,82) !important">region - free format</label>
                          <b-form-input
                            id="input-region"
                            class="w-input"
                            v-model="c_wine.region"
                            @change="onSave"
                            aria-describedby="input-live-help input-live-feedback"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-appellation" style="font-weight:bold; color:rgb(109,122,82) !important">appellation - free format</label>
                      <b-form-input
                        id="input-appellation"
                        class="w-input"
                        v-model="c_wine.appellation"
                        @change="onSave"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                    </b-form-group>       

                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-classification" style="font-weight:bold; color:rgb(109,122,82) !important">classification - free format</label>
                      <b-form-input
                        id="input-classification"
                        class="w-input"
                        v-model="c_wine.classification"
                        @change="onSave"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                    </b-form-group>     
                  </div>

                  <b-row>
                    <b-col cols="4">
                      <b-form-group style="text-align:left">
                        <label class="w-label w-required" for="colorList">color</label>        
                        <model-select
                          class="w-select"
                          :options="colors"
                          v-model="color_id"
                          v-bind:class="{'form-control':true, 'is-invalid' : !this.colorValid}"
                          @input="inputColor"
                        >
                        </model-select>
                        <b-form-invalid-feedback id="input-live-feedback">
                          {{colorFeedback}}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group style="text-align:left;display: flex;position:relative;top:15px">   
                        <b-form-checkbox 
                          id="sparklingToggle"
                          v-model="c_wine.sparkling"
                          @change="onSave"
                          style="flex: 1;top:10px"
                        >
                        <label class="w-label" for="sparklingToggle">sparkling</label>
                        </b-form-checkbox>
                        <b-form-checkbox 
                          id="sweetToggle"
                          v-model="c_wine.sweet"
                          style="flex: 2"
                        >
                        <label class="w-label" for="sweetToggle">sweet</label>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group style="text-align:left">
                        <label class="w-label" for="input-price">price</label>
                        <b-form-input
                          id="input-price"
                          class="w-input"
                          v-model="item.price"
                          @change="onSave"
                          aria-describedby="input-live-help input-live-feedback"
                        ></b-form-input>
                      </b-form-group>                      
                    </b-col>
                  </b-row>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-origin">origin</label>
                    <b-form-input
                      id="input-origin"
                      class="w-input"
                      v-model="item.origin"
                      @change="onSave"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-comment">comment</label>
                    <b-form-input
                      id="input-comment"
                      class="w-input"
                      v-model="item.comment"
                      @change="onSave"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-form-group>                       
                </b-form>
              </div>
              <div v-if="pageCount == 1" class="w-page">
                <b-form autocomplete="off">
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-grapes">grapes</label>
                    <b-form-input
                      id="input-grapes"
                      class="w-input"
                      v-model="c_wine.grapes"
                      @change="onSave"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-longevity">longevity</label>
                    <b-form-input
                      id="input-longevity"
                      class="w-input"
                      v-model="c_wine.longevity"
                      @change="onSave"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-vines">vines</label>
                    <b-form-input
                      id="input-vines"
                      class="w-input"
                      v-model="c_wine.vines"
                      @change="onSave"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-yields">yields</label>
                    <b-form-input
                      id="input-yields"
                      class="w-input"
                      v-model="c_wine.yields"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-vinification">vinification</label>
                    <b-form-input
                      id="input-vinification"
                      class="w-input"
                      v-model="c_wine.vinification"
                      @change="onSave"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
              </div>
              <div v-if="pageCount == 2" class="w-page">
                <div style="height:350px;width:100%">
                  <b-row>
                    <b-col class="justify-content-center" style="height:100%; width:33%; display:flex">                
                      <div v-if="c_wine.image_files[0]" class="bottle-box"> 
                        <b-img  
                          v-bind:src="c_wine.image_files[0].dataURL"
                        >
                        </b-img>
                        <b-img
                          v-on:click="onDeletePicture(0)"
                          class="delete-picture"
                          :src="require('@/assets/img/delete-picture.png')"
                          style="width:100%;height:100%;border-radius:50%;border-style:none"
                        ></b-img>
                      </div>
                      <div v-else class="bottle-box">
                        <p style="margin-top: 15px;font-size: calc(3vw + 7vh);font-family: ui-monospace; color:grey">+</p>
                      </div>
                    </b-col>
                    <b-col class="justify-content-center" style="height:100%; width:33%; display:flex">               
                      <div v-if="c_wine.image_files[1]" class="bottle-box"> 
                        <b-img  
                          v-bind:src="c_wine.image_files[1].dataURL"
                        >
                        </b-img>
                        <img
                          v-on:click="onDeletePicture(1)" 
                          class="delete-picture"
                          :src="require('@/assets/img/delete-picture.png')"
                          style="height:100%;border-radius:50%;border-style:none"
                        />
                      </div>
                      <div v-else class="bottle-box">
                        <p style="margin-top: 15px;font-size: calc(3vw + 7vh);font-family: ui-monospace; color:grey">+</p>
                      </div>
                    </b-col>
                    <b-col class="justify-content-center" style="height:100%; width:33%; display:flex">             
                      <div v-if="c_wine.image_files[2]" class="bottle-box"> 
                        <b-img  
                          v-bind:src="c_wine.image_files[2].dataURL"
                          style="height:100%"
                        >
                        </b-img>
                        <img
                          v-on:click="onDeletePicture(2)" 
                          class="delete-picture"
                          :src="require('@/assets/img/delete-picture.png')"
                          style="width:100%;height:100%;border-radius:50%;border-style:none"
                        />
                      </div>
                      <div v-else class="bottle-box">
                        <p style="margin-top: 15px;font-size: calc(3vw + 7vh);font-family: ui-monospace; color:grey">+</p>
                      </div>
                    </b-col>
                  </b-row>
                  <vue-dropzone 
                    id="imgDropzone" ref="imgDropzone" class="winedropzone" 
                    :options="dropzoneOptions"
                    @vdropzone-thumbnail="vfileAdded" 
                  >
                  </vue-dropzone>  
                </div>
                <div style="height:50px">
                </div>  
              </div>
          </b-row>
          <b-row>
            <b-col class="text-right" style="padding-right:0;padding-left:0">
              <b-button v-on:click="onHardSave()" class="w-button-green-full">save</b-button>
            </b-col>
          </b-row>          
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';

  import { ModelSelect } from 'vue-search-select';
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

  import Countries from '../Countries.js'
  import Regions from '../Regions.js'
  import Geographies from '../Geographies.js';
  import Classifications from '../Classifications.js'

  import Tastings from '../Tastings.js';
  import TastingNotes from '../TastingNotes.js';
  import Items from '../Items.js'; 
  import Bottles from '../Bottles.js';
  import C_Wines from '../C_Wines.js'; 

  export default {
    name: "FullDefine",
    components: {
      ModelSelect,
      vueDropzone: vue2Dropzone
    },
    props: ['winelistArgument','winelistId'],
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },      
      Tasting() {
        return this.$store.state.tasting
      },
      TastingID() {
        return this.$store.state.tasting_id
      },
      TastingNote() {
        return this.$store.state.tasting_note
      },
      TastingNoteID() {
        return this.$store.state.tasting_note_id
      },      
      Item() {
        return this.$store.state.item
      },
      ItemID() {
        return this.$store.state.item_id
      },      
      Bottle() {
        return this.$store.state.bottle
      },
      BottleID() {
        return this.$store.state.bottle_id
      },
      CWine() {
        return this.$store.state.c_wine
      },
      CWineID() {
        return this.$store.state.c_wine_id
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,

        countries: [],
        regions: [],
        appellations:[],
        classifications:[],
        vintages: [],
        colors:[{text:'Red',value:0},{text:'White',value:1},{text:'Rosé',value:2}],
        color_id:'',

        geographies: [],
        current_geography:{},

        region_isActive: false,
        appellation_isActive: true,
        classification_isActive: false,

        freeFormat:false,
        
        // Validation Logic
        nameValid : true,
        nameFeedback : '',
        vintageValid : true,
        vintageFeedback : '',
        countryValid : true,
        countryFeedback : '',
        colorValid : true,
        colorFeedback : '',

        isValid: true,

        // Local objects
        tasting: {},
        tasting_id: 0,
        
        tasting_note: {
            user_id:0,
           	name:'',
            date:'',
            owner:'',
           	appearance:'',
           	nose:'',
           	palate:'',
           	aftertaste:'',
           	conclusion:'',
           	score:0,
           	entry_id:0,
	          item_id:0,
            tasting_id:0,
        },
        tasting_note_id: 0,
        
        item:{
          name:'',
          bottle_id:0,
          tasting_note_id:0,
          origin:'',
          price:'',
          comment:'',
          winelist_ids:[],
        },
        item_id:0,        

        bottle:{
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        },
        bottle_id:0,

        c_wine: {
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
          user_id:0,
        },
        c_wine_id:0,

        // Dropzone
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : '',
          maxFilesize: 10,
        },

        // Paging
        pageCount:0,
        maxPage:2,
        leftVisible:false,
        rightVisible:true,
      }
    }, 

    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("=====> FullDefine.mounted: START")

      // Read the STORE
      this.tasting = this.Tasting;
      this.tasting_id = this.TastingID;
      if (this.debug) console.log("=====> FullDefine.mounted: store read tasting =", this.tasting, "tasting_id =", this.tasting_id);

      this.tasting_note = this.TastingNote;
      this.tasting_note_id = this.TastingNoteID;
      if (this.debug) console.log("=====> FullDefine.mounted: store read tasting_note =", this.tasting_note, "tasting_note_id =", this.tasting_note_id);

      this.item = this.Item;
      this.item_id = this.ItemID;
      if (this.debug) console.log("=====> FullDefine.mounted: store read item =", this.item, "item_id =", this.item_id);
      
      this.bottle = this.Bottle;
      this.bottle_id = this.BottleID;
      if (this.debug) console.log("=====> FullDefine.mounted: store read bottle =", this.bottle, "bottle_id =", this.bottle_id);

      this.c_wine = this.CWine;
      this.c_wine_id = this.CWineID;
      if (this.debug) console.log("=====> FullDefine.mounted: store read c_wine =", this.c_wine, "c_wine_id =", this.c_wine_id);

      // Fill the vintage list
      var today = new Date();
      var this_year = today.getFullYear();
      for (let i = this_year; i > this_year-100; i--) {
        this.vintages.push({value:i,text:i.toString()});
      }
      this.vintages.push({value:"-",text:"no vintage"});

      // Fill the color list
      for (let i = 0 ; i < this.colors.length ; i ++) {
        if ( this.colors[i].text == this.c_wine.color) {
          this.color_id = this.colors[i].value
          if (this.debug) console.log("=====> FullDefine.mounted: this.color_id = ",this.color_id)
        }
      }

      await Geographies.get()
      .then(response=>{
        for (let i = 0 ; i < response.length ; i++) {
          this.geographies.push(response[i])
        }
        if (this.debug) console.log("FullDefine.mounted: Geographies ", response, " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log("FullDefine.mounted: catch await Geographies.get() - error : ", error);
      })
    
      this.region_isActive = false;
      this.appellation_isActive = false;
      this.classification_isActive = false;      
            
      if (this.debug) console.log('FullDefine.mounted: Checking c_wine');
      if (typeof this.c_wine != 'undefined' && this.c_wine_id != 0) {
        if (this.debug) console.log('FullDefine.mounted: c_wine ', this.c_wine, ' with c_wine_id ' + this.c_wine_id + ' found EXISTS');
        if (this.c_wine.country_id != 0) {
          if (this.debug) console.log("FullDefine.mounted: c_wine has a documented geography.")
            if (this.c_wine.appellation_id !=0) {
              await Geographies.getone(this.c_wine.appellation_id)
              .then(response => {
                this.current_geography = response
                if (this.debug) console.log("FullDefine.mounted: appellation current_geography is ", response)
              })
              .catch(error =>{
                if (this.debug) console.log("FullDefine.mounted: Geography.get failed with ERROR ", error)
              })
            } else  if (this.c_wine.region_id !=0) {
              await Geographies.getone(this.c_wine.region_id)
              .then(response => {
                this.current_geography = response
                if (this.debug) console.log("FullDefine.mounted: region current_geography is ", response)
              })
              .catch(error =>{
                if (this.debug) console.log("FullDefine.mounted: Geography.get failed with ERROR ", error)
              })
            } else  if (this.c_wine.country_id !=0) {
              await Geographies.getone(this.c_wine.country_id)
              .then(response => {
                this.current_geography = response
                if (this.debug) console.log("FullDefine.mounted: country  current_geography is ", response)
              })
              .catch(error =>{
                if (this.debug) console.log("FullDefine.mounted: Geography.get failed with ERROR ", error)
              })
            } else {
              if (this.debug) console.log('=====> FullDefine.mounted: Freeformat is on!');
              this.freeFormat = true;
            }

            // Country of existing c_wine
            this.countries = [];
            for (let i = 0 ; i < this.geographies.length ; i++) {
              if (this.geographies[i].level == 1) {
                this.countries.push({value:this.geographies[i]._id,text:this.geographies[i].name})
              }
            }
            if (this.countries.length > 0) {
              this.countries.sort((a,b)=>(a.text>b.text)?1:-1);
            }

            this.countries.push({value:"Another Country",text:"Another Country"})

            // Region of existing c_wine
            this.regions = [];
            for ( let i = 0 ; i < this.geographies.length ; i++ ) {
              if (this.geographies[i].level == 2) {
                if ( this.geographies[i].country_id == this.c_wine.country_id ) {
                  if (this.debug) console.log("FullDefine.mounted: regions of current country - ", this.geographies[i].name)
                  this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
                }
              }
            }
            if (this.regions.length > 0) {
              this.region_isActive = true;
              this.regions.sort((a,b)=>(a.text>b.text)?1:-1);
            }           

            // Appellation of existing c_wine
            this.appellations = []; 
            for ( let i = 0 ; i < this.geographies.length ; i++ ) {
              if (this.geographies[i].level == 3) {
                if ( this.c_wine.region_id !=0 ) {
                  if ( this.geographies[i].region_id == this.c_wine.region_id ) {
                    this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
                  }
                } else {
                  if ( this.geographies[i].country_id == this.c_wine.country_id ) {
                    this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
                  }
                }
              }     
            }    
            if (this.appellations.length > 0) {
              this.appellation_isActive = true;
              this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
            }            

            // Classification of existing c_wine
            this.classifications = [];
            if (this.current_geography.classification_ids.length > 0) {
              for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i++ ) {
                await Classifications.getone(this.current_geography.classification_ids[i])
                .then(response => {
                  if (typeof response != "undefined") {
                    if (this.debug) console.log("FullDefine.mounted: classification exists with response ", response)
                    this.classifications.push({value:response._id,text:response.name})
                  } else {
                    if (this.debug) console.log("FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
                  }
                })
                .catch(error => {
                  if (this.debug) console.log("FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
                })
              }
            }
            this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);

            if (this.classifications.length > 0) {
              this.classification_isActive = true
              if ( this.classifications.length == 1) {
                this.c_wine.classification_id = this.classifications[0].value
              }
            } else {
              this.classification_isActive = false
            }   
        } else {
          this.freeFormat = true
          if (this.debug) console.log("FullDefine.mounted: c_wine has a free format geography.")
        }
      } else {
        if (this.debug) console.log('FullDefine.mounted: c_wine DOES NOT EXISTS');
        this.initCountries();
      }

      this.isLoading = false;

      if (this.debug) console.log("=====> FullDefine.mounted: STOP this.bottle = ", this.bottle)
      if (this.debug) console.log("=====> FullDefine.mounted: STOP")
    },

    methods:{
      initCountries() {
        this.countries=[]
        this.regions=[]
        this.appellations=[]
        this.classifications=[]
        for (let i = 0 ; i < this.geographies.length ; i++) {
          if (this.geographies[i].level == 1) {
            this.countries.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }
        this.region_isActive = false;
        this.appellation_isActive = false;
        this.classification_isActive = false;  
        
        this.countries.push({value:"Another Country",text:"Another Country"})
        this.country_id = 0        
      },

      async inputVintage(value) {
        this.bottle.vintage = value;
        this.onSave();
        if (this.debug) console.log("=====> FullDefine.inputVintage - vintage = ",this.bottle.vintage)
      },

      async inputCountry(value) {
        if (this.debug) console.log("");        
        if (this.debug) console.log("FullDefine.inputCountry: START");

        this.c_wine.country_id = value;
        if (this.c_wine.country_id == "Another Country") {
          this.freeFormat = true;
          this.c_wine.country_id = 0
          this.c_wine.country = ""
          this.c_wine.region_id = 0
          this.c_wine.region = ""
          this.c_wine.appellation_id = 0
          this.c_wine.appellation = ""
          this.c_wine.classification_id = 0
          this.c_wine.classification = ""
          return
        }  

        for (let i=0; i< this.countries.length; i++) {
          if (this.countries[i].value === value) {
            this.c_wine.country = this.countries[i].text;
          }
        }

        if (this.debug) console.log("FullDefine.inputCountry: country_id = ",this.c_wine.country_id);
        if (this.debug) console.log("FullDefine.inputCountry: country = ",this.c_wine.country);

        this.regions =[];
        this.c_wine.region_id='';

        this.appellations =[];
        this.c_wine.appellation_id='';

        // Adapt regions
        this.regions = [];
        this.appellations =[];
        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
          if (this.geographies[i].level == 2 && this.geographies[i].country_id == value)  {
            this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
          if (this.geographies[i].level == 3 && this.geographies[i].country_id == value)  {
            this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }

        if (this.debug) console.log( "FullDefine.inputCountry: Regions from Country ", this.c_wine.country," ", this.regions, " succesfully found");
        if (this.debug) console.log( "FullDefine.inputCountry: Appellations from Country ", this.c_wine.country," ", this.appellations, " succesfully found");
      
        if (this.regions.length > 0 ) {
          this.regions.sort((a,b)=>(a.text>b.text)?1:-1);
          this.region_isActive = true
        } else {
          this.region_isActive = false
        }

        if (this.appellations.length > 0) {
          this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
          this.appellation_isActive = true;
        } else {
          this.appellation_isActive = false;
        }

        this.classifications =[];
        this.c_wine.classification_id='';

        // Adapt classifications
        this.classifications = []
        for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
          await Classifications.getone(this.current_geography.classification_ids[i])
          .then(response => {
            if (typeof response != "undefined") {
              if (this.debug) console.log("FullDefine.inputCountry: classification exists with response ", response)
              this.classifications.push({value:response._id,text:response.name})
            } else {
              if (this.debug) console.log("FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
            }

          })
          .catch(error => {
            if (this.debug) console.log("FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
          })
        }
        this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
            
        if (this.classifications.length > 0) {
          this.classification_isActive = true
        } else {
          this.classification_isActive = false
        }        
        
        this.onSave();
        if (this.debug) console.log("FullDefine.inputCountry: STOP");
      },

      async inputRegion(value) {
        let local_debug = false
        if (this.debug) console.log("")
        if (this.debug) console.log("FullDefine.inputRegion: START")

        this.c_wine.region_id = value;
        for (let i=0; i< this.regions.length; i++) {
          if (this.regions[i].value === value) {
            this.c_wine.region = this.regions[i].text;
          }
        }

        if (this.debug) console.log("FullDefine.inputRegion: region_id = ",this.c_wine.region_id)
        if (this.debug) console.log("FullDefine.inputRegion: region = ",this.c_wine.region)

        this.appellations = [];
        this.c_wine.appellation_id = '';

        if(typeof this.c_wine.region_id !== 'undefined') {  
          // Adapt regions
          for(let i = 0; i < this.geographies.length ; i++ ) {
            if (this.geographies[i]._id == value) {
              this.current_geography = this.geographies[i];
            }
            if (this.geographies[i].level == 3 && this.geographies[i].region_id == value)  {
              this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
            }
          }

          if (this.debug) console.log( "FullDefine.inputRegion: Appellations from Region ", this.c_wine.region," ", this.appellations, " succesfully found");
      
          if (this.appellations.length > 0 ) {
            this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
            this.appellation_isActive = true
          } else {
            this.appellation_isActive = false
          }

          this.classifications = [];
          this.c_wine.classification_id = 0;

          // Add classifications
          this.classifications = []
          if ((typeof this.current_geography.classification_ids != "undefined") && (this.current_geography.classification_ids.length > 0)) {
            for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
              await Classifications.getone(this.current_geography.classification_ids[i])
              .then(response => {
                if (typeof response != "undefined") {
                  if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification exists with response ", response)
                  this.classifications.push({value:response._id,text:response.name})
                } else {
                  if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
                }

              })
              .catch(error => {
                if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
              })
            }
            this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
          }
              
          if (this.classifications.length > 0) {
            this.classification_isActive = true
          } else {
            this.classification_isActive = false
          }          

          this.onSave();
          if (this.debug) console.log("FullDefine.inputRegion: STOP")
        }  
      },

      async inputAppellation(value) {
        let local_debug = false
        if (this.debug) console.log("")
        if (this.debug) console.log("FullDefine.inputAppellation: START")

        this.c_wine.appellation_id = value;
        // for (let i = 0; i < this.appellations.length; i++) {
        //   if (this.appellations[i].value == value ) {
        //     this.c_wine.appellation = this.appellations[i].text
        //   }
        // }

        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
        }

        if (this.debug) console.log("FullDefine.inputAppellation: appellation_id = ",this.c_wine.appellation_id)
        if (this.debug) console.log("FullDefine.inputAppellation: appellation = ",this.c_wine.appellation)
        
        if (this.c_wine.country_id == 0) {
          this.c_wine.country_id = this.current_geography.country_id
          for(let i = 0; i < this.geographies.length ; i++ ) {
            if (this.geographies[i].level == 2 && this.geographies[i].country_id == this.c_wine.country_id)  {
              this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
            }
          }
        }

        if (this.c_wine.region_id == 0) {
           this.c_wine.region_id = this.current_geography.region_id
           this.region_isActive = true;
        }

        // Add classificationss
        this.classifications = []
        this.classification_id = '';
        if ((typeof this.current_geography.classification_ids != "undefined") && (this.current_geography.classification_ids.length > 0)) {
          for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
            await Classifications.getone(this.current_geography.classification_ids[i])
            .then(response => {
              if (typeof response != "undefined") {
                if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification exists with response ", response)
                this.classifications.push({value:response._id,text:response.name})
              } else {
                if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
              }

            })
            .catch(error => {
              if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
            })
          }
          this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
        }
            
        if (this.classifications.length > 0) {
          this.classification_isActive = true
          if ( this.classifications.length == 1) {
            this.c_wine.classification_id = this.classifications[0].value
          }
        } else {
          this.classification_isActive = false
        }
        
        // New 2022-08-03 Adapt Appellationlist according to selected region
        this.appellations = []
        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
          if (this.geographies[i].level == 3 && this.geographies[i].region_id == this.c_wine.region_id)  {
            this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }
        if (this.appellations.length > 0 ) {
          this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
          this.appellation_isActive = true
        } else {
          this.appellation_isActive = false
        }
        for (let i = 0; i < this.appellations.length; i++) {
          if (this.appellations[i].value == value ) {
            this.c_wine.appellation = this.appellations[i].text
          }
        } 

        this.onSave();
        if (this.debug) console.log("FullDefine.inputAppellation: STOP")
      },

      inputClassification(value) {
        this.c_wine.classification_id = value;
        if (this.debug) console.log("FullDefine.inputClassification - classification_id = ",this.c_wine.classification_id)
        for (let i = 0; i < this.classifications.length; i++) {
          if (this.classifications[i].value == value ) {
            this.c_wine.classification = this.classifications[i].text
          }
        }
        if (this.debug) console.log("FullDefine.inputClassification - classification = ",this.c_wine.classification)
        this.onSave();
      },

      inputColor(value) {
        if (this.debug) if (this.debug) console.log("=====> FullDefine.inputColor - value = ",value) 
        for (let i = 0; i < this.colors.length; i++) {
          if (this.colors[i].value == value ) {
            this.c_wine.color = this.colors[i].text;
            this.color_id = i;
          }
        }
        if (this.debug) if (this.debug) console.log("=====> FullDefine.inputColor - color = ",this.c_wine.color)
        this.onSave();
      },      

      onDeletePicture(i){
        if (this.debug) console.log("========> FullDefine.onDeletePicture START")
        this.c_wine.image_files.splice(i, 1);
        this.hasChanged = true;
        this.onSave();
        if (this.debug) console.log("========> FullDefine.onDeletePicture STOP")
      },

      validate(){
        if(this.c_wine.name.length < 3) {
          this.nameValid = false;
          this.nameFeedback = "minimum 3 characters"
        }  
        else {
          this.nameValid = true;
          this.nameFeedback = ""
        }

        if(this.bottle.vintage == 0) {
          this.vintageValid = false;
          this.vintageFeedback = "select valid vintage"
        } 
        else {
          this.vintageValid = true;
          this.vintageFeedback = ""
        }

        if (!this.freeFormat) {
          if(this.c_wine.country_id == 0) {
            this.countryValid = false;
            this.countryFeedback = "select valid country"
          } 
          else {
            this.countryValid = true;
            this.countryFeedback = ""
          }
        } else {
          if(this.c_wine.country.length < 3) {
            this.countryValid = false;
            this.countryFeedback = "minimum 3 characters"
          } 
          else {
            this.countryValid = true;
            this.countryFeedback = ""
          }
        } 

        if(this.c_wine.color == "") {
          this.colorValid = false;
          this.colorFeedback = "select valid color"
        } 
        else {
          this.colorValid = true;
          this.colorFeedback = ""
        } 

        this.isValid = this.nameValid && this.vintageValid && this.countryValid && this.colorValid;
      },

      async onHardSave() {
        let local_debug = false
        if (local_debug || this.debug) console.log("");
        if (local_debug || this.debug) console.log("========> FullDefine.onHardSave: START");
        await this.onSave()
        if (local_debug || this.debug) console.log("========> FullDefine.onHardSave: emiting refreshRequest")
        await this.$emit('refreshRequest');
        if (local_debug || this.debug) console.log("========> FullDefine.onHardSave: STOP");
      },

      async onSave() {
        let local_debug = false
        if (local_debug || this.debug) console.log("");
        if (local_debug || this.debug) console.log("========> FullDefine.onSave: START");
        delete this.bottle._id
        if (typeof this.bottle._id != "undefined") {
          delete this.bottle._id
        }
        
        //READING THE FORMS

        this.validate();
        if (local_debug || this.debug) console.log("========> FullDefine.onSave: this.isValid = ", this.isValid);

        if (!this.isValid) {
          if (this.nameValid && this.vintageValid && this.countryValid && this.colorValid) {
            this.pageCount = 1
            this.leftVisible = true;
            this.rightVisible = true;
          } else {
            this.pageCount = 0;
            this.leftVisible = false;
            this.rightVisible = true;
          }
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: STOP c_wine not fully defined yet. Return without Save.")
          return;
        }

        if (this.c_wine.country_id !=0) await Countries.getone(this.c_wine.country_id)
        .then(response => {
          this.c_wine.country = response.name
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: country_id ",this.c_wine.country_id," found as ", this.c_wine.country, " - updating.");
        })
        .catch(error => {
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await Countries.getone ERROR :", error);
        })

        if (this.c_wine.region_id !=0) await Regions.getone(this.c_wine.region_id)
        .then(response => {
          this.c_wine.region = response.name
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: region_id ",this.c_wine.region_id," found as ", this.c_wine.region, " - updating.");
        })
        .catch(error => {
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await Regions.getone ERROR :", error);
        })

        if (this.c_wine.classification_id !=0) await Classifications.getone(this.c_wine.classification_id)
        .then(response => {
          this.c_wine.classification = response.name
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: classification_id ",this.c_wine.classification_id," found as ", this.c_wine.classification, " - updating.");
        })
        .catch(error => {
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await Classifications.getone ERROR :", error);
        })

        this.c_wine.user_id = this.currentUserID
        if ( this.c_wine_id == 0 ) {
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: c_wine does NOT exist - we create it");
          
          // Creating c_wine
          await C_Wines.create(this.c_wine)
          .then(response=>{
            this.c_wine_id = response;
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: c_wine ", this.c_wine, " succesfully created : id = ", response);
          })
          .catch(error=>{
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await C_Wines.create ERROR :", error);
          })
          
          // Creating bottle
          if (this.c_wine.type !== '') {
            this.bottle.name = this.c_wine.name + ' (' + this.c_wine.type + ') ' + this.bottle.vintage
          } else {
            this.bottle.name = this.c_wine.name + ' ' + this.bottle.vintage
          }
          this.bottle.c_wine_id = this.c_wine_id
          this.bottle.winelist_ids.push(this.winelist_id)
          
          await Bottles.create(this.bottle)
          .then(response => {
            this.bottle_id = response;
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: bottle ",this.bottle," succesfully created : id = ", response);
          })
          .catch(error => {
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await Bottles.create ERROR :", error);
          })

          // Creating item
          this.item.name = this.bottle.name
          this.item.color = this.c_wine.color
          this.item.bottle_id = this.bottle_id
          this.item.winelist_ids.push(this.winelist_id)

          await Items.create(this.item)
          .then(response => {
            this.item_id = response;
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: item ",this.item," succesfully created : id = ", response);
          })
          .catch(error => {
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await Items.create ERROR :", error);
          })

          if (this.tasting_note_id == 0) {
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: tasting_note DOES NOT exists - we create it")
            // Creating tasting_note
            this.tasting_note.user_id = this.currentUserID;
            this.tasting_note.name = this.item.name
            this.tasting_note.color = this.item.color
            this.tasting_note.date = this.tasting.date
            this.tasting_note.owner = this.currentUser.firstname + ' ' + this.currentUser.lastname
            this.tasting_note.item_id = this.item_id
            this.tasting_note.tasting_id = this.tasting_id

            await TastingNotes.create(this.tasting_note)
            .then(response => {
              this.tasting_note_id = response;
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: tasting note ",this.tasting_note," succesfully created : id = ", response);
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await TastingNotes.create ERROR :", error);
            })
            
            // Add this.tasting_note_id to the tasting_note_ids list of this.tasting
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: Adding tasting_note_id ",this.tasting_note_id," to the tasting_note_ids of tasting ", this.tasting)  
            this.tasting.tasting_note_ids.push(this.tasting_note_id)

            await Tastings.update(this.tasting_id,this.tasting)
            .then(response=>{
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: tasting ",this.tasting," with id ",this.tasting_id," succesfully updated with response ", response);
            })
            .catch(error=>{
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await Tasting.update ", error);
            })
          } else {
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: tasting_note DOES exists - we update it")
            this.tasting_note.name = this.item.name
            this.tasting_note.color = this.item.color
            this.tasting_note.item_id = this.item_id
            delete this.tasting_note._id
            await TastingNotes.update(this.tasting_note_id,this.tasting_note)
            .then(response => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: tasting note ",this.tasting_note," succesfully updated with response", response);
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await TastingNotes.update ERROR :", error);
            })

            this.tasting_note.name = this.item.name
            this.tasting_note.color = this.item.color
            await TastingNotes.update(this.tasting_note_id,this.tasting_note)
            .then(response => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: tasting_note ",this.tasting_note," succesfully updated with response", response);
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await TastingNotes.update ERROR :", error);
            })
          }          
          // this.init();
        } else {
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: c_wine DOES exist - we update it");
          if (local_debug || this.debug) console.log("========> FullDefine.onSave: c_wine DOES exist: c_wine_id = ", this.c_wine_id, " c_wine = ", this.c_wine);

          // Updating c_wine
          await C_Wines.update(this.c_wine_id,this.c_wine)
          .then(response=>{
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: c_wine ", this.c_wine, " succesfully updated with response ", response);
          })
          .catch(error=>{
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await C_Wines.update ERROR :", error);
          })
          
          // Updating bottle
          if (this.c_wine.type !== '') {
            this.bottle.name = this.c_wine.name + ' (' + this.c_wine.type + ') ' + this.bottle.vintage
          } else {
            this.bottle.name = this.c_wine.name + ' ' + this.bottle.vintage
          }
          
          if (this.bottle_id != 0 ) {
            await Bottles.update(this.bottle_id,this.bottle)
            .then(response => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: bottle ",this.bottle," succesfully updated with response ", response);
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await Bottles.update ERROR :", error);
            })
          } else {
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: ERROR bottle_id = ", this.bottle_id);
          }

          // Updating item
          this.item.name = this.bottle.name
          this.item.color = this.c_wine.color
          if (this.item_id != 0 ) {
            await Items.update(this.item_id,this.item)
            .then(response => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: item ",this.item," succesfully updated with response ", response);
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await Items.update ERROR :", error);
            })
          } else {
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: ERROR item_id = ", this.item_id);
          }

          // Updating tasting_note
          this.tasting_note.name = this.item.name
          this.tasting_note.color = this.item.color
          delete this.tasting_note._id
          if (this.tasting_note_id != 0 ) {
            await TastingNotes.update(this.tasting_note_id,this.tasting_note)
            .then(response => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: tasting_note ",this.tasting_note," succesfully updated with response ", response);
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("========> FullDefine.onSave: catch await TastingNotes.update ERROR :", error);
            })
          } else {
            if (local_debug || this.debug) console.log("========> FullDefine.onSave: ERROR tasting_note_id = ", this.tasting_note_id);
          }
        }

        //Update Store
        this.$store.commit('setTasting',this.tasting)
        this.$store.commit('setTastingID',this.tasting_id)
        this.$store.commit('setTastingnote',this.tasting_note)
        this.$store.commit('setTastingnoteID',this.tasting_note_id)
        this.$store.commit('setItem',this.item)
        this.$store.commit('setItemID',this.item_id)
        this.$store.commit('setBottle',this.bottle)
        this.$store.commit('setBottleID',this.bottle_id)
        this.$store.commit('setWine',{})
        this.$store.commit('setWineID',0)  
        this.$store.commit('setCWine',this.c_wine)
        this.$store.commit('setCWineID',this.c_wine_id)           
        if (local_debug || this.debug) console.log("========> FullDefine.onSave: store updated")  
         
        // Update Parent List
        // if (local_debug || this.debug) console.log("========> FullDefine.onSave: emiting refreshRequest")
        // await this.$emit('refreshRequest');
        if (local_debug || this.debug) console.log("========> FullDefine.onSave: STOP");
      },

      init() {
        this.item={
          name:'',
          bottle_id:0, 
          origin:'',
          comment:'',
          winelist_ids:[],
        };
        this.item_id=0;
        this.bottle = {
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        };
        this.bottle_id=0;

        this.c_wine = {
            name:'',
            type:'',
            country_id:0,
            country:'',
            region_id:0,
            region:'',
            appellation_id:0,
            appellation:'',
            classification_id:0,
            classification:'',
            sparkling:false,
            color:'',
            grapes:'',
            vines:'',
            yields:'',
            vinification:'',
            image_files:[],
        };
        this.c_wine_id = 0;
        this.color_id = '';
      },

      noFreeFormat() {
        this.initCountries()
        this.freeFormat = false;
      },

      onBack() {
        this.$router.push({path:'/Participate'});
      },

      async vfileAdded(new_image_file) {
        let local_debug = false
        if (this.debug || local_debug) console.log("======> FullDefine.vfileAdded: START");
        if ( !new_image_file.manuallyAdded ) {
          if (this.debug || local_debug) console.log("======> FullDefine.vfileAdded: wine picture URL: ", new_image_file);
          let org_base64 = new_image_file.dataURL;
          let cc_base64 = await cc64(org_base64,1,3);
          new_image_file.dataURL = cc_base64;
          this.c_wine.image_files.push(new_image_file);
          if (this.c_wine.image_files.length > 3) {
            this.c_wine.image_files.shift();
          }
          this.hasChanged = true;
          this.$refs.imgDropzone.removeFile(new_image_file);
          this.onSave();
        }
        if (this.debug || local_debug) console.log("======> FullDefine.vfileAdded: STOP");
      },

      vfileRemoved(image_file) {
        let local_debug = false
        if (this.debug || local_debug) console.log("======> FullDefine.vfileRemoved: START");
        for( var i = 0; i < this.c_wine.image_files.length; i++){
          if ( this.c_wine.image_files[i].dataURL === image_file.dataURL) { 
            this.c_wine.image_files.splice(i, 1); 
          }
        }
        this.hasChanged = true
        this.onSave();
        if (this.debug || local_debug) console.log("======> FullDefine.vfileRemoved: STOP");
      },

      template: function () {
         return `<div class="dz-preview">
                </div>
        `;
      },

      onLeft() {
        this.pageCount--;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },

      onRight() {
        this.pageCount++;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },
      async onWine() {
        this.pageCount = 0;
        this.leftVisible = false;
        this.rightVisible = true;
        // await this.onSave()
      },
      async onDetail() {
        this.pageCount = 1;
        this.leftVisible = true;
        this.rightVisible = true;
        // await this.onSave()
      },
      async onPictures() {
        this.pageCount = 2;
        this.leftVisible = true;
        this.rightVisible = false;
        // await this.onSave()
      },      

    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
/* Custom Dropzone */
/* .winedropzone {
  position: absolute;
  top:110px;
  left:0;
  width:50vw;
  height:min(30vw,40vh);

  background-color: transparent !important;

  border-style: none;
  border-radius: 5px;

  transition: background-color .2s linear;
} */
.winedropzone {
  position: relative;
  top:-34vh;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent !important;

  border-style: none;
  border-radius: 5px;

  color:#696969;

  transition: background-color .2s linear;
}
</style>